/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function VacationNewTime() {
  const router = useNavigate();
  const { id } = useParams();
  const [user, setUser]: any = useState({});
  const [valide, setValide] = useState(true);
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      concessive_period_start: null,
      concessive_period_end: null,
      acquisition_period_start: null,
      acquisition_period_end: null,
      days_available: "",
      days_used: "",
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        { ...values, target_user_uuid: user.uuid },
        "/vacations"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/vacation/time");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/vacations_form?user_uuid=" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUser({
          ...response.data.data.user_data,
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Férias", value: "/vacation/time" },
            { label: "Periodo", value: "/vacation/time" },
            { label: "Novo periodo" },
          ]}
        />
        <InfoComponent
          user={user}
          instruction='Nesta tela você cria de forma manual um periodo para um
                  colaborador. Preencha os campos abaixo e clique em "Criar".'
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar periodo de férias
              </Typography>
            </Grid>
            <Form formik={formik} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                onClick={() => {
                  setValide(true);
                  formik.handleSubmit();
                }}
                endIcon={<AddCircle />}>
                Criar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
