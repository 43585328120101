/** @format */

import { TextField, TextFieldProps } from "@mui/material";
import { getIn } from "formik";

interface FieldProps extends Omit<TextFieldProps, "ref"> {
  formik: any;
  id: string;
  label: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
}

export function InputTextField(props: FieldProps) {
  const { formik, id, label, disabled, onChange, onBlur } = props;
  const value = getIn(formik.values, id) ?? "";
  const error = getIn(formik.errors, id);
  return (
    <TextField
      {...props}
      fullWidth
      id={id}
      disabled={disabled}
      name={id}
      placeholder='Sem informação'
      label={label}
      value={value}
      onChange={(event: any) => {
        formik.setFieldValue(id, event.target.value);
        onChange && onChange(event);
      }}
      onBlur={(event: any) => {
        formik.handleBlur(event);
        onBlur && onBlur(event);
      }}
      error={Boolean(error)}
      helperText={error}
    />
  );
}
