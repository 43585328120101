/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid} from "@mui/material";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";

export function Form({ formik, grades, jobRoleGroups }: any) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
      <InputTextField formik={formik} label={"Nome"} id={"name"} />
      <InputTextField type='number' formik={formik} label={"Código"} id={"code"} />
      <InputCurrencyField  formik={formik} label='Piso Salarial' id={"salary_minimum"} />
       <InputCurrencyField  formik={formik} label='Teto Salarial' id={"salary_maximum"} />
        <AutoCompleteField
                formik={formik}
                label='Grupo de Cargos'
                id='job_role_group_id'
                options={jobRoleGroups.map((item: any) => ({
                  value: item.id,
                  label: item.description,
                }))}
              />
        <AutoCompleteField
                formik={formik}
                 label='Nível Funcional'
                id='grade_id'
                options={grades.map((item: any) => ({
                  value: item.id,
                  label: `${item.grade} - ${item.description}` ,
                }))}
              />
</Grid>
      <Grid container gap={2} mt={2} justifyContent={"end"}>
        <ButtonComponent
          variant={"outlined"}
          onClick={() => {
            window.history.back();
          }}
          endIcon={<Undo />}>
          Voltar
        </ButtonComponent>
        <ButtonComponent
          variant={"contained"}
          type={"submit"}
          endIcon={<AddCircle />}>
          Salvar
        </ButtonComponent>
      </Grid>
    </form>
  );
}
