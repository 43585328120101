/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid } from "@mui/material";

import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { InputCheckBox } from "../../../../../components/inputs/inputCheckBox";

interface FormProps {
  formik: any;
}

export function Form({
  formik,
}: FormProps) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
      <InputTextField
          id="code"
          formik={formik}
          label={"Código"}
          type="number"
        />
        <InputTextField
          id="description"
          formik={formik}
          label={"Descrição"}
          />
        <InputTextField
          id="weekly_hours_amount"
          formik={formik}
          label={"Carga horária semanal"}
          type="number"
        />
        <InputTextField
          id="monthly_hours_amount"
          formik={formik}
          label={"Carga horária mensal"}
          type="number"
        />
        <InputCheckBox
          id="night_shift"
          formik={formik}
          label={"Turno noturno"}
        />
        <InputCheckBox
          id="vacation_exeption"
          formik={formik}
          label={"Exceção de férias"}
        />

      </Grid>

      <Grid container gap={2} mt={2} justifyContent={"end"}>
        <ButtonComponent
          variant={"outlined"}
          onClick={() => {
            window.history.back();
          }}
          endIcon={<Undo />}>
          Voltar
        </ButtonComponent>
        <ButtonComponent
          variant={"contained"}
          type={"submit"}
          endIcon={<AddCircle />}>
          Salvar
        </ButtonComponent>
      </Grid>
    </form>
  );
}
