/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function PersonalNew() {
  const dispatch = useDispatch();
  const [valide,setValide]= useState(false);
  const [options, setOptions] = useState([]);
  const [optionsRequisition, setOptionsRequisition] = useState<any>({
    job_role: [],
    job_role_group: [],
  });
  const [currentPosition, setCurrentPosition] = useState<any>({});
  const params = new URLSearchParams(window.location.search);
  const position = params.get("position");

  const formik = useFormik({
    initialValues: {
      job_role_id: "",
      job_role_group_id: "",
      position_id: "",
      salary: "",
      initial_date: null,
      observations: "",
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setValide(false)
      dispatch(updateLoading(true));
      PostInstante(null, values, "/personnel_requisitions")
        .then((response: any) => {
          dispatch(updateLoading(false));
          window.history.back();
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/personnel_requisitions_form")
      .then(async (response: any) => {
        setOptionsRequisition(response.data.data.options);
        GetInstance({}, "/positions/status_by_company")
      .then(async (response2: any) => {
        dispatch(updateLoading(false));
        setOptions(response2.data);
        if (position) {
          PostInstante(null, null, `/positions/${position}/current_position`)
            .then((res) => {
              formik.setFieldValue("position_id", res.data.id);
              formik.setFieldValue("job_role_id", res.data.job_role_id);
              formik.setFieldValue("salary", res.data.salary);
              setCurrentPosition(res.data);
              dispatch(updateLoading(false));
            })
            .catch((e) => {
              GenericError(e, dispatch);
            });
        }else{
          dispatch(updateLoading(false));
        }
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Requerimentos", value: "/request/personal" },
            { label: "Pessoal", value: "/request/personal" },
            { label: "Nova solicitação" },
          ]}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar requisição de pessoal
              </Typography>
            </Grid>
           <Form formik={formik} position={currentPosition} options={{job_role_group: options,job_role: optionsRequisition.job_role }}/>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                type={"submit"}
                onClick={() => {setValide(true)}}
                endIcon={<AddCircle />}>
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
