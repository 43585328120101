/** @format */

import { Edit, InfoOutlined, Undo, Visibility } from "@mui/icons-material";
import { Breadcrumbs, Grid, Link, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Callout } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionResend } from "../../../../../components/actionResend";
import { ActionReturned } from "../../../../../components/actionReturned";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { ActionAprovedForm } from "../../../../../components/old/actionApprovedForm";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import { EmptyData } from "../../../../../helpers/emptyData";
import {
  ClearCurrency,
  FormatCurrency,
} from "../../../../../helpers/formatCurrency";
import { FormatStatus } from "../../../../../helpers/formatStatus";
import { GenericError } from "../../../../../helpers/genericError";
import { IsPermissions } from "../../../../../helpers/isPermission";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { HistoryComponent } from "../helpers/historyComponent";

const validationSchema = yup.object({
  function: yup.string().nullable().required("Função é obrigatório"),
  quantity: yup
    .number()
    .nullable()
    .min(1, "Quantidate mínima: 1")
    .required("Quantidade é obrigatório"),
  salary: yup.string().nullable().required("Remuneração é obrigatório"),
  description: yup
    .string()
    .nullable()
    .required("Responsabilidades é obrigatório"),
});

export function PositionDetails() {
  const { id } = useParams();
  const router = useNavigate();
  const dispatch = useDispatch();
  const [data, setData]: any = useState({ solicitation_data: {} });
  const [openAprovedDialog, setOpenDialog] = useState(false);

  const permissions = useSelector((state: any) => state.user.permissions);
  const user = useSelector((state: any) => state.user.data);
  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.record.created_by_id;
  const edit =
    (permissions !== null &&
      permissions.indexOf("requestPositionDetailsEdit") !== -1 &&
      (data?.solicitation_data.status === "pending" ||
        data?.solicitation_data.status === "waiting_rh_user" ||
        data?.solicitation_data.status === "accounting_return")) ||
    isResendable;

  const formik = useFormik({
    initialValues: {
      function: "",
      salary: "",
      quantity: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(
        id,
        {
          ...values,
          salary: ClearCurrency(values.salary),
        },
        "/new_positions"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          window.history.back();
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/new_positions/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const data = response.data.data;
        setData(data);
        formik.setValues({
          function: data.record.name,
          quantity: data.record.quantity,
          salary: data.record.salary,
          description: data.record.observations,
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const requestInfo = [
    {
      title: "Código",
      value: data.solicitation_data.code,
    },
    {
      title: "Data",
      value: data.solicitation_data.date,
    },
    {
      title: "Solicitante",
      value: EmptyData(data.solicitation_data.created_by),
    },
    {
      title: "Status",
      value: FormatStatus(data.solicitation_data.status),
    },
    {
      title: "Justificativa",
      value: EmptyData(data.solicitation_data.action_justification),
    },
  ];

  const resend = (resendActionCallback: any) => {
    dispatch(updateLoading(true));
    PutInstante(
      id,
      {
        ...formik.values,
        salary: ClearCurrency(formik.values.salary),
      },
      "/new_positions"
    )
      .then((response: any) => {
        resendActionCallback();
        dispatch(updateLoading(false));
        window.history.back();
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <Grid container justifyContent={"space-between"}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/'>
              Inicio
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/position'>
              Requerimentos
            </Link>
            <Link
              underline='hover'
              sx={{ cursor: "pointer" }}
              color='inherit'
              href='/request/position'>
              Posições
            </Link>
            <Typography fontWeight={800}>Ver solicitação</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid container>
          <Callout.Root size='2' style={{ alignItems: "center" }}>
            <Callout.Icon>
              <InfoOutlined />
            </Callout.Icon>
            <Grid container flexDirection={"column"}>
              <Typography fontSize={16} fontWeight={800}>
                Informações da solicitação
              </Typography>
              <Grid container gap={"12px 96px"} mt={1}>
                {requestInfo.map((item: any) => (
                  <Grid key={item.title}>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={400}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Callout.Root>
        </Grid>
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da posição
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr 1fr"} display={"grid"} gap={2}>
              <TextField
                fullWidth
                id='function'
                label='Função'
                disabled
                placeholder={"Nome da função"}
                variant='outlined'
                value={formik.values.function}
                onChange={(event: any) =>
                  formik.setFieldValue("function", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.function && Boolean(formik.errors.function)
                }
                helperText={formik.touched.function && formik.errors.function}
              />
              <TextField
                fullWidth
                id='quantity'
                type='number'
                disabled={!edit}
                label='Quantidade'
                variant='outlined'
                value={formik.values.quantity}
                onChange={(event: any) =>
                  formik.setFieldValue("quantity", event.target.value)
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.quantity && Boolean(formik.errors.quantity)
                }
                helperText={formik.touched.quantity && formik.errors.quantity}
              />
              <TextField
                fullWidth
                id='salary'
                label='Remuneração'
                disabled={!edit}
                variant='outlined'
                value={formik.values.salary}
                onChange={(event: any) => {
                  formik.setFieldValue("salary", FormatCurrency(event));
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.salary && Boolean(formik.errors.salary)}
                helperText={formik.touched.salary && formik.errors.salary}
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <div>
                <TextField
                  fullWidth
                  id='description'
                  label='Responsabilidades'
                  variant='outlined'
                  disabled
                  multiline={true}
                  rows={4}
                  value={formik.values.description}
                  onChange={(event: any) =>
                    formik.setFieldValue("description", event.target.value)
                  }
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </div>
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              {isResendable && (
                <ActionResend
                  id={id}
                  url={"new_positions"}
                  disabled={!formik.isValid}
                  onSubmit={resend}
                />
              )}

              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              {data.solicitation_data.status === "waiting_rh_user" && (
                <>
                  {edit && (
                    <ButtonComponent
                      variant={"contained"}
                      type={"submit"}
                      endIcon={<Edit />}>
                      Editar
                    </ButtonComponent>
                  )}
                </>
              )}

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ActionReturned id={id} url={"new_positions"} />
                <ActionDisapproved id={id} url={"new_positions"} />
                <ActionAprovedForm
                  id={id}
                  url={"new_positions"}
                  open={openAprovedDialog}
                  onOpenAprovedDialog={setOpenDialog}
                />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["approved"]}
                managerAllowedStatuses={["approved"]}
                status={data?.solicitation_data.status}>
                <IsPermissions keyPrivate={"requestPositionDetailsView"}>
                  <ButtonComponent
                    onClick={() =>
                      router("/request/position/details/letter/" + id)
                    }
                    variant={"contained"}
                    type={"submit"}
                    endIcon={<Visibility color='primary' />}>
                    Ver carta de comunicação
                  </ButtonComponent>
                </IsPermissions>
              </StatusPermittedByRoleContainer>
            </Grid>

            <HistoryComponent
              data={data?.solicitation_data?.solicitation_histories}
              options={data.options}
            />
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
