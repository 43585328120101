/** @format */

export const permissionsMock: {
  id: number;
  uuid: "manager" | "rh" | "collaborator" | "accountant" | "admin";
  name: string;
  permissions: string[];
}[] = [
    {
      id: 1,
      uuid: "manager",
      name: "Gestor",
      permissions: [
        "home",
        "organizational",
        "dataPulse",
        "notification",
        "vacationTimeDashboard",
        "settingsAccount",
        // "filesAccount",
        "employeeActive",
        "employeeLeave",
        "employeePosition",
        "delegation",
        "delegationNew",
        "delegationDetails",
        "requestUpdateInformation",
        "requestUpdateInformationRequest",
        "requestUpdateInformationDetails",
        "requestUpdateInformationDetailsEdit",
        "requestPersonal",
        "requestPersonalNew",
        "requestPersonalDetails",
        "requestVacation",
        "requestVacationNew",
        "requestVacationDetails",
        "requestLeave",
        "requestLeaveNew",
        "requestLeaveDetails",
        "requestDismissals",
        "requestDismissalsNew",
        "requestDismissalsDetails",
        "requestDismissalsDetailsEdit",
        // "requestDismissalRequests",
        // "requestDismissalRequestsNew",
        // "requestDismissalRequestsDetails",
        "requestSalaryReview",
        "requestSalaryReviewNew",
        "requestSalaryReviewDetails",
        "requestPosition",
        "requestPositionNew",
        "requestPositionDetails",
        "requestPromote",
        "requestPromoteNew",
        "requestPromoteDetails",
        "requestTransfer",
        "requestTransferNew",
        "requestTransferDetails",
        "reportEmployee",
      ],
    },
    {
      id: 2,
      uuid: "rh",
      name: "Recursos humanos",
      permissions: [
        "home",
        "dataPulse",
        "notification",
        "audit",
        "usersRole",
        "permissionsAudit",
        "vacationTimeDashboard",
        "vacationCollaborator",
        "vacationCollaboratorNew",
        "vacationCollaboratorDetails",
        "settingsAccount",
        // "filesAccount",
        // "filesAccountUpload",
        "settingsRequiredUpdateInformation",
        "organizational",
        "processes",
        "employeeLeave",
        "employeeActive",
        "employeeInactive",
        "employeePending",
        "employeePendingDetails",
        "employeePendingActive",
        "employeePosition",
        "employeeNew",
        "reportEmployee",
        "delegation",
        "delegationNew",
        "delegationDetails",
        "requestLeave",
        "requestLeaveNew",
        "requestLeaveDetails",
        "requestLeaveDetailsEdit",
        "requestDismissals",
        "requestDismissalsNew",
        "requestDismissalsDetails",
        "requestDismissalsDetailsEdit",
        // "requestDismissalRequests",
        // "requestDismissalRequestsNew",
        // "requestDismissalRequestsDetails",
        "requestPosition",
        "requestPositionNew",
        "requestPositionDetails",
        "requestPositionDetailsEdit",
        "requestPositionDetailsRemove",
        "requestPromote",
        "requestPromoteNew",
        "requestPromoteDetails",
        "requestSalaryReview",
        "requestSalaryReviewNew",
        "requestSalaryReviewDetails",
        "requestSalaryReviewDetailsEdit",
        "requestTransfer",
        "requestTransferNew",
        "requestTransferDetails",
        "requestUpdateInformation",
        "requestUpdateInformationRequest",
        "requestUpdateInformationDetails",
        "requestUpdateInformationDetailsEdit",
        "requestPersonal",
        "requestPersonalNew",
        "requestPersonalDetails",
        "requestPersonalDetailsEdit",
        "requestVacation",
        "requestVacationNew",
        "requestVacationDetails",
        "permissionsRequiredForgetPassword",
        "permissionGroup",
        "permissionGroupNew",
        "permissionGroupDetails",
        "permissionsRequiredPersonnel",
        "permissionsRequiredDismissals",
        "permissionsRequiredLeave",
        "permissionsRequiredPosition",
        "permissionsRequiredPromote",
        "permissionsRequiredSalaryReview",
        "permissionsRequiredTransfer",
        "permissionsRequiredVacation",
        "permissionLetter",
        "permissionLetterNew",
        "permissionLetterDetails",
        "permissionsRequiredUpdateInformation",
        // "permissionsGrade",
        // "permissionsWorkflow",
        // "grades",
        // "reference",
        // "tableSalaryDashboard",
        // "workflow",
        // "workflowNew",
        // "workflowDetails",
         "calendar",
        // "jobRoles",
        // "jobRoleNew",
        // "jobRoleDetails",
        // "jobRoleGroups",
        // "jobRoleGroupNew",
        // "jobRoleGroupDetails",
        "permissionsRequiredUpdateInformationEdit",
        // "workSchedule",
        // "workScheduleNew",
        // "workScheduleDetails",
      ],
    },
    {
      id: 3,
      uuid: "collaborator",
      name: "Colaborador",
      permissions: [
        "home",
        "dataPulse",
        "notification",
        "organizational",
        "settingsAccount",
        // "filesAccount",
        "requestLeave",
        "requestLeaveNew",
        "requestLeaveDetails",
        "requestDismissals",
        "requestDismissalsNew",
        "requestDismissalsDetails",
        "requestUpdateInformation",
        "requestUpdateInformationRequest",
        "requestUpdateInformationDetails",
        "requestUpdateInformationDetailsEdit",
        "requestVacation",
        "requestVacationNew",
        "requestVacationDetails",
      ],
    },
    {
      id: 4,
      uuid: "accountant",
      name: "Contador",
      permissions: [
        "home",
        "notification",
        "organizational",
        "processes",
        "settingsAccount",
        // "filesAccount",
        "requestLeaveDetails",
        "requestDismissalsDetails",
        "requestUpdateInformationDetails",
        "requestUpdateInformationDetailsEdit",
        "requestVacationDetails",
        "requestSalaryReviewDetails",
        "requestPromoteDetails",
        "requestTransferDetails",
        "requestPositionDetails",
        "requestDetailsComplete",
        "requestDetailsAccountingReturned",
      ],
    },
    {
      id: 5,
      uuid: "admin",
      name: "Recursos humanos",
      permissions: [
        "home",
        "dataPulse",
        "notification",
        "audit",
        "usersRole",
        "permissionsAudit",
        "vacationTimeDashboard",
        "vacationCollaborator",
        "vacationCollaboratorNew",
        "vacationCollaboratorDetails",
        "settingsAccount",
        // "filesAccount",
        // "filesAccountUpload",
        "settingsRequiredUpdateInformation",
         "calendar",
        "organizational",
        "processes",
        "employeeLeave",
        "employeeActive",
        "employeeInactive",
        "employeePending",
        "employeePendingDetails",
        "employeePendingActive",
        "employeePosition",
        "employeeNew",
        "delegation",
        "delegationNew",
        "delegationDetails",
        "requestLeave",
        "requestLeaveNew",
        "requestLeaveDetails",
        "requestLeaveDetailsEdit",
        "requestDismissals",
        "requestDismissalsNew",
        "requestDismissalsDetails",
        "requestDismissalsDetailsEdit",
        "requestDismissalRequests",
        "requestDismissalRequestsNew",
        "requestDismissalRequestsDetails",
        "requestPosition",
        "requestPositionNew",
        "requestPositionDetails",
        "requestPositionDetailsEdit",
        "requestPositionDetailsRemove",
        "requestPromote",
        "requestPromoteNew",
        "requestPromoteDetails",
        "requestSalaryReview",
        "requestSalaryReviewNew",
        "requestSalaryReviewDetails",
        "requestSalaryReviewDetailsEdit",
        "requestTransfer",
        "requestTransferNew",
        "requestTransferDetails",
        "requestUpdateInformation",
        "requestUpdateInformationRequest",
        "requestUpdateInformationDetails",
        "requestUpdateInformationDetailsEdit",
        "requestPersonal",
        "requestPersonalNew",
        "requestPersonalDetails",
        "requestPersonalDetailsEdit",
        "requestVacation",
        "requestVacationNew",
        "requestVacationDetails",
        "permissionsRequiredForgetPassword",
        "permissionGroup",
        "permissionGroupNew",
        "permissionGroupDetails",
        "permissionsRequiredPersonnel",
        "permissionsRequiredDismissals",
        "permissionsRequiredLeave",
        "permissionsRequiredPosition",
        "permissionsRequiredPromote",
        "permissionsRequiredSalaryReview",
        "permissionsRequiredTransfer",
        "permissionsRequiredVacation",
        "permissionLetter",
        "permissionLetterNew",
        "permissionLetterDetails",
        "permissionsRequiredUpdateInformation",
        // "permissionsGrade",
        // "permissionsWorkflow",
        // "grades",
        // "reference",
        // "tableSalaryDashboard",
        // "workflow",
        // "workflowNew",
        // "workflowDetails",
        // "jobRoles",
        // "jobRoleNew",
        // "jobRoleDetails",
        // "jobRoleGroups",
        // "jobRoleGroupNew",
        // "jobRoleGroupDetails",
        "permissionsRequiredUpdateInformationEdit",
      ],
    },
  ];
