/** @format */

export const optionsForget = [
  {
    label: "Recuperação com reset para senha padrão",
    value: "1",
    key: "default",
  },
  {
    label: "Recuperação através de perguntas",
    value: "2",
    key: "secret_questions",
  },
  {
    label: "Recuperação com envio de senha por email",
    value: "3",
    key: "email",
  },
];
