/** @format */

import { Grid } from "@mui/material";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { aviso_e_social } from "../../../../../config/constants";

export function Form({ formik, data }: any) {
  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} display={"grid"} gap={2}>
        <SelectField
          formik={formik}
          id={"with_position"}
          label='Transferência com a posição ?'
          options={aviso_e_social}
        />
        <AutoCompleteField
          formik={formik}
          id='new_manager_id'
          label='Gestor Futuro'
          labelcode={true}
          options={data.options.managers.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))}
        />
        <InputDateField
          formik={formik}
          id='transfer_date'
          label='Data da transferência'
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <InputTextField
          formik={formik}
          id='observations'
          label='Comentários'
          multiline={true}
          rows={4}
        />
      </Grid>
    </>
  );
}
