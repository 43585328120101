/** @format */

import { MoreVert } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { DropdownMenu } from "@radix-ui/themes";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FormatDate } from "../helpers/formatDate";
import { IsPermissions } from "../helpers/isPermission";
import { DialogDate } from "../pages/protected/employee/active/helpers/dialogDate";
import { DialogDismissalNew } from "../pages/protected/request/dismissals/helpers/dialogDismissalNew";
import { PostInstante } from "../services/api";

interface Props {
  data: any;
  dataIndex: number;
}

export const ActionsUser = ({ data, dataIndex }: Props) => {
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  const [openModalDismissal, setOpenModalDismissal] = useState({
    open: false,
    type: "",
    date: "",
  });
  return (
    <>
      {openModalDismissal.open && user.role === "rh" && (
        <DialogDate
          {...openModalDismissal}
          onSubmit={() => {
            router(`/request/dismissals/new/${data[dataIndex].uuid}`);
          }}
          setOpenModalDate={setOpenModalDismissal}
        />
      )}
      {openModalDismissal.open && user.role !== "rh" && (
        <DialogDismissalNew
          {...openModalDismissal}
          close={() =>
            setOpenModalDismissal({ open: false, type: "", date: "" })
          }
        />
      )}
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Tooltip title={"Ver ações"}>
            <IconButton>
              <MoreVert />
            </IconButton>
          </Tooltip>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <IsPermissions keyPrivate='requestUpdateInformationRequest'>
            <DropdownMenu.Item
              onClick={() =>
                router(
                  `/request/updateInformation/request/${data[dataIndex].uuid}`
                )
              }
              shortcut='->'>
              Ver informações
            </DropdownMenu.Item>
          </IsPermissions>
          <IsPermissions keyPrivate='requestVacationNew'>
            <DropdownMenu.Item
              onClick={() =>
                router(`/request/vacation/new/${data[dataIndex].uuid}`)
              }
              shortcut='->'>
              Solicitar férias
            </DropdownMenu.Item>
          </IsPermissions>
          <IsPermissions keyPrivate='requestLeaveNew'>
            <DropdownMenu.Item
              onClick={() => {
                if (data[dataIndex]?.status === "Afastado") {
                  PostInstante(
                    null,
                    null,
                    `/users/${data[dataIndex].uuid}/user_absence_id`
                  ).then(() => {
                    router(`/request/leave/new/${data[dataIndex].uuid}`);
                  });
                  return;
                }
                router(`/request/leave/new/${data[dataIndex].uuid}`);
              }}
              shortcut='->'>
              Efetuar afastamento
            </DropdownMenu.Item>
          </IsPermissions>
          <IsPermissions keyPrivate='requestDismissalsNew'>
            <DropdownMenu.Item
              onClick={() => {
                if (
                  data[dataIndex]?.business_unit_job_security_date &&
                  new Date(data[dataIndex]?.business_unit_job_security_date) >=
                  new Date()
                ) {
                  setOpenModalDismissal({
                    open: true,
                    type:
                      data[dataIndex]?.business_unit_job_security_type ?? "-",
                    date: FormatDate(
                      data[dataIndex]?.business_unit_job_security_date ?? "-"
                    ),
                  });
                } else {
                  router(
                    `/request/dismissals/new/${data[dataIndex].uuid}`
                  );
                }
              }}
              shortcut='->'>
              Efetuar desligamento
            </DropdownMenu.Item>
          </IsPermissions>
          {user?.id !== data[dataIndex]?.id && (
            <>
              <IsPermissions keyPrivate='vacationCollaboratorNew'>
                <DropdownMenu.Item
                  onClick={() =>
                    router(`/vacation/new/${data[dataIndex].uuid}`)
                  }
                  shortcut='->'>
                  Criar periodo de férias
                </DropdownMenu.Item>
              </IsPermissions>
              <IsPermissions keyPrivate='requestPromoteNew'>
                <DropdownMenu.Item
                  onClick={() =>
                    router(`/request/promote/new/${data[dataIndex].uuid}`)
                  }
                  shortcut='->'>
                  Solicitar promoção
                </DropdownMenu.Item>
              </IsPermissions>
              <IsPermissions keyPrivate='requestSalaryReviewNew'>
                <DropdownMenu.Item
                  onClick={() =>
                    router(`/request/salaryReview/new/${data[dataIndex].uuid}`)
                  }
                  shortcut='->'>
                  Solicitar revisão salarial
                </DropdownMenu.Item>
              </IsPermissions>
              <IsPermissions keyPrivate='requestTransferNew'>
                <DropdownMenu.Item
                  onClick={() => {
                    router(`/request/transfer/new/${data[dataIndex].uuid}`);
                  }}
                  shortcut='->'>
                  Solicitar transferência
                </DropdownMenu.Item>
              </IsPermissions>
              <IsPermissions keyPrivate='filesAccountUpload'>
                <DropdownMenu.Item
                  onClick={() => {
                    router(`/filesUpload/${data[dataIndex].uuid}`);
                  }}
                  shortcut='->'>
                  Adicionar demonstrativo
                </DropdownMenu.Item>
              </IsPermissions>
            </>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
};
