/** @format */

import { Download, UploadFile } from "@mui/icons-material";
import { Chip, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { useRef } from "react";

interface Props {
  formik: any;
  remove?: boolean;
}

export const InputFile = ({ formik, remove = true }: Props) => {
  const fileInputRef: any = useRef(null);
  const theme = useTheme();
  const onFileDrop = (file: any) => {
    const filesAll: any = formik.values.files;
    filesAll.push(file);
    formik.setFieldValue("files", filesAll);
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      onFileDrop(file);
    }
  };

  const handleDragOver: any = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };
  return (
    <>
      <Grid container mt={2}>
        <div
          style={{
            border: `1px ${
              formik.errors.files && formik.touched.files
                ? theme.palette.error.main
                : theme.palette.text.secondary
            } dashed`,
            color:
              formik.errors.files && formik.touched.files
                ? theme.palette.error.main
                : theme.palette.text.secondary,
            cursor: "pointer",
            borderRadius: 10,
            width: "inherit",
          }}
          className='ContextMenuTrigger'
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onClick={openFileDialog}>
          <input
            type='file'
            accept='*'
            ref={fileInputRef}
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
          <p style={{ display: "flex", justifyContent: "center" }}>
            <UploadFile style={{ marginRight: 12 }} /> Arraste e solte um
            arquivo aqui ou clique para selecionar um arquivo.
          </p>
        </div>
      </Grid>
      {formik.errors.files && (
        <Grid container mt={0.5} ml={2}>
          <Typography fontSize={"0.75rem"} textAlign={"center"} color={"error"}>
            {formik.errors.files}
          </Typography>
        </Grid>
      )}
      {formik.values.files.length > 0 && (
        <Grid container mt={2}>
          <Typography>Arquivos anexados</Typography>
        </Grid>
      )}
      <Grid container mt={2} gap={2}>
        {remove
          ? formik.values.files.map((file: any, index: number) => (
              <Tooltip title={"Remover arquivo"}>
                <Chip
                  color={"primary"}
                  onDelete={() => {
                    const filesAll: any = formik.values.files;
                    filesAll.splice(index, 1);
                    formik.setFieldValue("files", filesAll);
                  }}
                  label={file.name}
                  variant='outlined'
                />
              </Tooltip>
            ))
          : formik.values.files.map((file: any, index: number) => (
              <Tooltip title={"Baixar arquivo"}>
                <a
                  title={file.name}
                  href={file.url}
                  target='_blank'
                  style={{
                    border: theme.palette.primary.main + " 1px solid",
                    color: theme.palette.primary.main,
                    padding: 8,
                    fontSize: 12,
                    borderRadius: 24,
                    fontWeight: 800,
                    textDecorationLine: "none",
                    display: "flex",
                    gap: 4,
                    alignItems: "center",
                  }}
                  download={file.name}
                  rel='noreferrer'>
                  <Download fontSize={"small"} />
                  {file.name}
                </a>
              </Tooltip>
            ))}
      </Grid>
    </>
  );
};
