/** @format */

import { Grid } from "@mui/material";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { aviso_e_social } from "../../../../../config/constants";
import {
  ClearCurrency,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";

export function Form({ formik, data, edit }: any) {
  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <SelectField
          options={aviso_e_social}
          formik={formik}
          id='promote_position'
          label='A promoção será para a mesma posição?'
          disabled={edit}
          onChange={(event: any) => {
            if (event.target.value === true) {
              const option =
                data.options.positions.filter(
                  (item: any) => item.value === data.user_data.position_id
                )[0] ?? {};
              formik.setFieldValue(
                "target_position_id",
                data.user_data.position_id
              );
              formik.setFieldValue(
                "target_position",
                data.user_data.position_id +
                  " - " +
                  data.user_data.position_name
              );
              formik.setFieldValue("target_job_role_id", option.job_role_id);
              formik.setFieldValue("target_sector_id", option.sector_id);
              formik.setFieldValue(
                "target_cost_center_id",
                option.cost_center_id
              );
              formik.setFieldValue(
                "target_business_unit_id",
                option.business_unit_id
              );
            }
          }}
        />
        {formik.values.promote_position ? (
          <InputTextField
            formik={formik}
            disabled={true}
            id='target_position'
            label='Posição destino'
          />
        ) : (
          <AutoCompleteField
            formik={formik}
            id='target_position_id'
            label='Posição destino'
            disabled={formik.values.promote_position || edit}
            options={data.options.positions.map((option: any) => ({
              value: option.value,
              label: `${option.code} - ${option.job_role_name}`,
            }))}
            onChange={(event: any, newValue: any) => {
              const option =
                data.options.positions.filter(
                  (item: any) => item.value === newValue
                )[0] ?? {};
              formik.setFieldValue("target_job_role_id", option.job_role_id);
              formik.setFieldValue("target_sector_id", option.sector_id);
              formik.setFieldValue(
                "target_cost_center_id",
                option.cost_center_id
              );
              formik.setFieldValue(
                "target_business_unit_id",
                option.business_unit_id
              );
            }}
          />
        )}
        <AutoCompleteField
          formik={formik}
          disabled={edit}
          labelcode={true}
          id='target_job_role_id'
          label='Cargo destino'
          options={data.options.job_role}
        />
        <InputDateField
          formik={formik}
          id='movement_date'
          disabled={edit}
          label='Data da movimentação'
        />
        <InputTextField
          formik={formik}
          disabled={edit}
          id='percent'
          type={"number"}
          label='Porcentagem (%)'
          onChange={(event: any) => {
            if (event.target.value.length === 0) {
              formik.setFieldValue("target_salary", MaskCurrency(0));
            } else {
              formik.setFieldValue(
                "target_salary",
                MaskCurrency(
                  ((parseFloat(event.target.value) + 100) *
                    parseFloat(formik.values.actual_salary)) /
                    100
                )
              );
            }
          }}
        />
        <InputCurrencyField
          formik={formik}
          disabled={edit}
          id='target_salary'
          label='Salário futuro'
          onChange={(event: any) => {
            formik.setFieldValue(
              "percent",
              ReadjustSalary(
                Number(formik.values.actual_salary),
                ClearCurrency(FormatCurrency(event))
              )
            );
          }}
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <InputTextField
          formik={formik}
          disabled={edit}
          id='observations'
          label='Comentários'
          multiline={true}
          rows={4}
        />
      </Grid>
    </>
  );
}
