/** @format */

import { Filter } from "../../../../../components/filter";
import { InputDateField } from "../../../../../components/inputs/inputDateField";

export function Filters({ formik }: any) {
  return (
    <Filter
      formik={formik}
      id={"vacation_dashboard"}
      keys={{ from: { label: "Data inicio" }, to: { label: "Data final" } }}>
      <InputDateField formik={formik} id='from' label='Data Inícial' />
      <InputDateField formik={formik} id='to' label='Data Final' />
    </Filter>
  );
}
