/** @format */

import { useEffect, useState } from "react";

import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { GetInstance, PostInstante } from "../../../../services/api";
import { MENU_ITENS, MESES } from "./helpers/constants";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";

interface DataEntry {
  solicitation_calendar_type: string;
  id: number;
  opening_date: string;
  closing_date: string;
}

interface CalendarEntry {
  opening_date: Dayjs | null;
  closing_date: Dayjs | null;
  id: number | null;
}

interface Calendars {
  [solicitation_calendar_type: string]: CalendarEntry[];
}

function generateCalendar(data: DataEntry[]): Calendars {
  const calendars: Calendars = {};

  const startYear = dayjs().year();

  const initializeMonths = (): CalendarEntry[] =>
    Array(24)
      .fill(null)
      .map(() => ({ opening_date: null, closing_date: null, id: null }));

  data.forEach(({ solicitation_calendar_type }) => {
    // if do not exists especific key initilize months with null data
    if (!calendars[solicitation_calendar_type]) {
      calendars[solicitation_calendar_type] = initializeMonths();
    }
  });

  data.forEach(
    ({ solicitation_calendar_type, opening_date, closing_date, id }) => {
      const date = dayjs(closing_date);

      const monthIndex = (date.year() - startYear) * 12 + date.month();

      if (calendars[solicitation_calendar_type]) {
        calendars[solicitation_calendar_type][monthIndex] = {
          id,
          opening_date: opening_date ? dayjs(opening_date) : null,
          closing_date: dayjs(closing_date),
        };
      }
    }
  );

  return calendars;
}

export function Calendar() {
  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedSolicitation, setSelectedSolicitation] = useState("promotion");
  const [saveDisabled, setSaveDisabled] = useState(false);

  const currentYear = dayjs().year();

  const years = [
    { id: currentYear.toString(), label: currentYear },
    { id: (currentYear + 1).toString(), label: (currentYear + 1).toString() },
  ];

  const formik: any = useFormik({
    initialValues: {
      calendars: {
        promotion: Array(24).fill({
          opening_date: null,
          closing_date: null,
          id: null,
        }),
      },
    },
    onSubmit: () => {
      setSaveDisabled(true);
      const result = Object.entries(
        formik.values.calendars as Record<
          string,
          {
            id: number | null;
            type: string;
            opening_date: string;
            closing_date: string;
          }[]
        >
      ).flatMap(([key, values]) =>
        values
          .filter((entry) => entry.closing_date || entry.id)
          .map((entry) => {
            if (!entry.closing_date) {
              return {
                id: entry.id,
                type: key,
                _destroy: true,
              };
            }
            return {
              id: entry.id,
              type: key,
              opening_date: entry.opening_date,
              closing_date: entry.closing_date,
            };
          })
      );

      PostInstante(null, { calendars: result }, "/calendars")
        .then((response: any) => {
          toast.success(response?.data?.message);

          GetInstance({}, "/calendars")
            .then((response: any) => {
              const savedCalendars = generateCalendar(response.data.data);

              formik.setFieldValue("calendars", {
                ...savedCalendars,
                ...(savedCalendars[selectedSolicitation] === undefined && {
                  [selectedSolicitation]: Array(24).fill({
                    opening_date: null,
                    closing_date: null,
                    id: null,
                  }),
                }),
              });

              setSaveDisabled(false);
            })
            .catch((e: any) => {
              if (e.code !== "ERR_CANCELED") {
                toast.error(
                  e?.response?.data?.message ??
                    "Erro inesperado, tente novamente."
                );
                setSaveDisabled(false);
              }
            });
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            // dispatch(updateLoading(false));
            toast.error(
              e?.response?.data?.message ?? "Erro inesperado, tente novamente."
            );
          }
        });
    },
  });

  useEffect(() => {
    GetInstance({}, "/calendars")
      .then((response: any) => {
        const savedCalendars = generateCalendar(response.data.data);
        formik.setFieldValue("calendars", {
          ...savedCalendars,
          ...(savedCalendars[selectedSolicitation] === undefined && {
            [selectedSolicitation]: Array(24).fill({
              opening_date: null,
              closing_date: null,
              id: null,
            }),
          }),
        });
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickSolicitationType = (id: string) => {
    if (!formik.values.calendars[id]) {
      formik.setFieldValue(
        `calendars[${id}]`,
        Array(24).fill({ opening_date: null, closing_date: null })
      );
    }
    setSelectedSolicitation(id);
  };

  return (
    <Box>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Configurações", value: "/settings/calendar" },
          { label: "Cartas" },
        ]}
      />
      <Box display="flex" flexDirection="row" gap={2} marginTop={3}>
        <Paper
          sx={{ width: "100%", maxWidth: 260, bgcolor: "background.paper" }}
        >
          <nav>
            <List dense>
              {MENU_ITENS.map((item, index) => (
                <ListItem>
                  <ListItemButton
                    sx={{ borderRadius: "12px" }}
                    selected={selectedSolicitation === item.id}
                    onClick={() => handleClickSolicitationType(item.id)}
                  >
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </nav>
        </Paper>

        <form onSubmit={formik.handleSubmit}>
          <Paper>
            <Tabs value={selectedYear} onChange={(e, x) => setSelectedYear(x)}>
              {years.map((year) => (
                <Tab label={year.label} id={year.id} />
              ))}
            </Tabs>
            <Box paddingY={2} paddingX={3}>
              <Typography fontSize={18} fontWeight={800}>
                {
                  MENU_ITENS.find((item) => item.id === selectedSolicitation)
                    ?.label
                }
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              paddingY={2}
              paddingX={5}
            >
              {MESES.map((mes) => {
                if (
                  !formik.values.calendars &&
                  !formik.values.calendars[selectedSolicitation]
                )
                  return <></>;

                let current_month = mes.id - 1;
                if (selectedYear === 1) {
                  current_month = current_month + 12;
                }

                return (
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    gap={2}
                  >
                    <Typography
                      alignSelf="center"
                      fontSize={14}
                      fontWeight={500}
                    >
                      {mes.label}
                    </Typography>
                    <Box display="flex" gap={2}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            id: `calendars[${selectedSolicitation}][${current_month}]opening_date`,
                            onBlur: formik.handleBlur,
                          },
                        }}
                        label="Abertura"
                        disabled={dayjs(`${years[selectedYear].id}-${mes.id}`)
                          .endOf("month")
                          .isBefore(dayjs())}
                        minDate={dayjs(
                          `${years[selectedYear].id}-${mes.id}`
                        ).startOf("month")}
                        maxDate={dayjs(
                          `${years[selectedYear].id}-${mes.id}`
                        ).endOf("month")}
                        value={
                          formik.values.calendars[selectedSolicitation][
                            current_month
                          ]
                            ? formik.values.calendars[selectedSolicitation][
                                current_month
                              ].opening_date
                            : null
                        }
                        onChange={(value: any) => {
                          formik.setFieldValue(
                            `calendars[${selectedSolicitation}][${current_month}]opening_date`,
                            value
                          );
                        }}
                      />
                      <DatePicker
                        slotProps={{
                          textField: {
                            id: `calendars[${selectedSolicitation}][${
                              mes.id - 1
                            }]closing_date`,
                            onBlur: formik.handleBlur,
                          },
                        }}
                        label="Fechamento"
                        disabled={dayjs(`${years[selectedYear].id}-${mes.id}`)
                          .endOf("month")
                          .isBefore(dayjs())}
                        minDate={dayjs(
                          `${years[selectedYear].id}-${mes.id}`
                        ).startOf("month")}
                        maxDate={dayjs(
                          `${years[selectedYear].id}-${mes.id}`
                        ).endOf("month")}
                        value={
                          formik.values.calendars[selectedSolicitation][
                            current_month
                          ]
                            ? formik.values.calendars[selectedSolicitation][
                                current_month
                              ].closing_date
                            : null
                        }
                        onChange={(value: any) => {
                          formik.setFieldValue(
                            `calendars[${selectedSolicitation}][${current_month}]closing_date`,
                            value
                          );
                        }}
                      />
                    </Box>
                  </Box>
                );
              })}

              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant={"contained"}
                  onClick={formik.handleSubmit}
                  endIcon={<Save />}
                  disabled={saveDisabled}
                >
                  Salvar modificações
                </Button>
              </Box>
            </Box>
          </Paper>
        </form>
      </Box>
    </Box>
  );
}
