/** @format */

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FilterEmpty } from "../../../helpers/emptyData";
import { GenericError } from "../../../helpers/genericError";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../api";

let timerSearch: any;

export default function useReportUsers(values?: any) {
  const [data, setData]: any = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);
  const [reset, setReset] = useState(true);
  const dispatch = useDispatch();

  const getCall = useCallback(async () => {
    try {
      setReset(false);
      dispatch(updateLoading(true));
      const { data } = await GetInstance(
        {
          ...values,
          page: page + 1,
          per_page: perPage,
          search,
          order_by: order,
        },
        "/reports/users"
      );
      setData(FilterEmpty(data.data.data.data));
      setTotal(data.data.data.total_count);
      dispatch(updateLoading(false));
    } catch (e: any) {
      GenericError(e, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, page, search, perPage, order, reset]);

  useEffect(() => {
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, reset, search, perPage, order]);

  const searchCall = (value: string) => {
    clearTimeout(timerSearch);
    timerSearch = setTimeout(() => {
      setPage(0);
      setSearch(value);
    }, 500);
  };

  return {
    data,
    total,
    page,
    perPage,
    setPage,
    setReset,
    setPerPage,
    setOrder,
    order,
    searchCall,
  };
}
