/** @format */

import { CancelOutlined, Save } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Dialog, Flex } from "@radix-ui/themes";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InputTextField } from "../../../../components/inputs/inputTextField";
import RadioGroupComponent from "../../../../components/inputs/radioGroup";

export function DialogModal({
  formik,
  openModal,
  setOpenModal,
  setValide,
  options
}: any) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <Dialog.Root
        open={openModal.open}
        onOpenChange={() => {
          setValide(false);
          setOpenModal({ open: false, type: "", values: {} });
          formik.handleReset();
        }}>
        <Dialog.Content maxWidth='450px'>
          <Dialog.Title>
            Valor
          </Dialog.Title>
          <Dialog.Description size='2' mb='4'>
            Verifique a grade e a referência selecionada e informe o valor que deseja.
          </Dialog.Description>
          <Grid mt={1} gap={2} gridTemplateColumns={"1fr 1fr"}
            display={"grid"}>
            <RadioGroupComponent formik={formik} label='Grade' id='grade_id' options={options.grades.filter((item: any) => item.value === formik.values.grade_id)} />
            <RadioGroupComponent formik={formik} label='Referência' id='grade_column_id' options={options.grade_columns.filter((item: any) => item.value === formik.values.grade_column_id)} />
          </Grid>
          <Grid mt={2} gap={2} gridTemplateColumns={"1fr"}
            display={"grid"}>
            <InputTextField formik={formik} label='Valor' id='item_value' />
          </Grid>
          <Flex gap='3' mt='4' justify='end'>
            <Dialog.Close>
              <ButtonComponent
                color='error'
                variant='outlined'
                endIcon={<CancelOutlined />}>
                Cancelar
              </ButtonComponent>
            </Dialog.Close>
            <ButtonComponent
              color='success'
              variant='contained'
              onClick={() => {
                setValide(true);
                formik.handleSubmit();
              }}
              endIcon={
                <Save />
              }>
              Salvar
            </ButtonComponent>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </form>
  );
}
