/** @format */

import styled from "@emotion/styled";
import { AppBar, Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { drawerWidth } from "../config/constants";
import { permissionsMock } from "../config/permissionsMock";
import { GenericError } from "../helpers/genericError";
import { getNotificationsUnread } from "../pages/protected/notifications/helpers/getNotification";
import { updateLoading, updateLoadingSessao } from "../redux/reducers/common";
import {
  updateCompanySettings,
  updateUser,
  updateUserDelegation,
  updateUserPermissions,
} from "../redux/reducers/user";
import { GetInstance } from "../services/api";
import { GetPhoto } from "../services/protected/getPhoto";
import Header from "./header/header";
import Sidebar from "./header/sidebar";
import { SecretQuestionsRoute } from "./secretQuestion";

export const ProtectedRoute = ({ children }: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const mode: any = Cookies.get("theme") ?? "light";
  const router = useNavigate();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const [leftDrawerOpened, setLeftDrawer]: any = useState(!matchDownMd);
  const user = Cookies.get("auth");

  useEffect(() => {
    if (!user) {
      router("/login");
    } else {
      dispatch(updateLoadingSessao(true));
      GetInstance({}, "users/sessao")
        .then(async (response: any) => {
          dispatch(
            updateUserPermissions(
              permissionsMock.filter(
                (item: any) => item.uuid === response.data.data.record.role
              )[0].permissions
            )
          );
          dispatch(updateUser(response.data.data.record));
          dispatch(updateUserDelegation(response.data.data.delegation_data));
          GetPhoto(response.data.data.record.uuid, dispatch);
          GetInstance({}, "/company_settings/")
            .then(async (response: any) => {
              dispatch(updateCompanySettings(response.data.data.record));
            })
            .catch((e: any) => {
              GenericError(e, dispatch);
            });
          getNotificationsUnread(dispatch);
          dispatch(updateLoadingSessao(false));
          const interval = setInterval(() => {
            getNotificationsUnread(dispatch);
          }, 30000);
          clearInterval(interval);
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            dispatch(updateLoading(false));
            router("/login");
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Main = styled("main", {
    shouldForwardProp: (prop: any) => prop !== "open" && prop !== "theme",
  })(() => ({
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: 64,
    padding: "24px",
    borderTopLeftRadius: leftDrawerOpened ? 20 : 0,
    height: `calc(100dvh - 64px)`,
    backgroundColor:
      mode === "light" ? theme.palette.grey.A100 : theme.palette.grey.A700,
    transition: theme.transitions.create(
      "margin",
      leftDrawerOpened
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }
    ),
    [theme.breakpoints.up("md")]: {
      marginLeft: leftDrawerOpened ? 0 : -drawerWidth,
      width: leftDrawerOpened ? `calc(100% - ${drawerWidth}px)` : "100%",
      borderTopLeftRadius: leftDrawerOpened ? 20 : 0,
      height: "auto",
      minHeight: `calc(100dvh - 64px)`,
    },
    [theme.breakpoints.down("md")]: {
      width: `100dvw`,
      height: "auto",
      borderTopLeftRadius: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: `100dvw`,
      height: "auto",
      padding: "16px",
      borderTopLeftRadius: 0,
    },
  }));

  return (
    <SecretQuestionsRoute>
      <Box sx={{ display: "flex" }}>
        <AppBar
          enableColorOnDark
          position='fixed'
          color='inherit'
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.default,
            transition: leftDrawerOpened
              ? theme.transitions.create("width")
              : "none",
          }}>
          <Toolbar style={{ minHeight: "64px" }}>
            <Header
              open={leftDrawerOpened}
              handleLeftDrawerToggle={() => setLeftDrawer(!leftDrawerOpened)}
            />
          </Toolbar>
        </AppBar>
        <Sidebar
          drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
          drawerToggle={() => setLeftDrawer(!leftDrawerOpened)}
        />
        <Main>{children}</Main>
      </Box>
    </SecretQuestionsRoute>
  );
};
