/** @format */

import { Cancel, UploadFile } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ButtonComponent } from "../../../../../../components/buttonComponent";
import { fields } from "../../../../../../config/fields";
import { GenericError } from "../../../../../../helpers/genericError";
import { updateLoading } from "../../../../../../redux/reducers/common";
import {
  PostInstante,
  PostInstanteFormData,
  RemoveInstance,
} from "../../../../../../services/api";
import { validationSchema } from "./constants";
import { UploadDocument } from "./uploadDocument";

interface Props {
  filesForm: any;
  setFileForm: any;
  data: any;
  open: any;
  setOpen: any;
  id: any;
  url: string;
  urlBack: string;
  itensRequired: number;
}
export function ModalUploadDocument({
  filesForm,
  setFileForm,
  data,
  open,
  setOpen,
  id,
  url,
  urlBack,
  itensRequired,
}: Props) {
  const dispatch = useDispatch();
  const router = useNavigate();
  const formik: any = useFormik({
    initialValues: {
      files: [],
    },
    validationSchema: validationSchema(itensRequired),
    onSubmit: () => {
      setOpen(false);
      dispatch(updateLoading(true));
      PostInstante(null, { payload: data, target_user_uuid: id }, url)
        .then((response: any) => {
          const formData = new FormData();
          // eslint-disable-next-line array-callback-return
          formik.values.files.map((file: any) => {
            formData.append("files[]", file);
          });
          PostInstanteFormData(
            null,
            formData,
            "/solicitations/" +
              response.data.data.solicitation_uuid +
              "/upload_files"
          )
            .then(() => {
              dispatch(updateLoading(false));
              toast.success("Solicitação criada com sucesso");
              setTimeout(() => {
                router(urlBack);
              }, 3000);
            })
            .catch((e: any) => {
              if (e.code !== "ERR_CANCELED") {
                RemoveInstance(id, url)
                  .then(() => {
                    dispatch(updateLoading(false));
                    toast.error(
                      "Sua solicitação não pode ser criada, pois houve um erro ao anexar os arquivos."
                    );
                  })
                  .catch((e: any) => {
                    if (e.code !== "ERR_CANCELED") {
                      dispatch(updateLoading(false));
                      toast.error(
                        "Sua solicitação não pode ser criada, pois houve um erro ao anexar os arquivos."
                      );
                    }
                  });
              }
            });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });
  return (
    <AlertDialog.Root open={open} onOpenChange={setOpen}>
      <AlertDialog.Content maxWidth='450px'>
        <AlertDialog.Title>
          Inclusão de documentos de comprovação
        </AlertDialog.Title>
        <AlertDialog.Description size='2'>
          Insira os documentos que comprovem as alterações realizadas abaixo:
        </AlertDialog.Description>
        {filesForm.map((option: any, index: number) => (
          <div id={option.keyValue} key={option.keyValue}>
            <AlertDialog.Description size='2' mt={"2"}>
              <Typography fontWeight={800}>
                -{" "}
                {fields.filter((item: any) => option.keyValue === item.value)[0]
                  ?.label ?? "Não possui"}
                :
              </Typography>
            </AlertDialog.Description>
            {Array.isArray(option.file) ? (
              option.file.map((sub: any, index2: number) => (
                <>
                  <AlertDialog.Description size='2' mt={"4"} ml='4'>
                    <Typography fontSize={14} fontWeight={500}>
                      - {option.names[index2]}:
                    </Typography>
                  </AlertDialog.Description>
                  <UploadDocument
                    option={option}
                    index={index + index2 + 10}
                    index2={index2}
                    formik={formik}
                    filesForm={filesForm}
                    setFileForm={setFileForm}
                  />
                </>
              ))
            ) : (
              <UploadDocument
                option={option}
                index={index}
                formik={formik}
                filesForm={filesForm}
                setFileForm={setFileForm}
              />
            )}
          </div>
        ))}
        {formik.errors.files && (
          <AlertDialog.Description size='2' mt={"2"}>
            <Grid container justifyContent={"center"}>
              <Typography
                fontSize={"0.75rem"}
                textAlign={"center"}
                color={"error"}>
                {formik.errors.files}
              </Typography>
            </Grid>
          </AlertDialog.Description>
        )}
        <Flex gap='3' mt='4' justify='end'>
          <AlertDialog.Cancel onClick={() => formik.handleReset()}>
            <ButtonComponent
              color='error'
              variant={"outlined"}
              endIcon={<Cancel />}>
              Cancelar
            </ButtonComponent>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <ButtonComponent
              color='success'
              variant={"contained"}
              onClick={formik.handleSubmit}
              endIcon={<UploadFile />}>
              Enviar documentos
            </ButtonComponent>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
}
