/** @format */

import { updateLoading } from "../../redux/reducers/common";
import { updatePhoto } from "../../redux/reducers/user";
import { request } from "../api";

export async function GetPhoto(id: string, dispatch: any) {
  try {
    dispatch(updateLoading(true));
    const { data } = await request().get(`/users/${id}/avatar`);
    if (data !== undefined) {
      dispatch(updatePhoto(data.data.avatar));
      setTimeout(() => {
        dispatch(updateLoading(false));
      }, 2500);
    } else {
      dispatch(updateLoading(false));
    }
  } catch (e) {
    dispatch(updateLoading(false));
  }
}
