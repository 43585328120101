/** @format */

import { Grid } from "@mui/material";

import { useFormik } from "formik";
import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import { EmptyFilter } from "../../../components/emptyFilter";
import { validationSchema } from "./helpers/constants";
import { Filters } from "./helpers/filters";

const options = { years: [], months: [], type_statements: [] }
export function FilesAccount() {
  const formik: any = useFormik({
    initialValues: {
      type_statement: "",
      month: "",
      year: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      console.log('submit')
    },
  });
  return (
    <Grid container gap={4}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio", value: "/" },
            { label: "Demostrativos de pagamento" },
          ]}
        />
        <Grid />
        <Filters formik={formik} options={options} />
        <EmptyFilter />
      </Grid>

    </Grid>
  );
}
