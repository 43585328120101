/** @format */

import { Grid } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import { optionsTable } from "../../../config/optionsTable";
import useAudit from "../../../services/protected/hooks/useAudit";
import { columns } from "./helpers/constants";

export function Audit() {
  const { data, page, perPage, setOrder, setPage, setPerPage, total } =
    useAudit();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[{ label: "Inicio", value: "/" }, { label: "Auditória" }]}
      />
      <MUIDataTable
        title={"Auditória"}
        data={data}
        columns={columns}
        options={{
          ...optionsTable,
          searchAlwaysOpen: false,
          search: false,
          download: false,
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
