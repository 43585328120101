/** @format */

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Checkbox, TextField } from "@mui/material";

type AutoCompleteFieldProps = {
  options: { value: string | number; label: string }[];

  label: string;
  id: string;
  onChange?: (
    event: any,
    newValue: { value: string | number; label: string }[] | null
  ) => void;
  labelcode?: boolean;
  formik: any;
  disabled?: boolean;
};

const icon = <CheckBoxOutlineBlank fontSize='small' />;
const checkedIcon = <CheckBox fontSize='small' />;

export function AutoCompleteFieldMultiple(props: AutoCompleteFieldProps) {
  const { options, label, labelcode, formik, id, disabled } = props;

  const optionsToUse = options === undefined || options === null ? [] : options;

  return (
    <Autocomplete
      {...props}
      multiple
      limitTags={2}
      options={optionsToUse}
      value={formik.values[id]}
      disabled={disabled}
      disableCloseOnSelect
      onChange={(event, newValue, reason, details) => {
        const index = formik.values[id].findIndex(
          (v: any) => v.value === details?.option?.value
        );
        if (index !== -1) {
          formik.values[id].splice(index, 1);
          formik.setFieldValue(id, formik.values[id] || []);
          return;
        }
        if (reason === "clear") {
          formik.setFieldValue(id, []);
          return;
        }
        formik.setFieldValue(id, newValue || []);
        if (props.onChange) props.onChange(event, newValue);
      }}
      noOptionsText='Nenhum resultado encontrado'
      filterOptions={(optionsToFiltter, params) => {
        const filtered = optionsToFiltter.filter((option: any) => {
          if (!params.inputValue) {
            return true;
          }
          return (
            option?.label
              ?.toLowerCase()
              ?.includes(params?.inputValue?.toLowerCase()) ||
            option?.value
              ?.toString()
              ?.toLowerCase()
              ?.includes(params?.inputValue?.toLowerCase())
          );
        });

        return filtered;
      }}
      getOptionLabel={(option) => {
        if (!option) return "";
        const selected = optionsToUse.find(
          ({ value }) => value === option.value
        );
        return selected
          ? labelcode
            ? `${selected.value} - ${selected.label}`
            : selected?.label ?? "Não encontrado"
          : "";
      }}
      renderOption={(props, option, opt, { value }) => {
        const { key, ...optionProps } = props;
        const selected = value?.find(({ value }) => value === option.value);
        return (
          <li key={key + option?.value?.toString()} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected !== undefined}
            />
            {labelcode ? `${option.value} - ${option.label}` : option.label}
          </li>
        );
      }}
      renderInput={(renderInputParams) => (
        <TextField
          {...renderInputParams}
          fullWidth
          placeholder='Digite aqui para buscar'
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors[id])}
          helperText={formik.errors[id]}
          label={label}
          variant='outlined'
        />
      )}
    />
  );
}
