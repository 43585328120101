/** @format */

import { AddCircleOutlined, Edit } from "@mui/icons-material";
import { Button, Checkbox, Grid, Tooltip } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";

import { IconButton } from "@radix-ui/themes";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { optionsTable } from "../../../../config/optionsTable";
import useWorkSchedules from "../../../../services/protected/hooks/useWorkSchedule";
import { columns } from "./helpers/constants";

export function WorkSchedule() {
  const router = useNavigate();

  const {
    data,
    page,
    perPage,
    searchCall,
    setOrder,
    setPage,
    setPerPage,
    total,
  } = useWorkSchedules();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Configurações", value: "/settings/workSchedule" },
          { label: "Jornada de trabalho" },
        ]}
      />
      <Button
        variant={"contained"}
        endIcon={<AddCircleOutlined />}
        onClick={() => router("/settings/workSchedule/new")}>
        Nova Jornada de trabalho
      </Button>

      <MUIDataTable
        title={"Cargos"}
        data={data}
        columns={[
          {
            name: "action",
            label: "AÇÕES",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <Grid item gap={2} display={"flex"}>
                    <Tooltip title={"Editar"}>
                      <IconButton
                        variant='ghost'
                        radius='full'
                        onClick={() =>
                          router(
                            `/settings/workSchedule/details/${data[dataIndex].uuid}`
                          )
                        }>
                        <Edit fontSize='small' />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                );
              },
            },
          },
          ...columns.map((col, index) => {
            if (index >= columns.length - 2) {
              return {
                ...col,
                options: {
                  customBodyRenderLite: (dataIndex: number) => {
                    const value = data[dataIndex][col.name];
                    return <Checkbox checked={value === "Não possui" ? false : true} disabled />;
                  },
                },
              };
            }
            return col;
          }),
        ]}
        options={{
          ...optionsTable,
          download: false,
          searchPlaceholder: "Pesquise por descrição ou código",
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
