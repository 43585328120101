/** @format */

import { Save, Undo } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { ConfigSelection } from "../../../../components/configSelection";
import { InfoComponent } from "../../../../components/infoComponent";
import { fields } from "../../../../config/fields";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

export function PermissionsUpdateInformation() {
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);
  const [left, setLeft]: any = useState([]);
  const [right, setRight]: any = useState([]);
  const [leftRH, setLeftRH]: any = useState([]);
  const [rightRH, setRightRH]: any = useState([]);
  const [leftCollaborator, setLeftCollaborator]: any = useState([]);
  const [rightCollaborator, setRightCollaborator]: any = useState([]);
  const [leftManager, setLeftManager]: any = useState([]);
  const [rightManager, setRightManager]: any = useState([]);
  const [options, setOptions]: any = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then((response: any) => {
        const allOpt: any = [];
        fields.map(
          (item: any) => item?.hidden !== true && allOpt.push(item.value)
        );
        const allOptRequired: any = [];
        fields.map(
          (item: any) =>
            item?.hidden !== true &&
            item?.tab !== true &&
            item?.notRequiredCheck !== true &&
            allOptRequired.push(item.value)
        );
        const resultOpt = allOptRequired.filter(
          (item: any) =>
            !response.data.data.record.fields_with_required_documents.includes(
              item
            )
        );
        setRight(response.data.data.record.fields_with_required_documents);
        setLeft(resultOpt);
        // campos ocultos colaborador
        if (response.data.data.record.user_form_hidden_fields.collaborator) {
          const resultOptCollaborator = allOpt.filter(
            (item: any) =>
              !response.data.data.record.user_form_hidden_fields.collaborator.includes(
                item
              )
          );
          setLeftCollaborator(
            response.data.data.record.user_form_hidden_fields.collaborator
          );
          setRightCollaborator(resultOptCollaborator);
        } else {
          setRightCollaborator(allOpt);
        }
        // campos ocultos gestor
        if (response.data.data.record.user_form_hidden_fields.manager) {
          const resultOptManager = allOpt.filter(
            (item: any) =>
              !response.data.data.record.user_form_hidden_fields.manager.includes(
                item
              )
          );
          setLeftManager(
            response.data.data.record.user_form_hidden_fields.manager
          );
          setRightManager(resultOptManager);
        } else {
          setRightManager(allOpt);
        }
        // campos ocultos rh
        if (response.data.data.record.user_form_hidden_fields.rh) {
          const resultOptRH = allOpt.filter(
            (item: any) =>
              !response.data.data.record.user_form_hidden_fields.rh.includes(
                item
              )
          );
          setLeftRH(response.data.data.record.user_form_hidden_fields.rh);
          setRightRH(resultOptRH);
        } else {
          setRightRH(allOpt);
        }
        GetInstance({}, "/users/options?settings=true")
          .then(async (response2: any) => {
            setOptions(response2.data.data);
            const arrayIds = response2.data.data.map((opt: any) => opt.value);
            if (
              Object.keys(response.data.data.record.user_update_request_rules)
                .length > 0
            ) {
              if (
                response.data.data.record.user_update_request_rules.approvers
              ) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.user_update_request_rules.approvers.includes(
                      item
                    )
                );
                setRightApprovers(
                  response.data.data.record.user_update_request_rules.approvers
                );
                setLeftApprovers(resultOpt);
              }
            } else {
              setLeftApprovers(arrayIds);
            }
            dispatch(updateLoading(false));
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit() {
    dispatch(updateLoading(true));
    PutInstante(
      null,
      {
        user_update_request_rules: {
          approvers: rightApprovers,
        },
        fields_with_required_documents: right,
        user_form_hidden_fields: {
          rh: leftRH,
          manager: leftManager,
          collaborator: leftCollaborator,
        },
      },
      "/company_settings"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }
  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/updateInformation" },
            { label: "Alteração cadastral" },
          ]}
        />
        <InfoComponent
          instructionMap={[
            "Abaixo é permitido a configuração de usuários com permissão aos fluxos de alteração cadastral.",
            'Para realizar esse procedimento, é só selecionar os itens que deseja incluir no lado esquerdo, enviar para o lado direito. e para remover é só realizar o processo contrário, após a conclusão da personalização, clique em "SALVAR MODIFICAÇÕES" para concluir.',
            "Esse procedimento afeta as ações: VER DETALHES, APROVAR E REPROVAR.",
          ]}
        />
        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />
        <InfoComponent
          instructionMap={[
            "Abaixo é permitido a configuração de campos obrigatórios para inclusão de comprovantes aos fluxos de alteração cadastral.",
            'Para realizar esse procedimento, é só selecionar os itens que deseja incluir no lado esquerdo, enviar para o lado direito. e para remover é só realizar o processo contrário, após a conclusão da personalização, clique em "SALVAR MODIFICAÇÕES" para concluir.',
          ]}
        />
        <ConfigSelection
          leftLabel='Campos sem comprovação:'
          rightLabel='Campos com comprovante obrigatório:'
          left={left}
          right={right}
          options={fields}
          setLeft={setLeft}
          setRight={setRight}
        />
        <InfoComponent
          instructionMap={[
            "Abaixo é permitido a configuração de campos que não deve ser mostrado para o perfil aos fluxos de alteração cadastral",
            'Para realizar esse procedimento, é só selecionar os itens que deseja incluir no lado esquerdo, enviar para o lado direito. e para remover é só realizar o processo contrário, após a conclusão da personalização, clique em "SALVAR MODIFICAÇÕES" para concluir.',
          ]}
        />
        <ConfigSelection
          leftLabel='Perfil Colaborador - campos ocultos:'
          rightLabel='Perfil Colaborador - campos liberados:'
          left={leftCollaborator}
          right={rightCollaborator}
          options={fields}
          setLeft={setLeftCollaborator}
          setRight={setRightCollaborator}
        />
        <ConfigSelection
          leftLabel='Perfil RH - campos ocultos:'
          rightLabel='Perfil RH - campos liberados:'
          left={leftRH}
          right={rightRH}
          options={fields}
          setLeft={setLeftRH}
          setRight={setRightRH}
        />
        <ConfigSelection
          leftLabel='Perfil Gestor - campos ocultos:'
          rightLabel='Perfil Gestor - campos liberados:'
          left={leftManager}
          right={rightManager}
          options={fields}
          setLeft={setLeftManager}
          setRight={setRightManager}
        />
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={onSubmit}
            endIcon={<Save />}>
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
