/** @format */

import { toast } from "react-toastify";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { PostInstante } from "../../../../../services/api";

export const onSubmit = ({ values, dispatch, router, id }: any) => {
  dispatch(updateLoading(true));
  PostInstante(null, { ...values, collaborator_id: id }, "/dismissal_requests")
    .then((response: any) => {
      dispatch(updateLoading(false));
      toast.success(response?.data?.message);
      router("/request/dismissalRequests");
    })
    .catch((e: any) => {
      GenericError(e, dispatch);
    });
};
