/** @format */

import { Mail, Phone } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Avatar, Card, IconButton, Tooltip } from "@radix-ui/themes";
import { toast } from "react-toastify";
import { ActionsUser } from "../../../../components/actionsUser";

export function CardUser({ navigate, company, user, setChange, change }: any) {
  return (
    <Card
      style={{ display: "inline-block", height: 150, width: 230, margin: 12 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
        }}>
        <div style={{ display: "flex", gap: 8 }}>
          <Grid
            display={"flex"}
            alignItems={"center"}
            gap={1}
            flexDirection={"column"}>
            <Avatar
              size='3'
              src={user?.avatar}
              radius='full'
              fallback={user?.name?.split("")[0] ?? ""}
            />
          </Grid>
          <div>
            <Typography fontSize={12} fontWeight={800}>
              {user?.name?.toUpperCase()}
            </Typography>
            {user?.job_role_name && (
              <Typography fontSize={10}>{user?.job_role_name}</Typography>
            )}
            {user?.email && (
              <Typography fontSize={10}>{user?.email}</Typography>
            )}
            {user?.phone && (
              <Typography fontSize={10}>{user?.phone}</Typography>
            )}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip
            content={
              !user?.phone
                ? "Sem número de telefone"
                : "Copiar número de telefone"
            }>
            <IconButton
              disabled={!user?.phone}
              color={company.primary}
              variant='ghost'
              radius='full'
              style={{ cursor: "pointer", margin: 2 }}
              onClick={() => {
                toast.success("Número de telefone copiado com sucesso.");
                navigator.clipboard.writeText(user.phone);
              }}>
              <Phone fontSize={"small"} />
            </IconButton>
          </Tooltip>
          <Tooltip
            content={
              !user?.email
                ? "Sem endereço de e-mail"
                : "Copiar endereço de e-mail"
            }>
            <IconButton
              disabled={!user?.email}
              color={company.primary}
              variant='ghost'
              radius='full'
              style={{ cursor: "pointer", margin: 2 }}
              onClick={() => {
                toast.success("Endereço de e-mail copiado com sucesso.");
                navigator.clipboard.writeText(user.email);
              }}>
              <Mail fontSize={"small"} />
            </IconButton>
          </Tooltip>
          {(user.userActive || user.userManager || user.userOptions) && (
            <ActionsUser data={[user]} dataIndex={0} />
          )}
        </div>
      </div>
    </Card>
  );
}
