/** @format */

import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      hidden={value !== index}
      id={`tab-app-${index}`}
      aria-labelledby={`app-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-app-${index}`,
    "aria-controls": `app-tab-${index}`,
  };
}

interface TabsComponentProps {
  label: string;
  component: React.ReactNode;
}

interface Props {
  tabs: TabsComponentProps[];
  index?: number;
}

export function TabsComponent({ tabs, index = 0 }: Props) {
  const [value, setValue] = useState(index);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map((tab, index) => (
            <Tab
              key={tab.label + index + "tab"}
              label={tab.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <CustomTabPanel
          key={tab.label + index + "children"}
          value={value}
          index={index}>
          {tab.component}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
