/** @format */

import { Save, Undo } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { ConfigSelection } from "../../../../components/configSelection";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

export function PermissionsLeave() {
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);
  const [options, setOptions]: any = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        GetInstance({}, "/users/options?settings=true")
          .then(async (response2: any) => {
            setOptions(response2.data.data);
            const arrayIds = response2.data.data.map((opt: any) => opt.value);
            if (
              Object.keys(response.data.data.record.user_update_request_rules)
                .length > 0
            ) {
              if (
                response.data.data.record.user_update_request_rules.approvers
              ) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.user_update_request_rules.approvers.includes(
                      item
                    )
                );
                setRightApprovers(
                  response.data.data.record.user_update_request_rules.approvers
                );
                setLeftApprovers(resultOpt);
              }
            } else {
              setLeftApprovers(arrayIds);
            }
            dispatch(updateLoading(false));
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onSubmit() {
    dispatch(updateLoading(true));
    PutInstante(
      null,
      {
        user_update_request_rules: {
          approvers: rightApprovers,
        },
      },
      "/company_settings"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/leave" },
            { label: "Afastamento" },
          ]}
        />
        <InfoComponent
          instructionMap={[
            "Nesta tela é permitido a configuração de usuários com permissão aos fluxos de afastamento.",
            'Para realizar esse procedimento, é só selecionar os itens que deseja incluir no lado esquerdo, enviar para o lado direito. e para remover é só realizar o processo contrário, após a conclusão da personalização, clique em "SALVAR MODIFICAÇÕES" para concluir.',
            "Esse procedimento afeta as ações: VER DETALHES, APROVAR E REPROVAR.",
          ]}
        />
        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={onSubmit}
            endIcon={<Save />}>
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
