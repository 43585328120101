/** @format */
import { MarkChatUnread, OpenInNew } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import Chip from "../../../components/header/chip";
import { optionsTable } from "../../../config/optionsTable";
import { RedirectSolicitation } from "../../../helpers/redirectSolicitation";
import useNotification from "../../../services/protected/hooks/useNotification";
import { chipSuccessSX, chipWarningSX, columns } from "./helpers/constants";
import { readNotification } from "./helpers/readNotification";

export function Notifications() {
  const user = useSelector((state: any) => state.user.data);
  const notification = useSelector(
    (state: any) => state.common.totalNotifications
  );
  const { data, page, perPage, setOrder, setPage, setPerPage, total } =
    useNotification();
  const dispatch = useDispatch();
  const theme = useTheme();
  const router = useNavigate();

  return (
    <Grid container>
      <Grid container>
        <BreadcrumbsComponent
          links={[{ label: "Inicio" }, { label: "Notificações" }]}
        />
      </Grid>
      <Grid container mt={2} justifyContent={"space-between"}>
        <Grid item>
          <Grid container alignItems='center' gap={4}>
            <Grid item>
              <Grid container flexDirection={"column"}>
                <Typography component={"span"} fontSize={22}>
                  Olá,
                  <strong> {user?.social_name ?? user?.name}</strong>
                </Typography>
                <Typography component={"span"} fontSize={16}>
                  Você possui <strong>{notification}</strong> mensagen(s) não
                  lida(s).
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <MUIDataTable
          title={"Todas as notificações"}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações / Status",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid container>
                      {data[dataIndex].read_at === "Não possui" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: isMobile ? "column" : "row",
                            alignItems: "center",
                            gap: 12,
                          }}>
                          {data[dataIndex].resource_uuid !== "Não possui" && (
                            <Tooltip title={"Ir para a solicitação"}>
                              <IconButton
                                onClick={() => {
                                  readNotification(
                                    {
                                      id: data[dataIndex].uuid,
                                    },
                                    false,
                                    dispatch
                                  );
                                  RedirectSolicitation(
                                    data[dataIndex].resource_type,
                                    data[dataIndex].resource_uuid,
                                    router
                                  );
                                }}>
                                <OpenInNew color='primary' />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title={"Marcar como lida"}>
                            <IconButton
                              onClick={() => {
                                readNotification(
                                  {
                                    id: data[dataIndex].uuid,
                                  },
                                  true,
                                  dispatch
                                );
                              }}>
                              <MarkChatUnread color='primary' />
                            </IconButton>
                          </Tooltip>
                          <Chip label='Não lida' sx={chipWarningSX(theme)} />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 12,
                          }}>
                          {data[dataIndex].resource_uuid !== "Não possui" && (
                            <Tooltip title={"Ir para a solicitação"}>
                              <IconButton
                                onClick={() => {
                                  readNotification(
                                    {
                                      id: data[dataIndex].uuid,
                                    },
                                    false,
                                    dispatch
                                  );
                                  RedirectSolicitation(
                                    data[dataIndex].resource_type,
                                    data[dataIndex].resource_uuid,
                                    router
                                  );
                                }}>
                                <OpenInNew color='primary' />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Chip
                            label={`Lida em ${data[dataIndex].updated_at}`}
                            sx={chipSuccessSX(theme)}
                          />
                        </div>
                      )}
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            download: false,
            searchAlwaysOpen: false,
            search: false,
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
