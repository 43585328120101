/** @format */

import * as yup from "yup";
export const validationSchema = yup.object().shape({
  salary_review_min_percent: yup.number().required("Campo obrigatório"),
  salary_review_max_percent: yup
    .number()
    .required("Campo obrigatório")
    .when("salary_review_min_percent", ([salary_review_min_percent], schema) =>
      schema.min(
        salary_review_min_percent,
        "O percentual máximo deve ser maior ou igual ao percentual mínimo"
      )
    ),
});
