/** @format */

import { AddCircleOutline, GroupAdd } from "@mui/icons-material";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { ActionRemove } from "../../../../components/actionRemove";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { optionsTable } from "../../../../config/optionsTable";
import { IsPermissions } from "../../../../helpers/isPermission";
import useUserPosition from "../../../../services/protected/hooks/useUserPosition";
import { columns } from "./helpers/constants";

export function Position() {
  const router = useNavigate();
  const {
    data,
    total,
    perPage,
    page,
    setPage,
    setPerPage,
    searchCall,
    setOrder,
  } = useUserPosition();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Início" },
          { label: "Colaboradores", value: "/employee/active" },
          { label: "Posições" },
        ]}
        buttons={
          <ButtonComponent
            variant={"contained"}
            onClick={() => {
              router("/request/position/new");
            }}
            endIcon={<GroupAdd />}>
            Nova posição
          </ButtonComponent>
        }
      />
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return data[dataIndex].status === "Aberta" ? (
                    <Grid
                      container
                      display={"flex"}
                      flexDirection={"row"}
                      width={80}>
                      <IsPermissions keyPrivate={"requestPersonalNew"}>
                        <Tooltip title={"Nova requisição de pessoal"}>
                          <IconButton
                            onClick={() => {
                              router(
                                `/request/personal/new?position=${data[dataIndex].uuid}`
                              );
                            }}>
                            <AddCircleOutline />
                          </IconButton>
                        </Tooltip>
                      </IsPermissions>
                      <IsPermissions keyPrivate='requestPositionDetailsRemove'>
                        <ActionRemove
                          statusList={data[dataIndex].status}
                          url='/positions'
                          id={data[dataIndex].uuid}
                        />
                      </IsPermissions>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      display={"flex"}
                      flexDirection={"row"}
                      width={80}
                      justifyContent={"center"}>
                      <Typography>-</Typography>
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            onSearchChange: (value: string) => {
              searchCall(value);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPage(0);
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
