/** @format */

import { InfoOutlined } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Callout } from "@radix-ui/themes";
import { requestInfo, RequestProps } from "../config/requestInfo";
import { userInfo, UserProps } from "../config/userInfo";
import { vacationInfo, VacationProps } from "../config/vacationInfo";
import { VacationOthersProps } from "../config/vacationInfoOthers";

interface Props {
  user?: UserProps;
  solicitation?: RequestProps;
  vacation?: VacationProps;
  otherVacations?: VacationOthersProps[];
  instruction?: string;
  instructionMap?: string[];
  instructionChildren?: any;
}

export const InfoComponent = ({
  user,
  instruction,
  solicitation,
  vacation,
  otherVacations,
  instructionMap,
  instructionChildren,
}: Props) => {
  return (
    <Grid container>
      <Callout.Root size='2' style={{ alignItems: "center", width: "100%" }}>
        <Callout.Icon>
          <InfoOutlined />
        </Callout.Icon>
        {user && (
          <Grid container flexDirection={"column"}>
            <Typography fontSize={16} fontWeight={800}>
              Informações do colaborador
            </Typography>
            <Grid container gap={"12px 96px"} mt={1}>
              {userInfo(user).map((item: any) => (
                <Grid key={item.title}>
                  <Typography fontSize={12} fontWeight={600}>
                    {item.title}
                  </Typography>
                  <Typography fontSize={10} fontWeight={300}>
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {solicitation && (
          <Grid container flexDirection={"column"}>
            <Typography fontSize={16} fontWeight={800}>
              Informações da solicitação
            </Typography>
            <Grid container gap={"12px 96px"} mt={1}>
              {requestInfo(solicitation).map((item: any) => (
                <Grid key={item.title}>
                  <Typography fontSize={12} fontWeight={600}>
                    {item.title}
                  </Typography>
                  <Typography fontSize={10} fontWeight={300}>
                    {item.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {vacation && (
          <Grid container flexDirection={"column"}>
            <Typography fontSize={16} fontWeight={800}>
              Período de férias da solicitação
            </Typography>
            <Grid container gap={"12px 96px"} mt={1}>
              {vacation.opt.map((opt: any) =>
                vacationInfo({ ...opt, formik: vacation.formik }).map(
                  (item: any) => (
                    <Grid key={item.title}>
                      <Typography fontSize={12} fontWeight={600}>
                        {item.title}
                      </Typography>
                      <Typography fontSize={10} fontWeight={300}>
                        {item.value}
                      </Typography>
                    </Grid>
                  )
                )
              )}
            </Grid>
          </Grid>
        )}
        {otherVacations && otherVacations?.length > 0 && (
          <Grid container flexDirection={"column"}>
            <Typography fontSize={16} fontWeight={800}>
              Outros período(s) de férias em aberto
            </Typography>
            <Grid container gap={"12px 96px"} mt={1}>
              {otherVacations.map((opt: any) =>
                vacationInfo(opt).map((item: any) => (
                  <Grid key={item.title}>
                    <Typography fontSize={12} fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Typography fontSize={10} fontWeight={300}>
                      {item.value}
                    </Typography>
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        )}
        {(instruction || instructionMap || instructionChildren) && (
          <Grid container flexDirection={"column"}>
            <Typography fontSize={16} fontWeight={800}>
              Instruções
            </Typography>
            {instruction && (
              <Typography fontSize={12} fontWeight={400}>
                {instruction}
              </Typography>
            )}
            {instructionMap &&
              instructionMap.map((item: any) => (
                <Typography key={item} fontSize={12} fontWeight={400}>
                  - {item}
                </Typography>
              ))}
            {instructionChildren}
          </Grid>
        )}
      </Callout.Root>
    </Grid>
  );
};
