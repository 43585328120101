/** @format */

import { TextField, TextFieldProps } from "@mui/material";
import { handleCellPhoneChange } from "../../helpers/handleCellPhoneChange";

interface FieldProps extends Omit<TextFieldProps, "ref"> {
  formik: any;
  id: string;
  label: string;
  disabled?: boolean;
}

export function InputPhoneField(props: FieldProps) {
  const { formik, id, label, disabled } = props;
  return (
    <TextField
      {...props}
      fullWidth
      id={id}
      placeholder={"(00) 00000-0000"}
      inputProps={{
        maxLength: 15,
      }}
      disabled={disabled}
      name={id}
      label={label}
      value={formik.values[id]}
      onChange={(event: any) => handleCellPhoneChange(event, formik, id)}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors[id])}
      helperText={formik?.errors[id]}
    />
  );
}
