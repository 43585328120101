/** @format */

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FilterEmpty } from "../../../helpers/emptyData";
import { GenericError } from "../../../helpers/genericError";
import { rhProcessOptions as processOptions } from "../../../pages/protected/processes/helpers/constants";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../api";

let timerSearch: any;

export default function useProcess(values?: any) {
  const [data, setData]: any = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);
  const dispatch = useDispatch();
  const [reset, setReset] = useState(true);

  const getCall = useCallback(async () => {
    try {
      setReset(false);
      dispatch(updateLoading(true));
      const response = await GetInstance(
        {
          page: page + 1,
          per_page: perPage,
          search,
          order_by: order,
          status: values.status,
          solicitation_types: values.solicitation_types,
          from: new Date(values.from),
          to: new Date(values.to),
        },
        "/processes"
      );
      const data = response.data.data.data.map((item: any) => ({
        ...item,
        solicitation_type_route: item.solicitation_type,
        solicitation_type_label: processOptions.find(
          (process: any) => process?.value === item?.solicitation_type
        )?.label,
      }));
      setData(FilterEmpty(data));
      setTotal(response.data.data.total_count);
      dispatch(updateLoading(false));
    } catch (e: any) {
      GenericError(e, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, page, search, perPage, order, reset]);

  //para atualizar a chamada
  useEffect(() => {
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, perPage, order, reset]);

  const searchCall = (value: string) => {
    clearTimeout(timerSearch);
    timerSearch = setTimeout(() => {
      setPage(0);
      setSearch(value);
    }, 500);
  };

  return {
    data,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrder,
    order,
    searchCall,
    search,
    setReset,
  };
}
