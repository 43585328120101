import { FormHelperText } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { getIn } from 'formik';

type Props = {
  options: { value: string | number; label: string }[];
  label: string;
  id: string;
  onChange?: (
    event: any
  ) => void;
  labelcode?: boolean;
  formik: any;
  disabled?: boolean;
};

export default function RadioGroupComponent(props: Props) {
  const { options, label, labelcode, formik, id, disabled, onChange } = props;
  const optionsToUse = options === undefined || options === null ? [] : options;
  const value = getIn(formik.values, id) ?? "";
  const error = getIn(formik.errors, id);

  return (
    <FormControl disabled={disabled}>
      <FormLabel >{label}</FormLabel>
      <RadioGroup
        id={id}
        name={id}
        row
        value={value}
        onChange={(event) => {
          formik.setFieldValue(id, event.target.value)
          onChange && onChange(event);
        }}
      >
        {optionsToUse.map((option) => <FormControlLabel key={option.value + option.label} value={option.value} control={<Radio />}
          label={labelcode ? `${option.value} - ${option.label}` : option.label} />)}
      </RadioGroup>
      {Boolean(error) && <FormHelperText error >{error}</FormHelperText>}
    </FormControl>
  );
}