/** @format */

import { Save, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { ConfigSelection } from "../../../../components/configSelection";
import { InfoComponent } from "../../../../components/infoComponent";
import { InputTextField } from "../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../components/inputs/selectField";
import { abono_settings, aviso_e_social } from "../../../../config/constants";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";
import { validationSchema } from "./helpers/constants";

export function PermissionsVacation() {
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);

  const [leftApproversMaster, setLeftApproversMaster]: any = useState([]);
  const [rightApproversMaster, setRightApproversMaster]: any = useState([]);

  const [options, setOptions]: any = useState([]);
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      vacations_request_minimum_days: "",
      custom_abono: false,
      vacations_custom_days_abono: 0,
      use_assiduity_allowance: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          vacations_request_minimum_days: values.vacations_request_minimum_days,
          vacations_rules: {
            approvers: rightApprovers,
            master_approvers: rightApproversMaster,
            use_assiduity_allowance: values.use_assiduity_allowance,
            vacations_custom_days_abono: values.custom_abono
              ? Number(values.vacations_custom_days_abono)
              : null,
          },
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        GetInstance({}, "/users/options?settings=true")
          .then(async (response2: any) => {
            setOptions(response2.data.data);
            formik.setValues({
              use_assiduity_allowance:
                response.data.data.record.vacations_rules
                  .use_assiduity_allowance,
              vacations_request_minimum_days:
                response.data.data.record.vacations_request_minimum_days,
              custom_abono:
                response.data.data.record.vacations_rules
                  .vacations_custom_days_abono !== null &&
                response.data.data.record.vacations_rules
                  .vacations_custom_days_abono !== undefined,
              vacations_custom_days_abono:
                response.data.data.record.vacations_rules
                  .vacations_custom_days_abono,
            });
            const arrayIds = response2.data.data.map((opt: any) => opt.value);
            if (
              Object.keys(response.data.data.record.vacations_rules).length > 0
            ) {
              if (response.data.data.record.vacations_rules?.approvers) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.vacations_rules.approvers.includes(
                      item
                    )
                );
                setRightApprovers(
                  response.data.data.record.vacations_rules.approvers
                );
                setLeftApprovers(resultOpt);
              } else {
                setLeftApprovers(arrayIds);
              }
              if (response.data.data.record.vacations_rules?.master_approvers) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.vacations_rules.master_approvers.includes(
                      item
                    )
                );
                setRightApproversMaster(
                  response.data.data.record.vacations_rules.master_approvers
                );
                setLeftApproversMaster(resultOpt);
              }
            } else {
              setLeftApproversMaster(arrayIds);
            }
            dispatch(updateLoading(false));
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/vacation" },
            { label: "Férias" },
          ]}
        />
        <InfoComponent
          instructionMap={[
            "Nesta tela é permitido a configuração de usuários com permissão aos fluxos de férias e suas configurações da empresa.",
            'Para realizar esse procedimento, é só selecionar os itens que deseja incluir no lado esquerdo, enviar para o lado direito. e para remover é só realizar o processo contrário, após a conclusão da personalização, clique em "SALVAR MODIFICAÇÕES" para concluir.',
          ]}
        />
        <Paper sx={{ width: "100%", height: "auto" }}>
          <Grid gridTemplateColumns={"1fr"} display={"grid"} m={2} gap={2}>
            <Typography fontSize={14} fontWeight={500}>
              Parametrizações de férias
            </Typography>
          </Grid>
          <Grid
            gridTemplateColumns={"1fr 1fr 1fr 1fr"}
            display={"grid"}
            m={2}
            gap={2}>
            <InputTextField
              formik={formik}
              id='vacations_request_minimum_days'
              label='Quantidade mínima de dias para solicitar férias'
              type={"number"}
            />
            <SelectField
              formik={formik}
              id={"use_assiduity_allowance"}
              label='Usa abono de assiduidade?'
              options={aviso_e_social}
            />
            <SelectField
              formik={formik}
              id={"custom_abono"}
              label='Dias de abono'
              options={abono_settings}
            />
            {formik.values.custom_abono && (
              <InputTextField
                formik={formik}
                id='vacations_custom_days_abono'
                label='Quantidade de dias personalizado para o abono'
                type={"number"}
              />
            )}
          </Grid>
        </Paper>
        <InfoComponent
          instructionMap={[
            "Usuários com permissão, porém com restrição do GESTOR para as ações:",
            "Esse procedimento afeta as ações: VER DETALHES, APROVAR E REPROVAR.",
          ]}
        />
        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />
        <InfoComponent
          instructionMap={[
            "Usuários com permissão sem restrição para as ações:",
            "Esse procedimento afeta as ações: VER DETALHES, APROVAR E REPROVAR.",
          ]}
        />
        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApproversMaster}
          right={rightApproversMaster}
          options={options}
          setLeft={setLeftApproversMaster}
          setRight={setRightApproversMaster}
        />
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Save />}>
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
