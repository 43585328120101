/** @format */

import { AddCircle, Cancel } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";
import { onSubmit } from "../helpers/onSubmit";

export function WorkflowNew() {
  const router = useNavigate();
  const { id } = useParams();
  const [valide, setValide] = useState(false);
  const [options, setOptions] = useState({
    positions: [],
  });
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      solicitation_type: "",
      workflow_steps: [{ position_id: '', name: 'Nivel 1', step_type: null, order: 1 }]
    },
    validationSchema: validationSchema,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values) => {
      onSubmit({ values, dispatch, router, id });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/workflow_steps_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions(response.data.data.options)
      })
      .catch((e: any) => {
        GenericError(e, dispatch)
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Workflow", value: "/workflow" },
            { label: "Novo workflow" },
          ]}
        />
        <InfoComponent
          instruction={
            "Nessa tela você pode criar um novo workflow da sua empresa."
          }
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar novo workflow
              </Typography>
            </Grid>
            <Form formik={formik} options={options} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                color={"error"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Cancel />}>
                Cancelar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                type='submit'
                onClick={() => {
                  setValide(true);
                }}
                endIcon={<AddCircle />}>
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
