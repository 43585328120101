/** @format */

import { AddCircle, Clear, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { config, validationSchema, variables } from "../helpers/constants";
import { Form } from "../helpers/form";

export function LetterNew() {
  const router = useNavigate();
  const dispatch = useDispatch();
  const editor: any = useRef(null);
  const [valide, setValide] = useState(false);
  const [options, setOptions] = useState({
    dismissal_types: [],
    dismissal_reasons: [],
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/letters_form")
      .then((response: any) => {
        setOptions({
          dismissal_reasons:
            response.data.data.options.dismissal_reasons.group_0.filter(
              (d: any) => d.associated
            ),
          dismissal_types:
            response.data.data.options.dismissal_types.group_0.filter(
              (d: any) => d.associated
            ),
        });
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    initialValues: {
      solicitation_type: "",
      dismissal_types: [],
      salary_review_types: [],
      name: "",
      content: "",
      active: true,
    },
    validationSchema: validationSchema,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      const sendValues: any = {
        ...values,
        dismissal_types: values.dismissal_types.map((item: any) => item.value),
        salary_review_types: values.salary_review_types.map((item: any) => item.value),
      };
      if (sendValues.solicitation_type !== "dismissal") {
        delete sendValues.dismissal_types;
      }
      if (sendValues.solicitation_type !== "salary_review") {
        delete sendValues.salary_review_types;
      }
      PostInstante(null, sendValues, "/letters")
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/settings/letter");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Configurações", value: "/settings/letter" },
            { label: "Carta", value: "/settings/letter" },
            { label: "Nova carta" },
          ]}
        />
        <InfoComponent
          instructionMap={[
            "Selecione o tipo de carta, adicione um nome e os outros dados necessário, Selecione 'Ativar exibição da carta' caso deseje ativar e crie o documento personalizado que deseja acrescentar no sistema. Depois é só clicar em cadastrar.",
            "Para adicionar uma variavel de ambiente, por exemplo que seja colocado o nome do colaborador personalizado, adicione o texto como no exemplo do arquivo.",
            "Variáveis disponíveis:",
          ]}
          instructionChildren={variables.map((item: any) => (
            <Typography fontSize={12} ml={2} fontWeight={400}>
              - {item.label} <strong>{item.value}</strong>
            </Typography>
          ))}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar novo modelo de carta
              </Typography>
            </Grid>
            <Form formik={formik} options={options} />
            <Grid pt={2}>
              <JoditEditor
                ref={editor}
                value={formik.values.content}
                config={config(editor)}
                onBlur={(newContent) =>
                  formik.setFieldValue("content", newContent)
                }
              />
              {formik.errors?.content && (
                <Typography
                  fontSize={"0.75rem"}
                  style={{ marginTop: 4, marginLeft: 14 }}
                  color={"error"}>
                  {formik.errors?.content}
                </Typography>
              )}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  formik.setFieldValue("content", "");
                }}
                endIcon={<Clear />}>
                Limpar tudo
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                onClick={() => {
                  setValide(true);
                  formik.handleSubmit();
                }}
                endIcon={<AddCircle />}>
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
