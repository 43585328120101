/** @format */

import { Save, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { ConfigSelection } from "../../../../components/configSelection";
import { InfoComponent } from "../../../../components/infoComponent";
import { InputTextField } from "../../../../components/inputs/inputTextField";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

const validationSchema = yup.object().shape({
  transfer_with_promotion_min_percent: yup
    .number()
    .required("Campo obrigatório"),
  transfer_with_promotion_max_percent: yup
    .number()
    .required("Campo obrigatório")
    .when(
      "transfer_with_promotion_min_percent",
      ([transfer_with_promotion_min_percent], schema) =>
        schema.min(
          transfer_with_promotion_min_percent,
          "O percentual máximo deve ser maior ou igual ao percentual mínimo"
        )
    ),
  transfer_without_promotion_min_percent: yup
    .number()
    .required("Campo obrigatório"),
  transfer_without_promotion_max_percent: yup
    .number()
    .required("Campo obrigatório")
    .when(
      "transfer_without_promotion_min_percent",
      ([transfer_without_promotion_min_percent], schema) =>
        schema.min(
          transfer_without_promotion_min_percent,
          "O percentual máximo deve ser maior ou igual ao percentual mínimo"
        )
    ),
});

export function PermissionsTransfer() {
  const [leftApprovers, setLeftApprovers]: any = useState([]);
  const [rightApprovers, setRightApprovers]: any = useState([]);
  const [options, setOptions]: any = useState([]);
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      transfer_with_promotion_min_percent: 0,
      transfer_with_promotion_max_percent: 0,
      transfer_without_promotion_min_percent: 0,
      transfer_without_promotion_max_percent: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          transfer_requisitions_rules: {
            approvers: rightApprovers,
          },
          transfer_with_promotion_min_percent:
            values.transfer_with_promotion_min_percent,
          transfer_with_promotion_max_percent:
            values.transfer_with_promotion_max_percent,
          transfer_without_promotion_min_percent:
            values.transfer_without_promotion_min_percent,
          transfer_without_promotion_max_percent:
            values.transfer_without_promotion_max_percent,
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        formik.setValues({
          transfer_with_promotion_min_percent:
            response.data.data.record.transfer_with_promotion_min_percent,
          transfer_with_promotion_max_percent:
            response.data.data.record.transfer_with_promotion_max_percent,
          transfer_without_promotion_min_percent:
            response.data.data.record.transfer_without_promotion_min_percent,
          transfer_without_promotion_max_percent:
            response.data.data.record.transfer_without_promotion_max_percent,
        });
        GetInstance({}, "/users/options?settings=true")
          .then(async (response2: any) => {
            setOptions(response2.data.data);
            const arrayIds = response2.data.data.map((opt: any) => opt.value);
            if (
              Object.keys(response.data.data.record.transfer_requisitions_rules)
                .length > 0
            ) {
              if (
                response.data.data.record.transfer_requisitions_rules.approvers
              ) {
                const resultOpt = arrayIds.filter(
                  (item: any) =>
                    !response.data.data.record.transfer_requisitions_rules.approvers.includes(
                      item
                    )
                );
                setRightApprovers(
                  response.data.data.record.transfer_requisitions_rules
                    .approvers
                );
                setLeftApprovers(resultOpt);
              }
            } else {
              setLeftApprovers(arrayIds);
            }
            dispatch(updateLoading(false));
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/transfer" },
            { label: "Transferências" },
          ]}
        />
        <InfoComponent
          instructionMap={[
            "Nesta tela é permitido a configuração de usuários com permissão fluxos de transferências e suas configurações da empresa",
            'Para realizar esse procedimento, é só selecionar os itens que deseja incluir no lado esquerdo, enviar para o lado direito. e para remover é só realizar o processo contrário, após a conclusão da personalização, clique em "SALVAR MODIFICAÇÕES" para concluir.',
            "Esse procedimento afeta as ações: VER DETALHES, APROVAR E REPROVAR.",
          ]}
        />

        <Paper sx={{ width: "100%", height: "auto" }}>
          <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} m={2} gap={2}>
            <Grid>
              <Grid gridTemplateColumns={"1fr"} display={"grid"} m={2} gap={2}>
                <Typography fontSize={14} fontWeight={500}>
                  Parametrizações de transferência com promoção
                </Typography>
              </Grid>
              <Grid
                gridTemplateColumns={"1fr 1fr"}
                display={"grid"}
                m={2}
                gap={2}>
                <InputTextField
                  label='Porcentagem mínima de salário'
                  id={`transfer_with_promotion_min_percent`}
                  type='number'
                  formik={formik}
                />
                <InputTextField
                  label='Porcentagem máxima de salário'
                  id={`transfer_with_promotion_max_percent`}
                  type='number'
                  formik={formik}
                />
              </Grid>
            </Grid>
            <Grid>
              <Grid gridTemplateColumns={"1fr"} display={"grid"} m={2} gap={2}>
                <Typography fontSize={14} fontWeight={500}>
                  Parametrização de transferência sem promoção
                </Typography>
              </Grid>
              <Grid
                gridTemplateColumns={"1fr 1fr "}
                display={"grid"}
                m={2}
                gap={2}>
                <InputTextField
                  label='Porcentagem mínima de salário'
                  id={`transfer_without_promotion_min_percent`}
                  type='number'
                  formik={formik}
                />
                <InputTextField
                  label='Porcentagem máxima de salário'
                  id={`transfer_without_promotion_max_percent`}
                  type='number'
                  formik={formik}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <ConfigSelection
          leftLabel='Usuários sem permissão:'
          rightLabel='Usuários com permissão:'
          left={leftApprovers}
          right={rightApprovers}
          options={options}
          setLeft={setLeftApprovers}
          setRight={setRightApprovers}
        />
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Save />}>
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
