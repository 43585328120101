/** @format */

import { Grid } from "@mui/material";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import {
  motive_e_social,
  motive_e_social_manager,
} from "../../../../../config/constants";
import {
  ClearCurrency,
  FormatCurrency,
  MaskCurrency,
} from "../../../../../helpers/formatCurrency";
import { ReadjustSalary } from "../../../../../helpers/readjustSalary";

export function Form({ formik, userData, edit }: any) {
  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        <AutoCompleteField
          formik={formik}
          disabled={edit}
          id='e_social_motive'
          label='Motivo da revisão'
          options={
            userData.role === "rh"
              ? motive_e_social.map((item: any) => ({
                  value: item,
                  label: item,
                }))
              : motive_e_social_manager.map((item: any) => ({
                  value: item,
                  label: item,
                }))
          }
        />
        <InputDateField
          formik={formik}
          id='move_date'
          label='Data da movimentação'
          disabled={edit}
        />
        <InputTextField
          formik={formik}
          id='percent'
          type={"number"}
          disabled={edit}
          label='Porcentagem (%)'
          onChange={(event: any) => {
            formik.setFieldValue(
              "future_salary",
              MaskCurrency(
                ((parseFloat(event.target.value) + 100) *
                  parseFloat(formik.values.actual_salary)) /
                  100
              )
            );
          }}
        />
        <InputCurrencyField
          formik={formik}
          id='future_salary'
          label='Salário futuro'
          disabled={edit}
          onChange={(event: any) => {
            formik.setFieldValue(
              "percent",
              ReadjustSalary(
                Number(formik.values.actual_salary),
                ClearCurrency(FormatCurrency(event))
              )
            );
          }}
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <InputTextField
          formik={formik}
          disabled={edit}
          multiline={true}
          rows={4}
          id='observations'
          label='Comentários'
        />
      </Grid>
    </>
  );
}
