/** @format */

import { useSelector } from "react-redux";
import { Filter } from "../../../../components/filter";
import { AutoCompleteField } from "../../../../components/inputs/autoCompleteField";
import { InputDateField } from "../../../../components/inputs/inputDateField";
import {
  accountantProcessOptions,
  rhProcessOptions,
  accountantStatusOptions,
  rhStatusOptions,
} from "./constants";

export function Filters({ formik }: any) {
  const user = useSelector((state: any) => state.user.data);
  const statusOptions =
    user.role === "rh"
      ? rhStatusOptions
      : user.role === "accountant"
      ? accountantStatusOptions
      : [];

  const processOptions = 
    user.role === "rh"
      ? rhProcessOptions
      : user.role === "accountant"
      ? accountantProcessOptions
      : [];

  return (
    <Filter
      formik={formik}
      id={"processes"}
      keys={{
        from: { label: "Data inicio" },
        to: { label: "Data final" },
        solicitation_types: { label: "Processo", options: processOptions },
        status: { label: "Status", options: statusOptions },
      }}>
      <InputDateField formik={formik} id='from' label='Data Inícial' />
      <InputDateField formik={formik} id='to' label='Data Final' />
      <AutoCompleteField
        formik={formik}
        id='solicitation_types'
        label='Processo'
        options={processOptions}
      />
      <AutoCompleteField
        formik={formik}
        id='status'
        label='Status'
        options={statusOptions}
      />
    </Filter>
  );
}
