/** @format */

import { AddCircleOutlined, Visibility } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { ActionRemove } from "../../../components/actionRemove";
import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../components/buttonComponent";
import { optionsTable } from "../../../config/optionsTable";
import { IsPermissions } from "../../../helpers/isPermission";
import useWorkflow from "../../../services/protected/hooks/useWorkflow";
import { columns } from "./helpers/constants";

export function WorkflowDashboardPage() {
  const router = useNavigate();
  const {
    data,
    searchCall,
    setOrder,
    page,
    setPage,
    setPerPage,
    perPage,
    total,
  } = useWorkflow();
  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[{ label: "Início" }, { label: "Workflow", value: "/workflow" }]}
        buttons={
          <ButtonComponent
            endIcon={<AddCircleOutlined />}
            variant='contained'
            label='Nova solicitação'
            onClick={() => router(`/workflow/new`)}>
            Novo workflow
          </ButtonComponent>
        }
      />
      <MUIDataTable
        title={""}
        data={data}
        columns={[
          {
            name: "action",
            label: "Ações",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <Grid container>
                    <IsPermissions keyPrivate={"workflowDetails"}>
                      <Tooltip title={"Ver solicitação"}>
                        <IconButton
                          onClick={() =>
                            router(`/workflow/details/${data[dataIndex].uuid}`)
                          }>
                          <Visibility color='primary' />
                        </IconButton>
                      </Tooltip>
                    </IsPermissions>
                    <ActionRemove
                      statusList={data[dataIndex].solicitation_status}
                      url='/workflow'
                      id={data[dataIndex].uuid}
                    />
                  </Grid>
                );
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por nome",
          download: false,
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPage(0);
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
