/** @format */

import {
  AddCircleOutlined,
  CheckCircle,
  Edit,
  HighlightOff,
} from "@mui/icons-material";
import { Box, Grid, Tooltip } from "@mui/material";
import { IconButton } from "@radix-ui/themes";
import MUIDataTable from "mui-datatables";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { optionsTable } from "../../../../config/optionsTable";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { PostInstante } from "../../../../services/api";
import useJobRoles from "../../../../services/protected/hooks/useJobRole";
import { columns } from "./helpers/constants";
import { ButtonComponent } from "../../../../components/buttonComponent";

export function JobRoles() {
  const dispatch = useDispatch();
  const router = useNavigate();

  const {
    data,
    setData,
    page,
    perPage,
    searchCall,
    setOrder,
    setPage,
    setPerPage,
    total,
  } = useJobRoles();

  const handleToogleStatus = (uuid: string) => {
    dispatch(updateLoading(true));
    PostInstante(null, null, `/job_roles/${uuid}/change_status`)
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setData((prev: any) =>
          prev.map((item: any) => {
            if (item.uuid === uuid) {
              return {
                ...item,
                status: item.status === "Ativo" ? "Inativo" : "Ativo",
              };
            }
            return item;
          })
        );
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  return (
    <Box>
      <Grid
        container
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Configurações" },
            { label: "Cargos" },
          ]}
          buttons={<ButtonComponent
            variant={"contained"}
            endIcon={<AddCircleOutlined />}
            onClick={() => router("/settings/jobRoles/new")}>
            Novo Cargo
          </ButtonComponent>}
        />
        
        <MUIDataTable
          title={"Cargos"}
          data={data}
          columns={[
            {
              name: "action",
              label: "AÇÕES",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid item gap={2} display={"flex"}>
                      <Tooltip title={"Editar"}>
                        <IconButton
                          variant='ghost'
                          radius='full'
                          onClick={() =>
                            router(
                              `/settings/jobRoles/details/${data[dataIndex].uuid}`
                            )
                          }>
                          <Edit fontSize='small' />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title={
                          data[dataIndex].status === "active"
                            ? "Desativar"
                            : "Ativar"
                        }>
                        <IconButton
                          variant='ghost'
                          radius='full'
                          onClick={() =>
                            handleToogleStatus(data[dataIndex].uuid)
                          }>
                          {data[dataIndex].status === "Ativo" ? (
                            <HighlightOff />
                          ) : (
                            <CheckCircle />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome ou código",
            download: false,
            onSearchChange: (value: string) => {
              searchCall(value);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Box>
  );
}
