/** @format */

import { Save, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { SelectField } from "../../../../components/inputs/selectField";
import { aviso_e_social } from "../../../../config/constants";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";

export function GradePage() {
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      grade_table: false,
    },
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          grade_table: values.grade_table,
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        formik.setValues({
          grade_table: response.data.data.record.grade_table,
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/tableSalary" },
            { label: "Tabela salarial" },
          ]}
        />
        <InfoComponent instruction='Nesta tela é realizado a parametrização da tabela salarial' />
        <Paper sx={{ width: "100%", height: "auto", p: 3 }}>
          <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2}>
            <Typography fontSize={14} fontWeight={500}>
              Parametrização da tabela salarial
            </Typography>
          </Grid>
          <Grid
            gridTemplateColumns={"1fr 1fr 1fr 1fr"}
            display={"grid"}
            mt={2}
            gap={2}>
            <SelectField
              label='Ativar tabela salarial'
              id='grade_table'
              options={aviso_e_social}
              formik={formik}
            />
          </Grid>
        </Paper>
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Save />}>
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
