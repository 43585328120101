/** @format */

import { Filter } from "../../../../../components/filter";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";

export const Filters = ({ formik, options }: any) => {
  return (
    <Filter
      formik={formik}
      id={"users_inactive"}
      keys={{
        job_role_id: { label: "Cargo", options: options?.job_roles ?? [] },
        manager_id: { label: "Gestor", options: options?.managers ?? [] },
        department_id: {
          label: "Departamento",
          options: options?.departments ?? [],
        },
      }}>
      <AutoCompleteField
        formik={formik}
        label='Cargo'
        id='job_role_id'
        options={options?.job_roles}
      />
      <AutoCompleteField
        formik={formik}
        label='Gestor'
        id='manager_id'
        options={options?.managers}
      />
      <AutoCompleteField
        formik={formik}
        label='Departamento'
        id='department_id'
        options={options?.departments}
      />
    </Filter>
  );
};
