/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { ClearCurrency } from "../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function SalaryReviewNew() {
  const { id } = useParams();
  const userData = useSelector((state: any) => state.user.data);
  const [user, setUser]: any = useState({});
  const [rules, setRules]: any = useState({});
  const dispatch = useDispatch();
  const [valide, setValide] = useState(false);

  const formik: any = useFormik({
    initialValues: {
      e_social_motive: null,
      move_date: null,
      percent: "",
      future_salary: "",
      observations: "",
      actual_salary: "",
    },
    validationSchema: validationSchema(rules, user),
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values: any) => {
      setValide(true);
      dispatch(updateLoading(true));
      values.manager_id = userData.id;
      delete user.uuid;
      delete user.status;
      delete values.actual_salary;
      delete user.salary_range;
      PostInstante(
        null,
        {
          ...values,
          ...user,
          future_salary: ClearCurrency(values.future_salary),
          percent: Number(values.percent),
        },
        "/salary_reviews"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          window.history.back();
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({ user_uuid: id }, "/salary_reviews_form")
      .then(async (response: any) => {
        setUser(response.data.data.user_data);
        setRules(response.data.data.rules_data);
        formik.setFieldValue(
          "actual_salary",
          ClearCurrency(response.data.data.user_data.remuneration_value) ?? "0"
        );
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/salaryReview" },
            { label: "Revisão salarial", value: "/request/salaryReview" },
            { label: "Nova solicitação" },
          ]}
        />
        <InfoComponent
          user={user}
          instruction='Para criar uma revisão salarial, confira as informações abaixo e preencha os campos.'
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar revisão salarial
              </Typography>
            </Grid>
            <Form formik={formik} userData={userData} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                type={"submit"}
                endIcon={<AddCircle />}>
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
