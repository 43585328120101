/** @format */

import { Grid } from "@mui/material";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";

export const Form = ({ formik }: any) => {
  return (
    <>
      <Grid
        gridTemplateColumns={"1fr 1fr 1fr 1fr"}
        display={"grid"}
        mt={2}
        gap={2}>
        <InputDateField
          formik={formik}
          label='Data de aquisição inicial'
          id='acquisition_period_start'
          onChange={(value: any) => {
            formik.setFieldValue(
              `concessive_period_start`,
              value.add(1, "year")
            );
          }}
        />
        <InputDateField
          formik={formik}
          label='Data de aquisição final'
          id='acquisition_period_end'
          onChange={(value: any) => {
            formik.setFieldValue(`concessive_period_end`, value.add(1, "year"));
          }}
        />
        <InputDateField
          formik={formik}
          label='Data de inicio da concessão'
          id='concessive_period_start'
        />
        <InputDateField
          formik={formik}
          label='Data final da concessão'
          id='concessive_period_end'
        />
        <InputTextField
          formik={formik}
          label='Quantidade de dias disponiveis'
          id='days_available'
          type='number'
        />
        <InputTextField
          formik={formik}
          label='Quantidade de dias usados'
          id='days_used'
          type='number'
        />
      </Grid>
    </>
  );
};
