/** @format */

import { Cancel, CheckCircle, Send } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Flex } from "@radix-ui/themes";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Lottie from "react-lottie";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import animationData from "../../assets/lotties/loading_modal.json";
import { FormatCurrency } from "../../helpers/formatCurrency";
import { updateLoading } from "../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../services/api";

const validationSchema = yup.object({
  job_role_id: yup.string().nullable().required("Cargo é obrigatório"),
  salary: yup.string().nullable().required("Remuneração é obrigatório"),
});

export function ActionAprovedForm({ id, url, open, onOpenAprovedDialog }: any) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState({ job_role: [], job_role_group: [] });

  const loading = useSelector((state: any) => state.common.loading);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const formik = useFormik({
    initialValues: {
      job_role_id: "",
      salary: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      Approve();
    },
  });

  function Approve() {
    dispatch(updateLoading(true));
    PostInstante(
      null,
      {
        action_type: "approved",
        action_justification: null,
        job_role_id: formik.values.job_role_id,
        salary: formik.values.salary,
      },

      "/" + url + "/" + id + "/action"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.history.back();
        }, 3000);
        onOpenAprovedDialog(false);
      })
      .catch((e: any) => {
        onOpenAprovedDialog(false);
      });
  }

  useEffect(() => {
    GetInstance({}, "/personnel_requisitions_form")
      .then(async (response: any) => {
        // dispatch(updateLoading(false));
        setOptions(response.data.data.options);
      })
      .catch((e: any) => {
        if (e.code !== "ERR_CANCELED") {
          // dispatch(updateLoading(false));
          toast.error(
            e?.response?.data?.message ?? "Erro inesperado, tente novamente."
          );
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button
        variant={"contained"}
        color={"success"}
        onClick={() => onOpenAprovedDialog(true)}
        endIcon={<Send />}
        fullWidth={isMobile}>
        Aprovar
      </Button>
      <Dialog open={open}>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            {loading ? (
              <Lottie options={defaultOptions} height={"auto"} width={200} />
            ) : (
              <Grid>
                <Typography>
                  Deseja realmente aprovar esta solicitação?
                </Typography>
                <Typography>
                  Após a confirmação, não será possivel reverter o processo.
                  Tenha absoluta certeza antes de realizar essa ação.
                </Typography>

                <Grid mt={2} display='flex' flexDirection='column' gap={2}>
                  <TextField
                    fullWidth
                    id='job_role_id'
                    select
                    name='job_role_id'
                    label='Cargo'
                    value={formik.values.job_role_id}
                    onChange={(event: any) => {
                      formik.setFieldValue("job_role_id", event.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.job_role_id &&
                      Boolean(formik.errors.job_role_id)
                    }
                    helperText={
                      formik.touched.job_role_id && formik.errors.job_role_id
                    }>
                    {options.job_role.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    fullWidth
                    id='salary'
                    label='Remuneração'
                    variant='outlined'
                    value={formik.values.salary}
                    onChange={(event: any) => {
                      formik.setFieldValue("salary", FormatCurrency(event));
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.salary && Boolean(formik.errors.salary)
                    }
                    helperText={formik.touched.salary && formik.errors.salary}
                  />
                </Grid>

                <Flex gap='3' mt='4' justify='end'>
                  <DialogActions>
                    <Button
                      variant={"outlined"}
                      endIcon={<Cancel />}
                      onClick={() => onOpenAprovedDialog(false)}>
                      Cancelar
                    </Button>
                  </DialogActions>

                  <DialogActions>
                    <Button
                      variant={"contained"}
                      type='submit'
                      endIcon={<CheckCircle />}>
                      Confirmar
                    </Button>
                  </DialogActions>
                </Flex>
              </Grid>
            )}
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
