/** @format */

import * as yup from "yup";

export const validationSchema = yup.object().shape({
  audit_retain_days: yup
    .number()
    .required("Campo obrigatório")
    .min(30, "Minimo de 30 dias"),
});
