import * as yup from "yup";

export const validationSchema = yup.object({
  description: yup.string().required("Descrição é obrigatório"),
  code: yup.string().required("Código é obrigatório"),
});

export const columns = [
  {name: "description", label: "Descrição"},
  {name: "weekly_hours_amount", label: "Horas Semanais"},
  {name: "monthly_hours_amount", label: "Horas Mensais"},
  {name: "work_schedule_type", label: "Tipo de Escala"},
  {name: "night_shift", label: "Turno Noturno"},
  {name: "vacation_exeption", label: "Exceção de Férias"},
];