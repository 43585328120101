/** @format */

import dayjs from "dayjs";
import { ClearString } from "../../../../../helpers/clearString";
import { FormatCPF } from "../../../../../helpers/formatCPF";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";
import { FormatPhone } from "../../../../../helpers/formatPhone";

interface Props {
  id: any;
  formik: any;
  setData: any;
  setIndex: any;
  dispatch: any;
}

export async function GetData({
  id,
  formik,
  setData,
  setIndex,
  dispatch,
}: Props) {
  dispatch(updateLoading(true));
  GetInstance({ user_uuid: id }, "/users_form")
    .then(async (response: any) => {
      if (
        response.data.data.address_data &&
        response.data.data.address_data.country
      ) {
        delete response.data.data?.address_data?.country;
      }
      const dataFormat = {
        city_of_birth: ClearString(
          response.data.data.record_data?.city_of_birth ?? ""
        ).toLocaleUpperCase(),
        ...response.data.data.address_data,
        dependents: response.data.data.dependant_data,
        emergency_contacts: response.data.data.emergency_contact_data,
        fields_with_required_documents:
          response.data.data.fields_with_required_documents,
        ...response.data.data.record_data,
        position_code: response.data.data.user_data.position_code,
        position_type: response.data.data.user_data.position_type,
        phone: FormatPhone(response.data.data.record_data.phone),
      };
      // eslint-disable-next-line array-callback-return
      Object.entries(dataFormat).map((opt: any) => {
        if (opt[0].indexOf("date") !== -1 && opt[1] !== null) {
          dataFormat[opt[0]] = dayjs(opt[1]);
        }
        if (opt[0] === "business_unit_employment_bond" && opt[1] !== null) {
          dataFormat[opt[0]] = dayjs(opt[1]);
        }
        if (opt[0] === "business_unit_exp_extension" && opt[1] !== null) {
          dataFormat[opt[0]] = dayjs(opt[1]);
        }
        if (opt[0] === "contribution_starting_from" && opt[1] !== null) {
          dataFormat[opt[0]] = dayjs(opt[1]);
        }
        if (opt[0] === "business_unit_contract_finish" && opt[1] !== null) {
          dataFormat[opt[0]] = dayjs(opt[1]);
        }
        if (opt[0] === "cpf") {
          dataFormat[opt[0]] = FormatCPF(opt[1]);
        }
      });
      // eslint-disable-next-line array-callback-return
      dataFormat.dependents.map((item: any) => {
        item.birth_date = dayjs(item.birth_date);
        item.cpf = FormatCPF(item.cpf);
      });
      formik.setValues({ ...dataFormat, falta: null });
      setData({
        ...response.data.data,
        
        options: {
          ...response.data.data.options,
        },
      });
      setIndex(1);
      dispatch(updateLoading(false));
    })
    .catch((e: any) => {
      GenericError(e, dispatch);
    });
}
