export const MENU_ITENS = [
  { id: "promotion", label: "Promoção" },
  { id: "salary_review", label: "Revisão salarial" },
  { id: "transfer", label: "Transferencia" },
  { id: "dismissal", label: "Demissão" },
  { id: "new_user", label: "Admissão" },
  { id: "absence", label: "Afastamento" },
  { id: "user_update_request", label: "Alteração Cadastral" },
];

export const MESES = [
  { id: 1, label: "Janeiro" },
  { id: 2, label: "Fevereiro" },
  { id: 3, label: "Março" },
  { id: 4, label: "Abril" },
  { id: 5, label: "Maio" },
  { id: 6, label: "Junho" },
  { id: 7, label: "Julho" },
  { id: 8, label: "Agosto" },
  { id: 9, label: "Setembro" },
  { id: 10, label: "Outubro" },
  { id: 11, label: "Novembro" },
  { id: 12, label: "Dezembro" },
];