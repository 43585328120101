/** @format */

import { CheckCircle, Edit, Send, Undo, Visibility } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionAccountingDisapproved } from "../../../../../components/actionAccountingDisapproved";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionResend } from "../../../../../components/actionResend";
import { ActionReturned } from "../../../../../components/actionReturned";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { DocsView } from "../../../../../components/docsView";
import { InfoComponent } from "../../../../../components/infoComponent";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import {
  ClearCurrency,
  FormatCurrency,
} from "../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { validationSchemaEdit } from "../helpers/constants";
import { FormEdit } from "../helpers/formEdit";
import { HistoryComponent } from "../helpers/historyComponent";

export function TransferDetails() {
  const [docs, setDocs] = useState(false);
  const [data, setData]: any = useState({
    options: {
      business_unit: [],
      sector: [],
      cost_center: [],
      job_role: [],
      department: [],
      positions: [],
      managers: [],
    },
    collaborator_data: {},
    transfer_data: {},
    rules_data: {
      transfer_with_promotion_min_percent: 0,
      transfer_with_promotion_max_percent: 0,
      transfer_without_promotion_min_percent: 0,
      transfer_without_promotion_max_percent: 0,
    },
    solicitation_data: { status: "pending" },
  });
  const { id } = useParams();
  const user = useSelector((state: any) => state.user.data);
  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.transfer_data.new_manager_id;

  const isNewManager = user.id === data.transfer_data.new_manager_id;
  const isRh = user.role === "rh";
  const solicitationWaitingManagerUser =
    data?.solicitation_data.status === "waiting_manager_user";
  const soliciationComplete = data?.solicitation_data.status === "completed";
  const soliciationApproved = data?.solicitation_data.status === "approved";
  const [valide, setValide] = useState(false);
  const edit =
    (isNewManager && solicitationWaitingManagerUser) ||
    (isRh && !soliciationComplete && !soliciationApproved) ||
    isResendable;

  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      business_unit_id: "",
      transfer_date: null,
      new_remuneration_percentage: "",
      new_remuneration_value: "",
      observations: "",
      with_position: "",
      with_promotion: false,
      sector_id: "",
      cost_center_id: "",
      job_role_id: "",
      department_id: "",
      position_id: null,
      actual_salary: "",
    },
    validationSchema: validationSchemaEdit(data),
    validateOnChange: valide,
    validateOnBlur: valide,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(
        id,
        {
          ...values,
          new_remuneration_value: ClearCurrency(values.new_remuneration_value),
        },
        "/transfers"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          window.history.back();
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({ transfer_uuid: id }, "/transfers/update_form")
      .then((response: any) => {
        const data = response.data.data;
        setData((old: any) => ({ ...old, ...data }));
        const transfer_date = dayjs(data.transfer_data.transfer_date);
        formik.setValues({
          business_unit_id: data.transfer_data.business_unit_id || "",
          transfer_date: transfer_date,
          new_remuneration_percentage:
            data.transfer_data.new_remuneration_percentage || "",
          new_remuneration_value: FormatCurrency({
            target: {
              value: `${data.transfer_data.new_remuneration_value}` || "0",
            },
          }),
          observations: data.transfer_data.observations,
          with_position: data.transfer_data.with_position,
          with_promotion: data.transfer_data.with_promotion || false,
          sector_id: data.transfer_data.sector_id || "",
          cost_center_id: data.transfer_data.cost_center_id || "",
          job_role_id: data.transfer_data.job_role_id || "",
          department_id: data.transfer_data.department_id || "",
          position_id: data.transfer_data.position_id || "",
          actual_salary: ClearCurrency(
            data.collaborator_data.remuneration_value
          ),
          new_manager_name: data.transfer_data.new_manager_name,
        });
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/transfer" },
            { label: "Transferências", value: "/request/transfer" },
            { label: "Ver solicitação" },
          ]}
        />
        <InfoComponent
          user={data.collaborator_data}
          solicitation={data.solicitation_data}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da transferência
              </Typography>
            </Grid>
            <FormEdit formik={formik} data={data} edit={edit} />
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <Grid container gap={2} justifyContent={"end"}>
                {isResendable && (
                  <ActionResend
                    id={id}
                    url={"transfers"}
                    disabled={!formik.isValid}
                    onSubmit={(resendActionCallback: any) => {
                      PutInstante(
                        id,
                        {
                          ...formik.values,
                          new_remuneration_value: ClearCurrency(
                            formik.values.new_remuneration_value
                          ),
                        },
                        "/transfers"
                      )
                        .then(() => {
                          resendActionCallback();
                          dispatch(updateLoading(false));
                          window.history.back();
                        })
                        .catch((e: any) => {
                          GenericError(e, dispatch);
                        });
                    }}
                  />
                )}

                <ButtonComponent
                  variant={"outlined"}
                  onClick={() => {
                    window.history.back();
                  }}
                  endIcon={<Undo />}>
                  Voltar
                </ButtonComponent>

                <StatusPermittedByRoleContainer
                  rhAllowedStatuses={[
                    "pending",
                    "waiting_manager_user",
                    "waiting_rh_user",
                  ]}
                  status={data?.solicitation_data.status}>
                  {edit &&
                    <>
                      <ActionReturned id={id} url={"transfers"} />
                      <ActionDisapproved id={id} url={"transfers"} />
                      <ButtonComponent
                        variant={"contained"}
                        color={user.role === "rh" ? "success" : "primary"}
                        type={"submit"}
                        endIcon={user.role === "rh" ? <CheckCircle /> : <Edit />}>
                        {user.role === "rh"
                          ? "Salvar e aprovar"
                          : "Salvar e enviar para o RH"}
                      </ButtonComponent>
                    </>
                  }
                </StatusPermittedByRoleContainer>
                <StatusPermittedByRoleContainer
                  managerAllowedStatuses={["pending", "waiting_manager_user"]}
                  status={data?.solicitation_data.status}>
                  {isNewManager && (
                    <>
                      <ActionDisapproved id={id} url={"transfers"} />
                      <ButtonComponent
                        variant={"contained"}
                        type={"submit"}
                        onClick={() => {
                          setValide(true);
                        }}
                        endIcon={<Edit />}>
                        Salvar e enviar para o RH
                      </ButtonComponent>
                    </>
                  )}
                </StatusPermittedByRoleContainer>

                {isResendable && (
                  <ButtonComponent
                    variant={"contained"}
                    type={"submit"}
                    onClick={() => {
                      setValide(true);
                    }}
                    color={"success"}
                    endIcon={<Send />}>
                    Reenviar
                  </ButtonComponent>
                )}

                <StatusPermittedByRoleContainer
                  accountantAllowedStatuses={["approved"]}
                  status={data?.solicitation_data.status}>
                  <ActionCompleted id={id} url={"transfers"} />
                  <ActionAccountingDisapproved id={id} url={"processes"} />
                </StatusPermittedByRoleContainer>
                <StatusPermittedByRoleContainer
                  rhAllowedStatuses={["completed"]}
                  managerAllowedStatuses={["completed"]}
                  status={data?.solicitation_data.status}>
                  <ButtonComponent
                    onClick={() =>
                      setDocs(true)
                    }
                    variant={"contained"}
                    endIcon={<Visibility />}>
                    Ver documentos de comunicação
                  </ButtonComponent>
                </StatusPermittedByRoleContainer>
              </Grid>
            </Grid>
            <HistoryComponent
              data={data?.solicitation_data?.solicitation_histories}
              options={data.options}
            />
          </Paper>
          {docs && (
            <DocsView solicitation_uuid={data?.solicitation_data?.uuid} solicitation_type={"transfer"} />
          )}
        </Grid>
      </Grid>
    </form>
  );
}
