/** @format */

import dayjs from "dayjs";

export function ClearString(str: string) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const ClearCPF = (value: string) => {
  if (value === null || value === undefined) {
    return "";
  }
  return ClearString(
    value.toString().toLowerCase().replaceAll(".", "").replaceAll("-", "")
  );
};

export const ClearDate = (value: string) => {
  if (value === null || value === undefined) {
    return "";
  }
  return dayjs(value).format("DD/MM/YYYY");
};
