/** @format */

import { Cancel, CheckCircle, Send } from "@mui/icons-material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GenericError } from "../helpers/genericError";
import { updateLoading } from "../redux/reducers/common";
import { PostInstante } from "../services/api";
import { ButtonComponent } from "./buttonComponent";

interface Props {
  id: string | number | null | undefined;
  url: string;
  close?: () => void;
  beforeAction?: (onSubmit: any) => void;
}

export function ActionCompleted({ id, url }: Props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  function Approve() {
    setOpen(false);
    dispatch(updateLoading(true));
    PostInstante(
      null,
      {
        action_type: "completed",
        action_justification: null,
      },
      "/" + url + "/" + id + "/action"
    )
      .then((response: any) => {
        dispatch(updateLoading(false));
        toast.success(response?.data?.message);
        setTimeout(() => {
          window.history.back();
        }, 3000);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  }

  return (
    <form>
      <AlertDialog.Root open={open} onOpenChange={setOpen}>
        <AlertDialog.Trigger>
          <ButtonComponent
            variant={"contained"}
            color={"success"}
            endIcon={<Send />}>
            Concluir
          </ButtonComponent>
        </AlertDialog.Trigger>
        <AlertDialog.Content maxWidth='450px'>
          <AlertDialog.Title>Deseja concluir este processo?</AlertDialog.Title>
          <AlertDialog.Description size='2'>
            Após a confirmação, não será possivel reverter o processo. Tenha
            absoluta certeza antes de realizar essa ação.
          </AlertDialog.Description>
          <Flex gap='3' mt='4' justify='end'>
            <AlertDialog.Cancel>
              <ButtonComponent
                color='error'
                variant={"outlined"}
                endIcon={<Cancel />}>
                Desistir
              </ButtonComponent>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <ButtonComponent
                color='success'
                variant={"contained"}
                onClick={Approve}
                endIcon={<CheckCircle />}>
                Confirmar
              </ButtonComponent>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </form>
  );
}
