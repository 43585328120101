/** @format */
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useDispatch } from "react-redux";
import { ActionsUser } from "../../../../components/actionsUser";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { optionsTable } from "../../../../config/optionsTable";
import useUsers from "../../../../services/protected/hooks/useUsers";
import { columns, validationSchema } from "./helpers/constants";
import { Filters } from "./helpers/filters";
import { handleDownloadButton } from "./helpers/handleDownloadButton";

export function Active() {
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      job_role_id: "all",
      manager_id: "all",
      department_id: "all",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      setReset(true);
    },
  });

  const {
    data,
    total,
    setOrder,
    setPage,
    setPerPage,
    searchCall,
    page,
    perPage,
    options,
    setReset,
  } = useUsers({ ...formik.values, status: "active" });

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[{ label: "Inicio" }, { label: "Colaboradores" }]}
      />
      <Filters formik={formik} options={options} />
      <MUIDataTable
        title={""}
        data={data}
        columns={[
          {
            name: "action",
            label: "Ações",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return <ActionsUser data={data} dataIndex={dataIndex} />;
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por nome ou CPF",
          download: true,
          onDownload: () => {
            handleDownloadButton(dispatch, formik);
            return false;
          },
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPage(0);
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
