/** @format */

import { Filter } from "../../../../../components/filter";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { statusOptions, typeLetterOptions } from "./constants";

export function Filters({ formik }: any) {
  return (
    <Filter
      formik={formik}
      id={"letter"}
      keys={{
        solicitation_type: {
          label: "Tipo de carta",
          options: typeLetterOptions ?? [],
        },
        active: { label: "Status", options: statusOptions },
      }}>
      <AutoCompleteField
        id='solicitation_type'
        options={typeLetterOptions}
        labelcode={false}
        label='Tipo de carta'
        formik={formik}
      />
      <SelectField
        formik={formik}
        id='active'
        label='Status'
        options={statusOptions}
      />
    </Filter>
  );
}
