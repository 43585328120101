/** @format */

import { Grid } from "@mui/material";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { AutoCompleteFieldMultiple } from "../../../../../components/inputs/autoCompleteFieldMultiple";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { aviso_e_social, motive_e_social, typeLetter } from "../../../../../config/constants";

export function Form({ formik, options }: any) {
  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        <InputTextField formik={formik} id='name' label='Nome' />
        <SelectField
          formik={formik}
          id='active'
          label='Ativar exibição da carta'
          options={aviso_e_social}
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2} mt={2}>
        <AutoCompleteField
          id='solicitation_type'
          options={typeLetter}
          labelcode={false}
          label='Tipo de carta'
          formik={formik}
        />
        {formik.values.solicitation_type === "dismissal" && (
          <AutoCompleteFieldMultiple
            id='dismissal_types'
            label='Tipo de desligamento'
            formik={formik}
            labelcode
            options={options.dismissal_types.map((option: any) => ({
              value: option.id,
              label: option.name,
            }))}
          />
        )}
        {formik.values.solicitation_type === "salary_review" && (
          <AutoCompleteFieldMultiple
          formik={formik}
          id='salary_review_types'
          label='Motivo da revisão'
          options={
             motive_e_social.map((item: any) => ({
                  value: item,
                  label: item,
                }))
              
          }
        />
        )}
        
      </Grid>
    </>
  );
}
