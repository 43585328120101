/** @format */

import { Save, Undo } from "@mui/icons-material";
import { Grid, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";
import { validationSchema } from "./helpers/constants";

export function PermissionsAudit() {
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      audit_retain_days: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(
        null,
        {
          audit_retain_days: values.audit_retain_days,
        },
        "/company_settings"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/company_settings/")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        formik.setValues({
          audit_retain_days: response.data.data.record.audit_retain_days,
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Parametrizações", value: "/settings/audit" },
            { label: "Auditória" },
          ]}
        />
        <InfoComponent instruction='Nesta tela é permitido a configuração de dias para salvar da auditória.' />

        <Paper sx={{ width: "100%", height: "auto" }}>
          <Grid gridTemplateColumns={"1fr"} display={"grid"} m={2} gap={2}>
            <Typography fontSize={14} fontWeight={500}>
              Parametrizações da auditória
            </Typography>
          </Grid>
          <Grid
            gridTemplateColumns={"1fr 1fr 1fr 1fr"}
            display={"grid"}
            m={2}
            gap={2}>
            <TextField
              fullWidth
              id={`audit_retain_days`}
              type='number'
              label='Quantidade de dias'
              variant='outlined'
              value={formik.values.audit_retain_days}
              onChange={(event: any) => {
                formik.setFieldValue(`audit_retain_days`, event.target.value);
              }}
              error={Boolean(formik.errors.audit_retain_days)}
              helperText={formik.errors.audit_retain_days}
            />
          </Grid>
        </Paper>
        <Grid container gap={2} justifyContent={"end"} mt={2}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
          <ButtonComponent
            variant={"contained"}
            onClick={formik.handleSubmit}
            endIcon={<Save />}>
            Salvar modificações
          </ButtonComponent>
        </Grid>
      </Grid>
    </form>
  );
}
