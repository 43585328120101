/** @format */

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FilterEmpty } from "../../../helpers/emptyData";
import { GenericError } from "../../../helpers/genericError";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../api";

let timerSearch: any;

export default function usePromote(id?: string) {
  const [data, setData]: any = useState({
    my_records: [],
    team_records: [],
    general_records: [],
  });
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal]: any = useState({
    my_records: 0,
    team_records: 0,
    general_records: 0,
  });
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);
  const dispatch = useDispatch();

  const getCall = useCallback(async () => {
    try {
      dispatch(updateLoading(true));
      const { data } = await GetInstance(
        { page: page + 1, per_page: perPage, search, order_by: order },
        "/promotions"
      );
      setData({
        my_records: FilterEmpty(data.data.my_records?.data ?? []),
        team_records: FilterEmpty(data.data.team_records?.data ?? []),
        general_records: FilterEmpty(data.data.general_records?.data ?? []),
      });
      setTotal({
        my_records: FilterEmpty(data.data.my_records?.total_count ?? 0),
        team_records: FilterEmpty(data.data.team_records?.total_count ?? 0),
        general_records: FilterEmpty(
          data.data.general_records?.total_count ?? 0
        ),
      });
      dispatch(updateLoading(false));
    } catch (e: any) {
      GenericError(e, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, page, search, perPage, order]);

  //para atualizar a chamada
  useEffect(() => {
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, perPage, order]);

  const searchCall = (value: string) => {
    clearTimeout(timerSearch);
    timerSearch = setTimeout(() => {
      setPage(0);
      setSearch(value);
    }, 500);
  };

  return {
    data,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrder,
    order,
    searchCall,
  };
}
