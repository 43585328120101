/** @format */

import { UploadFile } from "@mui/icons-material";
import { Chip, Tooltip, useTheme } from "@mui/material";
import { AlertDialog } from "@radix-ui/themes";
import { useRef } from "react";

interface Props {
  option: any;
  index: any;
  index2?: any;
  formik: any;
  filesForm: any;
  setFileForm: any;
}
export function UploadDocument({
  option,
  index,
  index2,
  formik,
  filesForm,
  setFileForm,
}: Props) {
  const fileInputRef: any = useRef([]);
  const theme = useTheme();

  const onFileDrop = async (
    file: any,
    keyValueString: string,
    index: number
  ) => {
    const fileNew: any = filesForm;
    // eslint-disable-next-line array-callback-return
    fileNew.map((item: any) => {
      if (item.keyValue === keyValueString) {
        if (Array.isArray(item.file)) {
          item.file[index] = file;
        } else {
          item.file = file;
        }
      }
    });
    setFileForm([]);
    setTimeout(() => {
      setFileForm(fileNew);
    }, 1);
    formik.setErrors({});
    const filesAll: any = [];
    // eslint-disable-next-line array-callback-return
    fileNew.map((item: any) => {
      if (item.file !== null) {
        if (Array.isArray(item.file)) {
          item.file.map((item2: any) => filesAll.push(item2));
        } else {
          filesAll.push(item.file);
        }
      }
    });
    formik.setFieldValue("files", filesAll);
  };
  const openFileDialog = (index: any) => {
    fileInputRef.current[index].click();
  };

  const handleDrop = (e: any, key: string, index: number) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      onFileDrop(file, key, index);
    }
  };

  const handleFileInputChange = (e: any, key: string, index: number) => {
    const file = e.target.files[0];
    if (file) {
      onFileDrop(file, key, index);
    }
  };

  const handleDragOver: any = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  };

  return (
    <AlertDialog.Description key={option.keyValue} size='2' mt={"4"}>
      {option.file === null || option.file[index2] === null ? (
        <div
          style={{
            border: `1px ${
              formik.errors.files
                ? theme.palette.error.main
                : theme.palette.text.secondary
            } dashed`,
            color: formik.errors.files
              ? theme.palette.error.main
              : theme.palette.text.secondary,
            cursor: "pointer",
            borderRadius: 10,
            width: "inherit",
          }}
          className='ContextMenuTrigger'
          onDragOver={(e) => handleDragOver(e, option.keyValue)}
          onDrop={(e) => handleDrop(e, option.keyValue, index2)}
          onClick={() => openFileDialog(index)}>
          <input
            key={index + option.keyValue}
            type='file'
            accept='*'
            ref={(el) => (fileInputRef.current[index] = el)}
            onChange={(e) => {
              handleFileInputChange(e, option.keyValue, index2);
            }}
            style={{ display: "none" }}
          />
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              margin: 16,
              alignItems: "center",
            }}>
            <UploadFile style={{ marginRight: 12 }} /> Arraste e solte um
            arquivo aqui ou clique para selecionar um arquivo.
          </p>
        </div>
      ) : (
        <Tooltip title={"Remover arquivo"}>
          <Chip
            color={"primary"}
            onDelete={() => {
              const fileNew = filesForm;
              // eslint-disable-next-line array-callback-return
              fileNew.map((item: any) => {
                if (item.keyValue === option.keyValue) {
                  if (Array.isArray(item.file)) {
                    item.file[index2] = null;
                  } else {
                    item.file = null;
                  }
                }
              });
              setFileForm([]);
              setTimeout(() => {
                setFileForm(fileNew);
              }, 1);
              formik.setErrors({});
              const filesAll: any = [];
              fileNew.map(
                (item: any) => item.file !== null && filesAll.push(item.file)
              );
              formik.setFieldValue("files", filesAll);
            }}
            label={
              Array.isArray(option.file)
                ? option.file[index2].name
                : option.file.name
            }
            variant='outlined'
          />
        </Tooltip>
      )}
    </AlertDialog.Description>
  );
}
