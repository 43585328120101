/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { aviso_e_social } from "../../../../../config/constants";
import {
  HiddenCheck,
  HiddenCheckChildren,
} from "../../../../../helpers/hiddenCheck";

export function Benefits({ formik, data }: any) {
  useEffect(() => {
    formik.setFieldValue(
      "health_insurance_name",
      data.options.health_insurance.filter(
        (opt: any) => opt.value === formik.values.health_insurance_id
      )[0]?.description
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.health_insurance_id]);
  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid id={"health_insurance_id"}>
          <Typography fontSize={16} fontWeight={500}>
            Plano de saúde
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='health_insurance_id'
              label='Operadora'
              options={data.options.health_insurance}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              disabled
              id='health_insurance_name'
              label='Plano'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputCurrencyField
              formik={formik}
              label='Preço'
              id='health_insurance_price'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Vale alimentação
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='food_voucher_id'
              label='Operadora'
              options={data.options.food_voucher}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputCurrencyField
              formik={formik}
              label='Preço'
              id='food_voucher_price'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Cesta básica
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              id='is_basic_basket_option'
              label='Optante cesta básica'
              options={aviso_e_social}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
