/** @format */

import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Chip, Divider, Grid, IconButton, Typography } from "@mui/material";
import { AutoCompleteField } from "../../../../components/inputs/autoCompleteField";
import { InputTextField } from "../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../components/inputs/selectField";
import { solicitationTypes } from "../../../../config/constants";
import { workflowOptions } from "./constants";

export const Form = ({ formik, options, edit }: any) => {
  return (
    <>
      <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2}>
        <AutoCompleteField
          formik={formik}
          id='solicitation_type'
          disabled={edit}
          label='Tipo da solicitação'
          options={solicitationTypes}
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <Typography fontSize={14} fontWeight={500}>
          Etapas do workflow
        </Typography>
      </Grid>
      {formik.values.workflow_steps.map((item: any, index: number) => {
        const valide1 = formik.values.workflow_steps[index].step_type === 'position'
        const numberIndex = index + 1
        const nextIndex = index + 2
        return (
          <>
            <Grid alignItems={'center'} gridTemplateColumns={valide1 ? "0.06fr 1fr 1fr 1fr 0.2fr" : "0.03fr 1fr 1fr 0.1fr"} mt={2} display={"grid"} gap={2}>
              <Chip
                size={"small"}
                color={'primary'}
                label={numberIndex}
              />
              <InputTextField
                formik={formik}
                disabled={edit}
                id={`workflow_steps[${index}]name`}
                label='Nome'
              />
              <SelectField
                formik={formik}
                id={`workflow_steps[${index}]step_type`}
                disabled={edit}
                label='Tipo do fluxo'
                options={workflowOptions}
              />
              {valide1 && <AutoCompleteField
                formik={formik}
                id={`workflow_steps[${index}]position_id`}
                disabled={edit}
                label='Posição'
                options={options.positions}
              />}
              <Grid alignContent={'center'} gridTemplateColumns={"1fr 1fr"} display={"grid"}>
                <IconButton onClick={() => {
                  const arrayOptions = formik.values.workflow_steps
                  arrayOptions.splice(numberIndex, 0, { position_id: '', name: 'Nivel ' + nextIndex, step_type: null, order: nextIndex })
                  // eslint-disable-next-line array-callback-return
                  arrayOptions.map((opt: any, index: number) => {
                    opt.name = opt.name.indexOf('Nivel') !== -1 ? 'Nivel ' + Number(index + 1) : opt.name
                    opt.order = Number(index + 1)
                  })
                  formik.setFieldValue('workflow_steps', arrayOptions)
                }} color="primary">
                  <AddCircleOutline />
                </IconButton>
                <IconButton onClick={() => {
                  const arrayOptions = formik.values.workflow_steps
                  arrayOptions.splice(index, 1)
                  formik.setFieldValue('workflow_steps', arrayOptions)
                }} color='error'><RemoveCircleOutline /></IconButton>
              </Grid>

            </Grid>
            <Grid mt={2}>
              <Divider />
            </Grid>
          </>
        )
      })}

    </>
  );
};
