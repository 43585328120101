export function validationCPF(cpf: any): boolean {
  if (cpf === undefined || cpf === null || cpf === '') return true
  // Remover caracteres não numéricos (como pontos e traços)
  cpf = cpf.replace(/[^\d]+/g, '');

  // Verificar se o CPF tem 11 dígitos e não é uma sequência de números repetidos
  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false;
  }

  // Calcular o primeiro dígito verificador
  let soma = 0;
  let peso = 10;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * peso--;
  }
  let primeiroDigito = 11 - (soma % 11);
  if (primeiroDigito === 10 || primeiroDigito === 11) {
    primeiroDigito = 0;
  }

  // Calcular o segundo dígito verificador
  soma = 0;
  peso = 11;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * peso--;
  }
  let segundoDigito = 11 - (soma % 11);
  if (segundoDigito === 10 || segundoDigito === 11) {
    segundoDigito = 0;
  }

  // Comparar os dígitos verificadores calculados com os informados
  return primeiroDigito === parseInt(cpf.charAt(9)) && segundoDigito === parseInt(cpf.charAt(10));
}
