/** @format */

import { AddCircle, Cancel } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import {
  dependents_e_social,
  other_relationships,
} from "../../../../../config/constants";
import { handleCellPhoneChange } from "../../../../../helpers/handleCellPhoneChange";
import { HiddenCheckChildren } from "../../../../../helpers/hiddenCheck";

export function EmergencyContacts({ formik, data }: any) {
  function Add() {
    const newArray = formik.values.emergency_contacts;
    newArray.push({
      name: "",
      phone: "",
      relationship_code: "",
      description: "",
    });
    formik.setFieldValue("emergency_contacts", newArray);
  }

  function Remove(id: number) {
    const newArray = formik.values.emergency_contacts;
    newArray.splice(id, 1);
    formik.setFieldValue("emergency_contacts", newArray);
  }

  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid id={"emergency_contacts"}>
          <Typography fontSize={16} fontWeight={500}>
            Contatos de emergência
          </Typography>
        </Grid>
        {formik.values.emergency_contacts.map((item: any, index: number) => (
          <div id={"emergency_contacts"}>
            <Divider style={{ marginTop: 16 }} />
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}>
              <Typography fontSize={14} fontWeight={400}>
                Contato de emergência {index + 1}
              </Typography>
              <Tooltip title={"Remover contato"}>
                <IconButton onClick={() => Remove(index)} color={"error"}>
                  <Cancel />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid
              gridTemplateColumns={"1fr 1fr 1fr 1fr"}
              mt={2}
              display={"grid"}
              gap={2}>
              <TextField
                fullWidth
                id={`emergency_contacts[${index}].name`}
                label='Nome'
                value={formik.values.emergency_contacts[index].name}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    `emergency_contacts[${index}].name`,
                    event.target.value
                  );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(formik.errors.emergency_contacts?.[index]?.name)}
                helperText={formik.errors.emergency_contacts?.[index]?.name}
              />
              <TextField
                fullWidth
                id={`emergency_contacts[${index}].relationship_code`}
                name={`emergency_contacts[${index}].relationship_code`}
                select
                label='Grau de parentesco'
                value={
                  formik.values.emergency_contacts[index].relationship_code
                }
                onChange={(event: any) => {
                  const relationship = dependents_e_social.find(
                    (item) => item.value === event.target.value
                  );
                  formik.setFieldValue(
                    `emergency_contacts[${index}].relationship_code`,
                    relationship?.value
                  );
                  formik.setFieldValue(
                    `emergency_contacts[${index}].description`,
                    relationship?.label
                  );
                }}
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.errors.emergency_contacts?.[index]?.relationship_code
                )}
                helperText={
                  formik.errors.emergency_contacts?.[index]?.relationship_code
                }>
                {dependents_e_social.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.value} - {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {formik.values.emergency_contacts[index].relationship_code ===
                "99" && (
                <TextField
                  fullWidth
                  id={`emergency_contacts[${index}].description`}
                  name={`emergency_contacts[${index}].description`}
                  select
                  label='Descrição'
                  value={formik.values.emergency_contacts[index].description}
                  onChange={(event: any) => {
                    formik.setFieldValue(
                      `emergency_contacts[${index}].description`,
                      event.target.value
                    );
                  }}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.errors.emergency_contacts?.[index]?.description
                  )}
                  helperText={
                    formik.errors.emergency_contacts?.[index]?.description
                  }>
                  {other_relationships.map((option: any, index: number) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <TextField
                fullWidth
                id={`emergency_contacts[${index}].phone`}
                label='Telefone'
                value={formik.values.emergency_contacts[index].phone}
                placeholder={"(00) 00000-0000"}
                onChange={(event: any) =>
                  handleCellPhoneChange(
                    event,
                    formik,
                    `emergency_contacts[${index}].phone`
                  )
                }
                onBlur={formik.handleBlur}
                error={Boolean(
                  formik.errors.emergency_contacts?.[index]?.phone
                )}
                helperText={formik.errors.emergency_contacts?.[index]?.phone}
              />
            </Grid>
            <Divider style={{ marginTop: 16 }} />
          </div>
        ))}
        <Grid mt={2}>
          <Button endIcon={<AddCircle />} onClick={Add} variant={"contained"}>
            Adicionar contato de emergência
          </Button>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
