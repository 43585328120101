/** @format */

import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputDateField } from "../../../../components/inputs/inputDateField";
import { SelectField } from "../../../../components/inputs/selectField";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance } from "../../../../services/api";
import { UserGetAll } from "../../../../services/protected/hooks/getUser";

interface Props {
  formik: any;
  id?: string;
}
export function Form({ formik, id }: Props) {
  const dispatch = useDispatch();
  const editPage = id !== undefined;
  const { users } = UserGetAll(dispatch);
  const user = useSelector((state: any) => state.user.data);

  useEffect(() => {
    if (user.id !== undefined)
      formik.setFieldValue("delegated_user_id", user.id);
    if (editPage) {
      dispatch(updateLoading(true));
      GetInstance({}, "/delegations/" + id)
        .then(async (response: any) => {
          dispatch(updateLoading(false));
          const initial_date = dayjs(response.data.data.initial_date);
          const final_date = dayjs(response.data.data.final_date);
          formik.setValues({
            initial_date,
            final_date,
            target_user_id: response.data.data.record.target_user_id,
            delegated_user_id: response.data.data.record.delegated_user_id,
          });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);

  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        <InputDateField
          formik={formik}
          id='initial_date'
          label='Data Início'
          disabled={editPage && user.id !== formik.values.delegated_user_id}
        />
        <InputDateField
          formik={formik}
          id='final_date'
          label='Data Fim'
          disabled={editPage && user.id !== formik.values.delegated_user_id}
        />
        <SelectField
          id='target_user_id'
          formik={formik}
          disabled={editPage && user.id !== formik.values.delegated_user_id}
          label='Atribuir a'
          options={users}
        />
        {user.role === "rh" ? (
          <SelectField
            id='delegated_user_id'
            formik={formik}
            disabled={editPage}
            label='Colaborador delegado'
            options={users}
          />
        ) : null}
      </Grid>
    </>
  );
}
