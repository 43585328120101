/** @format */
import dayjs from "dayjs";
import * as yup from "yup";
export const columns = [
  { name: "days_available", label: "Dias disponiveis" },
  { name: "days_used", label: "Dias utilizados" },
  { name: "status", label: "Status" },
];

export const validationSchema = yup.object().shape({
  acquisition_period_start: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido."),
  acquisition_period_end: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido.")
    .test(
      "min",
      "Data final de aquisição não pode ser anterior a data inicial",
      function (item: any, all: any) {
        if (
          dayjs(all.options.context.acquisition_period_start).isValid() &&
          dayjs(all.options.context.acquisition_period_end).isValid()
        ) {
          return (
            new Date(all.options.context.acquisition_period_start).getTime() <
            new Date(all.options.context.acquisition_period_end).getTime()
          );
        }
        return false;
      }
    ),
  concessive_period_start: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido.")
    .test(
      "min",
      "Data incial de concessão não pode ser anterior a data final de aquisição",
      function (item: any, all: any) {
        if (
          dayjs(all.options.context.acquisition_period_end).isValid() &&
          dayjs(all.options.context.concessive_period_start).isValid()
        ) {
          return (
            new Date(all.options.context.acquisition_period_end).getTime() <
            new Date(all.options.context.concessive_period_start).getTime()
          );
        }

        return false;
      }
    ),
  concessive_period_end: yup
    .date()
    .typeError("Data inválida")
    .nullable("Data inválida")
    .required("Campo obrigatório")
    .typeError("Data ou quantidade inválido.")
    .test(
      "min",
      "Data final de concessão não pode ser anterior a data inicial",
      function (item: any, all: any) {
        if (
          dayjs(all.options.context.concessive_period_end).isValid() &&
          dayjs(all.options.context.concessive_period_start).isValid()
        ) {
          return (
            new Date(all.options.context.concessive_period_end).getTime() >
            new Date(all.options.context.concessive_period_start).getTime()
          );
        }
        return false;
      }
    ),
  days_available: yup
    .number()
    .min(0, "Mínimo de 0")
    .max(30, "Máximo de 30")
    .required("Campo obrigatório"),
  days_used: yup
    .number()
    .min(0, "Mínimo de 0")
    .max(30, "Máximo de 30")
    .required("Campo obrigatório"),
});

export const columns2 = [
  { name: "start_date", label: "Data inicial" },
  { name: "end_date", label: "Data final" },
  { name: "days_quantity", label: "Quantidade de dias" },
  { name: "thirteen_anticipation", label: "Antecipação 13º" },
  { name: "abono_pecuniario", label: "Abono pecuniário" },
  { name: "payment_date", label: "Data do pagamento" },
  { name: "description", label: "Descrição" },
];
