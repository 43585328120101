/** @format */

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FilterEmpty } from "../../../helpers/emptyData";
import { GenericError } from "../../../helpers/genericError";
import { updateLoading } from "../../../redux/reducers/common";
import { GetInstance } from "../../api";

let timerSearch: any;

export default function useVacationDashboard(values?: any) {
  const [data, setData]: any = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch]: any = useState(null);
  const [order, setOrder]: any = useState(null);
  const dispatch = useDispatch();
  const [reset, setReset] = useState(true);

  const getCall = useCallback(async () => {
    try {
      const sendRequest = {
        ...values,
        page: page + 1,
        per_page: perPage,
        search,
        order_by: order,
        order,
        from: new Date(values.from).toLocaleDateString(),
        to: new Date(values.to).toLocaleDateString(),
      };

      if (sendRequest.status === "all") {
        delete sendRequest.status;
      }
      setReset(false);
      dispatch(updateLoading(true));
      const response = await GetInstance(
        sendRequest,
        "/vacation_items_dashboard"
      );
      setData(FilterEmpty(response.data.data.records.data));
      setTotal(response.data.data.records.total_count);
      dispatch(updateLoading(false));
    } catch (e: any) {
      GenericError(e, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, page, search, perPage, order, reset]);

  //para atualizar a chamada
  useEffect(() => {
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, perPage, order, reset]);

  const searchCall = (value: string) => {
    clearTimeout(timerSearch);
    timerSearch = setTimeout(() => {
      setPage(0);
      setSearch(value);
    }, 500);
  };

  return {
    data,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrder,
    order,
    searchCall,
    search,
    setReset,
  };
}
