/** @format */

import { Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionAccountingDisapproved } from "../../../../../components/actionAccountingDisapproved";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionResend } from "../../../../../components/actionResend";
import { ActionReturned } from "../../../../../components/actionReturned";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { DownloadFile } from "../../../../../components/donwloadFile";
import { InfoComponent } from "../../../../../components/infoComponent";
import { InputFile } from "../../../../../components/inputs/inputFile";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import {
  GetInstance,
  PostInstante,
  PutInstante,
} from "../../../../../services/api";
import {
  validationSchema,
  validationSchemaDocument,
} from "../helpers/constants";
import { DialogPosition } from "../helpers/dialogPosition";
import { Form } from "../helpers/form";
import { HistoryComponent } from "../helpers/historyComponent";

export function DismissalsDetails() {
  const [options, setOptions] = useState({
    dismissal_reasons: [],
    dismissal_types: [],
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData]: any = useState({
    collaborator_data: {},
    solicitation_data: {},
  });
  const [openModalPosition, setOpenModalPosition] = useState({
    open: false,
    action: null,
  });
  const [valide, setValide] = useState(false);
  const permissions = useSelector((state: any) => state.user.permissions);
  const user = useSelector((state: any) => state.user.data);
  const [reset, setReset] = useState(true)
  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.record.created_by_id;
  const edit2 =
    (permissions !== null &&
      permissions.indexOf("requestDismissalsDetailsEdit") !== -1 &&
      (data?.solicitation_data.status === "waiting_manager_user" ||
        data?.solicitation_data.status === "waiting_rh_user") &&
      (user.role === "rh" || user.role === "manager")) ||
    isResendable ||
    data?.solicitation_data.status === "approved" ||
    data?.solicitation_data.status === "completed" ||
    data?.solicitation_data.status === "cancelled";
  const formik: any = useFormik({
    initialValues: {
      dismissal_date: null,
      early_warning: "",
      files: [],
      dismissal_type_id: "",
      dismissal_reason_id: "",
      comment: "",
      position_delete: false,
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: !edit2 ? validationSchema : validationSchemaDocument,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(null, { ...values }, `/dismissals/${id}`)
        .then((response: any) => {
          const formData = new FormData();
          // eslint-disable-next-line array-callback-return
          formik.values.files.map((file: any) => {
            if (file instanceof File) formData.append("files[]", file);
          });
          PostInstante(
            null,
            formData,
            "/solicitations/" +
            response.data.data.solicitation_uuid +
            "/upload_files"
          )
            .then(() => {
              dispatch(updateLoading(false));
              setTimeout(() => {
                window.history.back();
              }, 2000);

              toast.success(response?.data?.message);
            })
            .catch((e: any) => {
              GenericError(e, dispatch);
            });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    if (reset) {
      setReset(false)
      dispatch(updateLoading(true));
      GetInstance({}, "/dismissals/" + id)
        .then(async (response: any) => {
          dispatch(updateLoading(false));
          const formData = response.data.data;
          setData(formData);
          formik.setValues({
            dismissal_date: dayjs(formData.record.dismissal_date),
            early_warning: formData.record.early_warning,
            files: formData.solicitation_files ?? [],
            dismissal_reason_id: formData.record.dismissal_reason_id,
            dismissal_type_id: formData.record.dismissal_type_id,
            comment: formData.record.comment,
            position_delete: false,
          });
          setOptions({
            dismissal_reasons: response.data.data.dismissal_reasons.group_0,
            dismissal_types: response.data.data.dismissal_types.group_0,
          });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
          if (e.code !== "ERR_CANCELED") {
            window.history.back();
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reset]);

  async function uploadFiles() {
    dispatch(updateLoading(true));
    const formData = new FormData();
    // eslint-disable-next-line array-callback-return
    formik.values.files.map((file: any) => {
      if (file instanceof File) formData.append("files[]", file);
    });
    PostInstante(
      null,
      formData,
      "/solicitations/" + data.solicitation_data.uuid + "/upload_files"
    )
      .then((res: any) => {
        dispatch(updateLoading(false));
        toast.success("Documento anexado com sucesso");
        setReset(true)
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
        const files: any = [];
        formik.values.files.map(
          (file: any) => file instanceof File === false && files.push(file)
        );
        formik.setFieldValue("files", files);
      });
  }

  useEffect(() => {
    formik.values.files.map(
      (file: any) => file instanceof File && uploadFiles()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.files.length]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {openModalPosition.open && (
        <DialogPosition
          formik={formik}
          setOpenModalPosition={setOpenModalPosition}
          onSubmit={openModalPosition.action}
        />
      )}
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Solicitações", value: "/request/dismissals" },
            {
              label: "Desligamentos",
              value: "/request/dismissals",
            },
            { label: "Detalhes da solicitação" },
          ]}
        />
        <InfoComponent
          user={data.collaborator_data}
          solicitation={data.solicitation_data}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes do desligamento
              </Typography>
            </Grid>
            <Form formik={formik} options={options} edit={edit2} />
            {((user.role !== "rh" && user.role !== "manager") ||
              (user.role === "rh" &&
                data?.solicitation_data.status !== "waiting_rh_user" &&
                data?.solicitation_data.status !== "waiting_manager_user") ||
              (user.role === "manager" &&
                data?.solicitation_data.status !== "waiting_manager_user")) && (
                <DownloadFile formik={formik} />
              )}
            <StatusPermittedByRoleContainer
              rhAllowedStatuses={["waiting_manager_user", "waiting_rh_user"]}
              managerAllowedStatuses={["waiting_manager_user"]}
              status={data?.solicitation_data.status}>
              <InputFile remove={false} formik={formik} />
            </StatusPermittedByRoleContainer>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              {isResendable && (
                <ActionResend
                  id={id}
                  url={"dismissals"}
                  disabled={!formik.isValid}
                  onSubmit={(resendActionCallback: any) => {
                    dispatch(updateLoading(true));
                    PutInstante(null, { ...formik.values }, `/dismissals/${id}`)
                      .then((response: any) => {
                        resendActionCallback();
                        dispatch(updateLoading(false));
                        window.history.back();
                      })
                      .catch((e: any) => {
                        GenericError(e, dispatch);
                      });
                  }}
                />
              )}
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                  "accounting_return",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ActionReturned id={id} url={"dismissals"} />
                <ActionDisapproved id={id} url={"dismissals"} />
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}>
                <ActionAproved
                  id={id}
                  close={() =>
                    setOpenModalPosition({ open: false, action: null })
                  }
                  values={formik.values}
                  url={"dismissals"}
                  beforeAction={(submit) => {
                    setValide(true);
                    formik.validateForm().then((e: any) => {
                      if (Object.keys(e).length === 0)
                        if (user.role === "rh") {
                          setOpenModalPosition({ open: true, action: submit });
                        } else {
                          submit();
                        }
                      else {
                        toast.error(
                          "Precisa ser anexado ao menos 1 documento."
                        );
                      }
                    });
                  }}
                />
              </StatusPermittedByRoleContainer>
              <StatusPermittedByRoleContainer
                accountantAllowedStatuses={["approved"]}
                status={data?.solicitation_data.status}>
                <ActionCompleted id={id} url={"dismissals"} />
                <ActionAccountingDisapproved id={id} url={"dismissals"} />
              </StatusPermittedByRoleContainer>
               
            </Grid>
            <HistoryComponent
              data={data?.solicitation_data?.solicitation_histories}
            />
          </Paper>
         
        </Grid>
      </Grid>
    </form>
  );
}
