/** @format */

import { Grid, Modal, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GenericError } from "../helpers/genericError";
import { SecretAnswers } from "../pages/protected/settingsAccount/secretAnswers";
import { updateLoadingSessao } from "../redux/reducers/common";
import { GetInstance } from "../services/api";

export const SecretQuestionsRoute = ({ children }: any) => {
  const dispatch = useDispatch();
  const [hasUnansweredQuestions, setHasUnansweredQuestions]: any =
    useState(false);

  useEffect(() => {
    dispatch(updateLoadingSessao(true));
    GetInstance({}, `/secret_questions`)
      .then(async (response: any) => {
        const {
          secret_questions: secretQuestions,
          unanswered_questions: unansweredQuestions,
        } = response.data.data.records;
        // eslint-disable-next-line array-callback-return
        secretQuestions.map((question: any) => {
          if (unansweredQuestions.includes(question.id)) {
            setHasUnansweredQuestions(true);
          }
        });
        dispatch(updateLoadingSessao(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {children}
      <Modal open={hasUnansweredQuestions}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            outline: "none",
            p: 4,
          }}>
          <Typography fontSize={20} fontWeight={800}>
            Perguntas secretas
          </Typography>
          <Grid container mb={2}>
            <Typography fontSize={14} fontWeight={400}>
              Foi identificado que existem perguntas secretas ainda não
              respondidas pelo o usuário
            </Typography>
          </Grid>
          <SecretAnswers />
        </Paper>
      </Modal>
    </>
  );
};
