/** @format */

import { MenuItem, TextField } from "@mui/material";
import { getIn } from "formik";

interface SelectFieldProps {
  formik: any;
  id: string;
  label: string;
  options: { value: string | boolean | number; label: string }[];
  disabled?: boolean;
  onChange?: (event: any) => void;
}

export function SelectField({
  formik,
  id,
  label,
  options,
  disabled,
  onChange,
}: SelectFieldProps) {
  const value = getIn(formik.values, id) ?? "";
  const error = getIn(formik.errors, id);
  return (
    <TextField
      fullWidth
      id={id}
      name={id}
      disabled={disabled}
      select
      sx={{
        "& .MuiSelect-select span::before": {
          color: "gray",
          content: "'Sem informação'",
        },
      }}
      label={label}
      value={value}
      onChange={(event: any) => {
        formik.setFieldValue(id, event.target.value);
        onChange && onChange(event);
      }}
      onBlur={formik.handleBlur}
      error={Boolean(error)}
      helperText={error}>
      {options.map((option: any, index: number) => (
        <MenuItem
          key={option.value + index + option.label + id}
          value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
