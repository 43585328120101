/** @format */

import * as yup from "yup";
export const validationSchema = yup.object().shape({
  promotions_min_percent: yup
    .number()
    .required("Campo obrigatório")
    .min(0, "Minimo de 0%")
    .max(99, "Máximo de 99%"),
  promotions_max_percent: yup
    .number()
    .required("Campo obrigatório")
    .min(0, "Minimo de 1%")
    .max(99, "Máximo de 100%"),
});
