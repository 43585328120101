/** @format */

import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { FilterEmpty } from "../../../../../helpers/emptyData";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function JobRoleGroupDetails() {
  const { id } = useParams();
  const router = useNavigate();
  const dispatch = useDispatch();
  const [cboFamilies, setCboFamilies] = useState<any[]>([]);
  const [cboOcupations, setCboOcupations] = useState<any[]>([]);
  const [cboFamily, setCboFamily] = useState<any>(null);

  const formik: any = useFormik({
    initialValues: {
      description: "",
      code: "",
      cbo_family: null,
      cbo_ocupation: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(null, values, "/job_role_groups/" + id)
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/settings/jobRoleGroups");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));

    GetInstance(null, "/job_role_groups/" + id)
      .then((response: any) => {
        dispatch(updateLoading(false));
        setCboFamilies(FilterEmpty(response.data.data.cbo_families));

        formik.setValues({
          description: response.data.data.job_role_group.description,
          code: response.data.data.job_role_group.code,
          cbo_family: parseInt(response.data.data.job_role_group.cbo_family),
          cbo_ocupation: parseInt(
            response.data.data.job_role_group.cbo_ocupation
          ),
        });
        setCboFamily(parseInt(response.data.data.job_role_group.cbo_family));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cboFamily) {
      dispatch(updateLoading(true));
      GetInstance({ cbo_family_id: cboFamily }, "/cbo_occupations")
        .then((response: any) => {
          setCboOcupations(FilterEmpty(response.data.data));
          dispatch(updateLoading(false));
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cboFamily]);

  return (
    <Box>
      <Grid
        container
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Configurações" },
            {
              label: "Grupos de Cargos",
              value: "/settings/jobRoleGroups",
            },
            { label: "Detalhes" },
          ]}
        />
      </Grid>
      <Grid width={"inherit"}>
        <Paper sx={{ p: 2 }}>
          <Grid pb={2}>
            <Typography fontSize={18} fontWeight={500}>
              Criar novo grupo
            </Typography>
          </Grid>
          <Form
            formik={formik}
            cboFamilies={cboFamilies}
            cboOcupations={cboOcupations}
            setCboFamily={setCboFamily}
            setCboOcupations={setCboOcupations}
          />
        </Paper>
      </Grid>
    </Box>
  );
}
