/** @format */

export function FormatSolicitation(value: string) {
  const statusDict = {
    dismissal: "Desligamento",
    promotion: "Promoção",
    transfer: "Transferências",
    leave: "Afastamento",
    update_information: "Alteração cadastral",
    dismissal_request: "Autorização de desligamento",
    salaryReview: "Revisão salarial",
    salary_review: "Revisão salarial",
    vacation: 'Férias'
  };
  const exist = Object.entries(statusDict).filter(
    (opt: any) => opt[0] === value
  );
  return value ? (exist.length > 0 ? exist[0][1] : "Não possui") : "Não possui";
}
