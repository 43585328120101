/** @format */

import { Info } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import { optionsTable } from "../../../config/optionsTable";
import useProcess from "../../../services/protected/hooks/useProcess";
import { columns, validationSchema } from "./helpers/constants";
import { Filters } from "./helpers/filters";
import { handleDownloadButton } from "./helpers/handleDownloadButton";
import { handleResourseRoute } from "./helpers/handleResourseRoute";

export function Processes() {
  const dispatch = useDispatch();
  const router = useNavigate();

  const formik = useFormik({
    initialValues: {
      status: "approved",
      solicitation_types: "all",
      from: dayjs(new Date().setDate(new Date().getUTCDate() - 30)),
      to: dayjs(new Date()),
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      setReset(true);
    },
  });
  const {
    data,
    page,
    perPage,
    setPage,
    setOrder,
    setPerPage,
    searchCall,
    total,
    order,
    search,
    setReset,
  } = useProcess(formik.values);

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[{ label: "Inicio", value: "/" }, { label: "Processos" }]}
      />
      <Filters formik={formik} />
      <Grid container>
        <MUIDataTable
          title={""}
          data={data}
          columns={[
            {
              name: "action",
              label: "Ações",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Tooltip title={"Ver solicitação"}>
                      <IconButton
                        onClick={() =>
                          handleResourseRoute(data[dataIndex], router)
                        }>
                        <Info />
                      </IconButton>
                    </Tooltip>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            searchPlaceholder: "Pesquise por nome",
            download: true,
            onDownload: () => {
              const values: any = formik.values;
              handleDownloadButton(dispatch, {
                search,
                order_by: order,
                status: values.status,
                solicitation_types: values.solicitation_types,
                from: new Date(values.from),
                to: new Date(values.to),
              });
              return false;
            },
            onSearchChange: (value: string) => {
              searchCall(value);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
