/** @format */

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { fields } from "../../../../../config/fields";
import { FormatStatusToHistoric } from "../../../../../helpers/formatStatusToHistoric";
import { formatField } from "../helpers/formatField";

export const HistoryComponent = ({ data, options }: any) => {
  const theme = useTheme();
  const [expandedHistoricId, setExpandedHistoricId] = useState<any>(null);
  const solicitationHistories =
    data?.length &&
    data?.sort((a: any, b: any) => {
      const dateA: any = new Date(a.created_at);
      const dateB: any = new Date(b.created_at);

      return dateB - dateA;
    });
  return data?.length > 0 ? (
    <>
      <Typography fontSize={18} fontWeight={500}>
        Histórico
      </Typography>
      {solicitationHistories.map((history: any, index: number) => (
        <Accordion
          sx={{
            marginTop: 3.5,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: 1,
            "&:before": {
              display: "none",
            },
          }}
          expanded={expandedHistoricId === history?.id}
          onChange={() => setExpandedHistoricId(history?.id)}>
          <AccordionSummary
            aria-controls='panel1d-content'
            id='panel1d-header'
            expandIcon={
              <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
            }
            sx={{
              margin: 0,
              flexDirection: "row-reverse",
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "rotate(90deg)",
              },
            }}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              width='100%'>
              <Typography marginLeft={2} fontSize={18} fontWeight={500}>
                {`${history.user_name} - ${FormatStatusToHistoric(
                  history.status
                )}`}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid display='flex' flexDirection='column' gap={2}>
              <Grid container gap={"12px 96px"}>
                {Object.entries(history.new_data.payload).map(
                  ([key, value]: any) =>
                    key !== "dependents" &&
                    key !== "emergency_contacts" && (
                      <Grid>
                        <Typography fontSize={14} fontWeight={500}>
                          {fields.filter((item: any) => key === item.value)[0]
                            ?.label ?? "Campo não identificado"}
                        </Typography>
                        <Typography fontSize={12} fontWeight={400}>
                          {formatField(key, value, options)}
                        </Typography>
                      </Grid>
                    )
                )}
                {Object.entries(history.new_data.payload).map(
                  ([key, value]: any) =>
                    key === "dependents" && (
                      <Grid container flexDirection={"column"}>
                        <Divider />
                        <Typography fontSize={16} fontWeight={800} mt={2}>
                          Dados de dependentes solicitados para alteração
                        </Typography>
                        {value.map((dep: any, index: any) => (
                          <Grid container gap={1} flexDirection={"column"}>
                            <Typography fontSize={14} fontWeight={500} mt={2}>
                              Dependente {index + 1}:
                            </Typography>
                            <Grid container gap={4}>
                              {Object.entries(dep).map(
                                ([key2, value2]: any) => (
                                  <Grid>
                                    <Typography fontSize={14} fontWeight={500}>
                                      {fields.filter(
                                        (item: any) => key2 === item.value
                                      )[0]?.label ?? "Campo não identificado"}
                                    </Typography>
                                    <Typography fontSize={12} fontWeight={400}>
                                      {formatField(key2, value2, options)}
                                    </Typography>
                                  </Grid>
                                )
                              )}
                            </Grid>
                            {index + 1 < value.length && <Divider />}
                          </Grid>
                        ))}
                      </Grid>
                    )
                )}
                {Object.entries(history.new_data.payload).map(
                  ([key, value]: any) =>
                    key === "emergency_contacts" && (
                      <Grid container flexDirection={"column"}>
                        <Divider />
                        <Typography fontSize={16} fontWeight={800} mt={2}>
                          Dados de contato de emergência solicitados para
                          alteração
                        </Typography>
                        {value.map((dep: any, index: any) => (
                          <Grid container gap={1} flexDirection={"column"}>
                            <Typography fontSize={14} fontWeight={500} mt={2}>
                              Contato de emergência {index + 1}:
                            </Typography>
                            <Grid container gap={4}>
                              {Object.entries(dep).map(
                                ([key2, value2]: any) => (
                                  <Grid>
                                    <Typography fontSize={14} fontWeight={500}>
                                      {fields.filter(
                                        (item: any) => key2 === item.value
                                      )[0]?.label ?? "Campo não identificado"}
                                    </Typography>
                                    <Typography fontSize={12} fontWeight={400}>
                                      {formatField(key2, value2, options)}
                                    </Typography>
                                  </Grid>
                                )
                              )}
                            </Grid>
                            {index + 1 < value.length && <Divider />}
                          </Grid>
                        ))}
                      </Grid>
                    )
                )}
              </Grid>
              {history.observations && (
                <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2}>
                  <TextField
                    fullWidth
                    label='Justificativa'
                    variant='outlined'
                    rows={4}
                    multiline={true}
                    disabled
                    value={history.observations}
                  />
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  ) : (
    <></>
  );
};
