/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { Grid, Typography } from "@mui/material";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputRGField } from "../../../../../components/inputs/inputRGField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import {
  cnh_category_e_social,
  level_cnh_e_social,
} from "../../../../../config/constants";
import {
  HiddenCheck,
  HiddenCheckChildren,
} from "../../../../../helpers/hiddenCheck";
import useLocation from "../../../../../services/protected/hooks/useLocation";

export function Documents({ formik, data }: any) {
  const { options } = useLocation({ state: formik.values.state_of_birth });

  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid>
          <Typography fontSize={16} fontWeight={500}>
            RG - Registro geral
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputRGField
              formik={formik}
              id='general_registry_number'
              label='Número'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='general_registry_issuing_authority'
              label='Orgão de expedição'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='general_registry_federal_unit'
              label='Estado de expedição'
              labelcode
              options={options.state.map((option: any) => ({
                value: option.sigla,
                label: option.nome,
              }))}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='general_registry_date'
              label='Data de expedição'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            RIC - Registro de identidade civil
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='civil_id_number'
              label='Número'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='civil_register_issuing_authority'
              label='Orgão de expedição'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='civil_id_register_date'
              label='Data de expedição'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            RNE - Registro nacional de estrangeiros
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='national_foreigner_registry_number'
              label='Número'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='national_foreigner_registry_issuing_authority'
              label='Orgão de expedição'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='national_foreigner_registry_date'
              label='Data de expedição'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            Certificado de reservista
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='reservist_number'
              label='Número'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='reservist_date_of_issuance'
              label='Data de emissão'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            Título de eleitor
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='voter_card_number'
              label='Número'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='voter_card_zone'
              label='Zona'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='voter_card_section'
              label='Seção'
              type={"number"}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            Carteira de trabalho
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='work_permit_number'
              label='Número'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='work_permit_series'
              label='Série'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              labelcode
              id='work_permit_federal_unit'
              label='Estado de expedição'
              options={options.state.map((option: any) => ({
                value: option.sigla,
                label: option.nome,
              }))}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={14} fontWeight={400}>
            PIS/PSET/NIT - Benefícios do trabalhador
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='social_integration_program_number'
              label='Número'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='social_integration_program_date'
              label='Data'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={4}>
          <Typography fontSize={16} fontWeight={500}>
            CNH - Carteira nacional de habilitação
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='driver_license_registration_number'
              label='Número'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='driver_license_issuing_authority'
              label='Orgão de expedição'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='driver_license_date_of_issuance'
              label='Data de emissão'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='driver_license_due_date'
              label='Data de validade'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={cnh_category_e_social.map((option: any) => ({
                value: option,
                label: option,
              }))}
              formik={formik}
              id='driver_license_category'
              label='Categoria'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={level_cnh_e_social}
              formik={formik}
              id='driver_license_quantity'
              label='Nível'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              labelcode
              id='driver_license_federal_unit'
              label='Estado de expedição'
              options={options.state.map((option: any) => ({
                value: option.sigla,
                label: option.nome,
              }))}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={14} fontWeight={400}>
            OC - Orgão de classe
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='oc_number'
              label='Número'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='oc_issuing_authority'
              label='Orgão de expedição'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              labelcode
              id='oc_federal_unit'
              label='Estado de expedição'
              options={options.state.map((option: any) => ({
                value: option.sigla,
                label: option.nome,
              }))}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='oc_date_of_issuance'
              label='Data de expedição'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={14} fontWeight={400}>
            MOOP - Movimentação Operacional de Produtos Perigosos
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='mopp_number'
              label='Número'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='mopp_due_date'
              label='Data de validade'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
