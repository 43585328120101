/** @format */

import { Filter } from "../../../../components/filter";
import { AutoCompleteField } from "../../../../components/inputs/autoCompleteField";

export const Filters = ({ formik, options }: any) => {
  return (
    <Filter
      formik={formik}
      buttonLabel={"Buscar"}
      id={"type_statement"}
      keys={{
        type_statement: { label: "Demonstrativo", options: options?.type_statements ?? [] },
        month: { label: "Mês", options: options?.months ?? [] },
        year: {
          label: "Ano",
          options: options?.years ?? [],
        },
      }}>
      <AutoCompleteField
        formik={formik}
        label='Tipo de demonstrativo'
        id='type_statement'
        options={options?.type_statements ?? []}
      />
      <AutoCompleteField
        formik={formik}
        label='Mês'
        id='month'
        options={options?.months ?? []}
      />
      <AutoCompleteField
        formik={formik}
        label='Ano'
        id='year'
        options={options?.years ?? []}
      />
    </Filter>
  );
};
