/** @format */
import * as yup from "yup";

export const columns = [
  { name: "id", label: "Código" },
  { name: "target_user_name", label: "Nome" },
  { name: "days_quantity", label: "Dias solicitados" },
  { name: "start_date", label: "Data inicial" },
  { name: "end_date", label: "Data final" },
  { name: "status", label: "Status" },
];

export const validationSchema = yup.object({
  from: yup.string().nullable().required("Campo é obrigatório"),
  to: yup.string().nullable().required("Campo é obrigatório"),
});
