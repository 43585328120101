/** @format */
import * as yup from "yup";

export const validationSchema = yup.object({
  grade: yup.string().nullable().required("Campo é obrigatório"),
  description: yup.string().nullable().required("Campo é obrigatório"),
});

export const statusOptions = [
  { label: "Ativo", value: "active" },
  { label: "Inativo", value: "inactive" },
];
