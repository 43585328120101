/** @format */

import {
  Cancel,
  CheckCircle,
  Edit,
  InfoOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { PostInstante } from "../../../../services/api";

export function ChangePassword() {
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const validationSchema = yup.object({
    currentPassword: yup.string().required("Campo é obrigatório"),
    newPassword: yup
      .string()
      .required("Campo é obrigatório")
      .matches(/(?=.{8,})/, "A senha deve ter pelo menos 8 caracteres")
      .matches(
        /(?=.*?[A-Z])/,
        "A senha deve conter pelo menos uma letra maiúscula"
      )
      .matches(
        /(?=.*?[a-z])/,
        "A senha deve conter pelo menos uma letra minúscula"
      )
      .matches(/(?=.*?[0-9])/, "A senha deve conter pelo menos um número")
      .matches(
        /(?=.*?[#?!@$%^&*-])/,
        "A senha deve conter pelo menos um caractere especial"
      ),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("newPassword")],
        "Nova senha e Confirmar senha não conferem"
      )
      .required("Campo é obrigatório"),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          current_password: values.currentPassword,
          new_password: values.newPassword,
          password_confirmation: values.confirmPassword,
        },
        "/password/change_password"
      )
        .then((r: any) => {
          dispatch(updateLoading(false));
          toast.success(r.data.message);
          formik.resetForm();
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  const atLeastMinimumLength = new RegExp(/(?=.{8,})/).test(
    formik.values.newPassword
  );
  const atLeastOneUppercaseLetter = new RegExp(/(?=.*?[A-Z])/).test(
    formik.values.newPassword
  );
  const atLeastOneLowercaseLetter = new RegExp(/(?=.*?[a-z])/).test(
    formik.values.newPassword
  );
  const atLeastOneNumber = new RegExp(/(?=.*?[0-9])/).test(
    formik.values.newPassword
  );
  const atLeastOneSpecialChar = new RegExp(/(?=.*?[#?!@$ %^&*-])/).test(
    formik.values.newPassword
  );

  const isDisabled =
    Object.keys(formik.touched).length < 3 ||
    Object.keys(formik.errors).length > 0 ||
    (!atLeastMinimumLength &&
      !atLeastOneLowercaseLetter &&
      !atLeastOneNumber &&
      !atLeastOneSpecialChar &&
      !atLeastOneUppercaseLetter);

  return (
    <Grid>
      <InfoComponent
        instruction='Nesta tela é possível realizar a troca de senha seguindo os
                passos abaixo.'
      />
      <Paper sx={{ p: 3, mt: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid gap={2} container flexDirection='column'>
            <Typography fontSize={18} fontWeight={600} mb={2}>
              Alteração de senha
            </Typography>
            <TextField
              fullWidth
              id='currentPassword'
              label='Senha atual'
              variant='outlined'
              type={showCurrentPassword ? "text" : "password"}
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={formik.handleBlur}
              error={
                formik.touched.currentPassword &&
                Boolean(formik.errors.currentPassword)
              }
              helperText={
                formik.touched.currentPassword && formik.errors.currentPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      edge='end'>
                      {showCurrentPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility color='primary' />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              id='newPassword'
              label='Nova senha'
              variant='outlined'
              type={showNewPassword ? "text" : "password"}
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={formik.handleBlur}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge='end'>
                      {showNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility color='primary' />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid container flexDirection='column'>
              <Typography
                color={
                  formik.touched.newPassword && !atLeastMinimumLength
                    ? "error"
                    : "inherit"
                }
                alignItems='center'
                display='flex'
                gap={1}>
                {!formik.touched.newPassword ? (
                  <InfoOutlined color='primary' />
                ) : atLeastMinimumLength ? (
                  <CheckCircle color='success' />
                ) : (
                  <Cancel color='error' />
                )}
                Mínimo de 8 caracteres;
              </Typography>
              <Typography
                color={
                  formik.touched.newPassword && !atLeastOneUppercaseLetter
                    ? "error"
                    : "inherit"
                }
                alignItems='center'
                display='flex'
                gap={1}>
                {!formik.touched.newPassword ? (
                  <InfoOutlined color='primary' />
                ) : atLeastOneUppercaseLetter ? (
                  <CheckCircle color='success' />
                ) : (
                  <Cancel color='error' />
                )}
                mínimo de 1 letras maiúsculas;
              </Typography>
              <Typography
                color={
                  formik.touched.newPassword && !atLeastOneLowercaseLetter
                    ? "error"
                    : "inherit"
                }
                alignItems='center'
                display='flex'
                gap={1}>
                {!formik.touched.newPassword ? (
                  <InfoOutlined color='primary' />
                ) : atLeastOneLowercaseLetter ? (
                  <CheckCircle color='success' />
                ) : (
                  <Cancel color='error' />
                )}
                Mínimo de 1 letras minúsculas;
              </Typography>
              <Typography
                color={
                  formik.touched.newPassword && !atLeastOneNumber
                    ? "error"
                    : "inherit"
                }
                alignItems='center'
                display='flex'
                gap={1}>
                {!formik.touched.newPassword ? (
                  <InfoOutlined color='primary' />
                ) : atLeastOneNumber ? (
                  <CheckCircle color='success' />
                ) : (
                  <Cancel color='error' />
                )}
                Mínimo de 1 número;
              </Typography>
              <Typography
                color={
                  formik.touched.newPassword && !atLeastOneSpecialChar
                    ? "error"
                    : "inherit"
                }
                alignItems='center'
                display='flex'
                gap={1}>
                {!formik.touched.newPassword ? (
                  <InfoOutlined color='primary' />
                ) : atLeastOneSpecialChar ? (
                  <CheckCircle color='success' />
                ) : (
                  <Cancel color='error' />
                )}
                Mínimo de 1 caractere especial;
              </Typography>
            </Grid>
            <TextField
              fullWidth
              id='confirmPassword'
              label='Confirmar senha'
              variant='outlined'
              type={showConfirmPassword ? "text" : "password"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onFocus={formik.handleBlur}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge='end'>
                      {showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility color='primary' />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                type='submit'
                endIcon={<Edit />}
                disabled={isDisabled}
                variant='contained'>
                Atualizar senha
              </ButtonComponent>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}
