import { Grid, TextField } from "@mui/material";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";

interface Position {
  code: string;
  job_role: string;
}
interface Props {
  formik: any;
  position: Position;
  options: any;
}

export function Form({ formik, position, options }: Props) {
  return (
    <form>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        {Object.keys(position).length > 0 ? (
          <TextField
            id="position_id"
            fullWidth
            label="Posição"
            value={`${position.code} ${position.job_role}`}
            onBlur={formik.handleBlur}
            error={
              formik.touched.position_id && Boolean(formik.errors.position_id)
            }
            helperText={formik.touched.position_id && formik.errors.position_id}
          />
        ) : (
          <AutoCompleteField
            formik={formik}
            onChange={(event,newValue) => {
              const value: any = options.job_role_group.find(
                (p: any) => p.id === newValue?.value
              );
              formik.setFieldValue("job_role_id", value.job_role_id);
              formik.setFieldValue("salary", value.salary);
            }}
            label="Posição"
            id="position_id"
            options={options?.job_role_group.map((item: any) => ({
              value: item.id,
              label: `${item.code} - ${item.job_role}`,
            }))}
          />
        )}
        <AutoCompleteField
          formik={formik}
          id="job_role_id"
          label="Cargo"
          options={options?.job_role}
        />
        <InputDateField
          formik={formik}
          id="initial_date"
          label="Data prevista de início"
        />
        <InputCurrencyField formik={formik} id="salary" label="Remuneração" />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <InputTextField
          formik={formik}
          id="observations"
          label="Responsabilidades"
          multiline={true}
          rows={4}
        />
      </Grid>
    </form>
  );
}
