/** @format */

import { Audit } from "../pages/protected/audit";
import DataPulsePage from "../pages/protected/datapulse";
import { Delegation } from "../pages/protected/delegation";
import { DelegationDetails } from "../pages/protected/delegation/details";
import { DelegationNew } from "../pages/protected/delegation/new";
import { Active } from "../pages/protected/employee/active";
import { Import } from "../pages/protected/employee/import";
import { ImportNew } from "../pages/protected/employee/import/new";
import { Inactive } from "../pages/protected/employee/inactive";
import { LeaveEmployee } from "../pages/protected/employee/leave";
import { EmployeeNew } from "../pages/protected/employee/new";
import { Pending } from "../pages/protected/employee/pending";
import { Position } from "../pages/protected/employee/position";
import { FilesAccount } from "../pages/protected/filesAccount";
import { FilesAccountUpload } from "../pages/protected/filesAccount/upload";
import { Home } from "../pages/protected/home";
import { Notifications } from "../pages/protected/notifications";
import { Organizational } from "../pages/protected/organizational";
import { Processes } from "../pages/protected/processes";
import { ReportEmployee } from "../pages/protected/reports/employee";
import { DismissalRequests } from "../pages/protected/request/dismissalRequests";
import { DismissalRequestsDetails } from "../pages/protected/request/dismissalRequests/details";
import { DismissalRequestsNew } from "../pages/protected/request/dismissalRequests/new";
import { Dismissals } from "../pages/protected/request/dismissals";
import { DismissalsDetails } from "../pages/protected/request/dismissals/details";
import { DismissalsNew } from "../pages/protected/request/dismissals/new";
import { Leave } from "../pages/protected/request/leave";
import { LeaveDetails } from "../pages/protected/request/leave/details";
import { LeaveNew } from "../pages/protected/request/leave/new";
import { Personal } from "../pages/protected/request/personal";
import { PersonalDetails } from "../pages/protected/request/personal/details";
import { PersonalNew } from "../pages/protected/request/personal/new";
import { PositionRequest } from "../pages/protected/request/position";
import { PositionDetails } from "../pages/protected/request/position/details";
import { PositionNew } from "../pages/protected/request/position/new";
import { Promote } from "../pages/protected/request/promote";
import { PromoteDetails } from "../pages/protected/request/promote/details";
import { PromoteLetter } from "../pages/protected/request/promote/letter";
import { PromoteNew } from "../pages/protected/request/promote/new";
import { ReviewSalary } from "../pages/protected/request/reviewSalary";
import { SalaryReviewDetails } from "../pages/protected/request/reviewSalary/details";
import { SalaryReviewLetter } from "../pages/protected/request/reviewSalary/letter";
import { SalaryReviewNew } from "../pages/protected/request/reviewSalary/new";
import { Transfer } from "../pages/protected/request/transfer";
import { TransferDetails } from "../pages/protected/request/transfer/details";
import { TransferLetter } from "../pages/protected/request/transfer/letter";
import { TransferNew } from "../pages/protected/request/transfer/new";
import { UpdateInformation } from "../pages/protected/request/updateInformation";
import { UpdateInformationDetails } from "../pages/protected/request/updateInformation/details";
import { UpdateNew } from "../pages/protected/request/updateInformation/request";
import { Vacation } from "../pages/protected/request/vacation";
import { VacationDetails } from "../pages/protected/request/vacation/details";
import { VacationNew } from "../pages/protected/request/vacation/new";
import { PermissionsAudit } from "../pages/protected/settings/audit";
import { Calendar } from "../pages/protected/settings/calendar";
import { PermissionsDismissals } from "../pages/protected/settings/dismissals";
import { PermissionsForgetPassword } from "../pages/protected/settings/forgetPassword";
import { GroupPermission } from "../pages/protected/settings/group";
import { PermissionGroupDetails } from "../pages/protected/settings/group/details";
import { PermissionGroupNew } from "../pages/protected/settings/group/new";
import { JobRoleGroups } from "../pages/protected/settings/jobRoleGroups";
import { JobRoleGroupDetails } from "../pages/protected/settings/jobRoleGroups/details";
import { JobRoleGroupNew } from "../pages/protected/settings/jobRoleGroups/new";
import { JobRoles } from "../pages/protected/settings/jobRoles";
import { JobRoleDetails } from "../pages/protected/settings/jobRoles/details";
import { JobRoleNew } from "../pages/protected/settings/jobRoles/new";
import { PermissionsLeave } from "../pages/protected/settings/leave";
import { LetterRequest } from "../pages/protected/settings/letter";
import { LetterDetails } from "../pages/protected/settings/letter/details";
import { LetterNew } from "../pages/protected/settings/letter/new";
import { PermissionsPersonnel } from "../pages/protected/settings/personnel";
import { PermissionsPosition } from "../pages/protected/settings/position";
import { PermissionsPromote } from "../pages/protected/settings/promote";
import { PermissionssalaryReview } from "../pages/protected/settings/salaryReview";
import { GradePage } from "../pages/protected/settings/tableSalary";
import { PermissionsTransfer } from "../pages/protected/settings/transfer";
import { PermissionsUpdateInformation } from "../pages/protected/settings/updateInformation";
import { UsersRole } from "../pages/protected/settings/usersRole";
import { PermissionsVacation } from "../pages/protected/settings/vacation";
import { WorkflowPage } from "../pages/protected/settings/workflow";
import { WorkSchedule } from "../pages/protected/settings/workSchedule";
import { WorkScheduleDetails } from "../pages/protected/settings/workSchedule/details";
import { WorkScheduleNew } from "../pages/protected/settings/workSchedule/new";
import { SettingsAccount } from "../pages/protected/settingsAccount";
import { TableSalaryDashboardPage } from "../pages/protected/tableSalary";
import { Grades } from "../pages/protected/tableSalary/grades";
import { Reference } from "../pages/protected/tableSalary/reference";
import { DashboardVacation } from "../pages/protected/vacation/dashboard";
import { VacationTime } from "../pages/protected/vacation/time";
import { VacationDetailsTime } from "../pages/protected/vacation/time/details";
import { VacationNewTime } from "../pages/protected/vacation/time/new";
import { WorkflowDashboardPage } from "../pages/protected/workflow";
import { WorkflowDetails } from "../pages/protected/workflow/details";
import { WorkflowNew } from "../pages/protected/workflow/new";
import { CompanyOptionsPage } from "../pages/unprotected/companyOptions";
import { CreatePasswordPage } from "../pages/unprotected/createPassword";
import { ForgetPasswordEmailPage } from "../pages/unprotected/forgetPasswordEmail";
import { ForgetPasswordSecretQuestions } from "../pages/unprotected/forgetPasswordSecretQuestions";
import { ForgetPasswordStandardPage } from "../pages/unprotected/forgetPasswordStandard";
import { LoginPage } from "../pages/unprotected/login";
import { MethodForgetPasswordPage } from "../pages/unprotected/methodForgetPassword";
import NotMobilePage from "../pages/unprotected/notMobile";
import NotPermissionPage from "../pages/unprotected/notPermission";
import SuccessPasswordPage from "../pages/unprotected/successPassword";

export const routers: {
  id: string;
  path: string;
  label: string;
  component?: any;
  protected?: boolean;
  mobile?: boolean;
}[] = [
    {
      id: "login",
      path: "/login",
      label: "Login",
      component: <LoginPage />,
    },
    {
      id: "methodForgetPassword",
      path: "/methodForgetPassword",
      label: "Metodos de recuperação de senha",
      component: <MethodForgetPasswordPage />,
    },
    {
      id: "forgetPasswordSendEmail",
      path: "/forgetPasswordSendEmail",
      label: "Esqueci minha senha por email",
      component: <ForgetPasswordEmailPage />,
    },
    {
      id: "forgetPasswordStandard",
      path: "/forgetPasswordStandard",
      label: "Esqueci minha senha padrão",
      component: <ForgetPasswordStandardPage />,
    },
    {
      id: "forgetPasswordSecretQuestions",
      path: "/forgetPasswordSecretQuestions",
      label: "Esqueci minha senha por perguntas secretas",
      component: <ForgetPasswordSecretQuestions />,
    },
    {
      id: "createPassword",
      path: "/createPassword",
      label: "Criar senha",
      component: <CreatePasswordPage />,
    },
    {
      id: "successPassword",
      path: "/successPassword",
      label: "Successo ao criar senha",
      component: <SuccessPasswordPage />,
    },
    {
      id: "notMobile",
      path: "/notMobile",
      label: "Não funciona no dispositivo mobile",
      component: <NotMobilePage />,
    },
    {
      id: "notPermission",
      path: "/notPermission",
      label: "Sem permissão",
      component: <NotPermissionPage />,
    },
    {
      id: "companyOptions",
      path: "/companyOptions",
      label: "Alterar empresa",
      component: <CompanyOptionsPage />,
    },
    {
      id: "home",
      path: "/",
      label: "Inicio",
      component: <Home />,
      protected: true,
    },
    {
      id: "dataPulse",
      path: "/datapulse",
      label: "Data Pulse",
      component: <DataPulsePage />,
      protected: true,
    },
    {
      id: "organizational",
      path: "/organizational",
      label: "Organograma",
      component: <Organizational />,
      protected: true,
    },
    {
      id: "settingsAccount",
      path: "/settingsAccount",
      label: "Ver configurações da conta",
      component: <SettingsAccount />,
      protected: true,
    },
    {
      id: "filesAccount",
      path: "/filesAccount",
      label: "Ver demonstrativos da conta",
      component: <FilesAccount />,
      protected: true,
    },
    {
      id: "filesAccountUpload",
      path: "/filesUpload/:id",
      label: "Upload de demonstrativos por usuário",
      component: <FilesAccountUpload />,
      protected: true,
    },
    {
      id: "notification",
      path: "/notification",
      label: "Notificações",
      component: <Notifications />,
      protected: true,
    },
    {
      id: "audit",
      path: "/audit",
      label: "Auditoria",
      component: <Audit />,
      protected: true,
    },

    {
      id: "vacationTimeDashboard",
      path: "/vacation/dashboard",
      label: "Férias",
      component: <DashboardVacation />,
      protected: true,
    },
    {
      id: "vacationCollaborator",
      path: "/vacation/time",
      label: "Ver periodos de férias",
      component: <VacationTime />,
      protected: true,
    },
    {
      id: "vacationCollaboratorNew",
      path: "/vacation/new/:id",
      label: "Criar novo periodo de férias",
      component: <VacationNewTime />,
      protected: true,
    },
    {
      id: "vacationCollaboratorDetails",
      path: "/vacation/details/:id",
      label: "Ver detalhes do periodo de férias",
      component: <VacationDetailsTime />,
      protected: true,
    },
    {
      id: "employeeActive",
      path: "/employee/active",
      label: "Ver colaboradores ativos",
      component: <Active />,
      protected: true,
    },
    {
      id: "employeeInactive",
      path: "/employee/inactive",
      label: "Ver colaboradores desligados",
      component: <Inactive />,
      protected: true,
    },
    {
      id: "employeeLeave",
      path: "/employee/leave",
      label: "Ver colaboradores afastados",
      component: <LeaveEmployee />,
      protected: true,
    },
    {
      id: "employeeNew",
      path: "/employee/pending/new",
      label: "Criar novo colaborador",
      component: <EmployeeNew />,
      protected: true,
    },
    {
      id: "employeePosition",
      path: "/position",
      label: "Ver posições para colaborador",
      component: <Position />,
      protected: true,
    },
    {
      id: "employeePending",
      path: "/employee/pending",
      label: "Ver usuários pendentes",
      component: <Pending />,
      protected: true,
    },
    {
      id: "employeeImport",
      path: "/employee/import",
      label: "Ver importação de usuários",
      component: <Import />,
      protected: true,
    },
    {
      id: "employeeImportNew",
      path: "/employee/import/new",
      label: "Importar usuários",
      component: <ImportNew />,
      protected: true,
    },
    {
      id: "reportEmployee",
      path: "/report/employee",
      label: "Relatório de colaboradores",
      component: <ReportEmployee />,
      protected: true,
    },
    {
      id: "processes",
      path: "/processes",
      label: "Ver processos",
      component: <Processes />,
      protected: true,
    },
    {
      id: "delegation",
      path: "/delegation",
      label: "Ver delegações",
      component: <Delegation />,
      protected: true,
    },
    {
      id: "delegationNew",
      path: "/delegation/new",
      label: "Criar nova delegações",
      component: <DelegationNew />,
      protected: true,
    },
    {
      id: "delegationDetails",
      path: "/delegation/details/:id",
      label: "Ver detalhes da solicitação de delegação",
      component: <DelegationDetails />,
      protected: true,
    },
    {
      id: "requestLeave",
      path: "/request/leave",
      label: "Ver detalhes da solicitação de afastamento",
      component: <Leave />,
      protected: true,
    },
    {
      id: "requestLeaveNew",
      path: "/request/leave/new/:id",
      label: "Criar nova solicitação de afastamento",
      component: <LeaveNew />,
      protected: true,
    },
    {
      id: "requestLeaveDetails",
      path: "/request/leave/details/:id",
      label: "Ver detalhes da solicitação de afastamento",
      component: <LeaveDetails />,
      protected: true,
    },
    {
      id: "requestDismissals",
      path: "/request/dismissals",
      label: "Ver solicitações de desligamento",
      component: <Dismissals />,
      protected: true,
    },
    {
      id: "requestDismissalsNew",
      path: "/request/dismissals/new/:id",
      label: "Criar nova solicitação de desligamento",
      component: <DismissalsNew />,
      protected: true,
    },
    {
      id: "requestDismissalsDetails",
      path: "/request/dismissals/details/:id",
      label: "Ver detalhes da solicitação de desligamento",
      component: <DismissalsDetails />,
      protected: true,
    },
    {
      id: "requestDismissalRequests",
      path: "/request/dismissalRequests",
      label: "Ver solicitações de aprovação de desligamento",
      component: <DismissalRequests />,
      protected: true,
    },
    {
      id: "requestDismissalRequestsNew",
      path: "/request/dismissalRequests/new/:id",
      label: "Criar nova solicitação de aprovação de desligamento",
      component: <DismissalRequestsNew />,
      protected: true,
    },
    {
      id: "requestDismissalRequestsDetails",
      path: "/request/dismissalRequests/details/:id",
      label: "Ver detalhes da solicitação de aprovação de desligamento",
      component: <DismissalRequestsDetails />,
      protected: true,
    },
    {
      id: "requestPosition",
      path: "/request/position",
      label: "Ver solicitações de posição",
      component: <PositionRequest />,
      protected: true,
    },
    {
      id: "requestPositionNew",
      path: "/request/position/new",
      label: "Criar nova solicitação de posição",
      component: <PositionNew />,
      protected: true,
    },
    {
      id: "requestPositionDetails",
      path: "/request/position/details/:id",
      label: "Ver detalhes da solicitação de posição",
      component: <PositionDetails />,
      protected: true,
    },
    {
      id: "requestPromote",
      path: "/request/promote",
      label: "Ver solicitações de promoção",
      component: <Promote />,
      protected: true,
    },
    {
      id: "requestPromoteNew",
      path: "/request/promote/new/:id",
      label: "Criar nova solicitação de promoção",
      component: <PromoteNew />,
      protected: true,
    },
    {
      id: "requestPromoteDetails",
      path: "/request/promote/details/:id",
      label: "Ver detalhes da solicitação de promoção",
      component: <PromoteDetails />,
      protected: true,
    },
    {
      id: "requestPromoteDetailsView",
      path: "/request/promote/details/letter/:id",
      label: "Ver carta de comunicação de solicitação de promoção",
      component: <PromoteLetter />,
      protected: true,
    },
    {
      id: "requestSalaryReview",
      path: "/request/salaryReview",
      label: "Ver solicitações de revisão salarial",
      component: <ReviewSalary />,
      protected: true,
    },
    {
      id: "requestSalaryReviewNew",
      path: "/request/salaryReview/new/:id",
      label: "Criar nova solicitação de revisão salarial",
      component: <SalaryReviewNew />,
      protected: true,
    },
    {
      id: "requestSalaryReviewDetails",
      path: "/request/salaryReview/details/:id",
      label: "Ver detalhes da solicitação de revisão salarial",
      component: <SalaryReviewDetails />,
      protected: true,
    },
    {
      id: "requestSalaryReviewDetailsView",
      path: "/request/salaryReview/details/letter/:id",
      label: "Ver carta de comunicação de solicitação de revisão salarial",
      component: <SalaryReviewLetter />,
      protected: true,
    },
    {
      id: "requestTransfer",
      path: "/request/transfer",
      label: "Ver solicitações de transferência",
      component: <Transfer />,
      protected: true,
    },
    {
      id: "requestTransferNew",
      path: "/request/transfer/new/:id",
      label: "Criar nova solicitação de transferência",
      component: <TransferNew />,
      protected: true,
    },
    {
      id: "requestTransferDetails",
      path: "/request/transfer/details/:id",
      label: "Ver detalhes da solicitação de transferência",
      component: <TransferDetails />,
      protected: true,
    },
    {
      id: "requestTransferDetailsView",
      path: "/request/transfer/details/letter/:id",
      label: "Ver carta de comunicação de solicitação de transferência",
      component: <TransferLetter />,
      protected: true,
    },
    {
      id: "requestUpdateInformation",
      path: "/request/updateInformation",
      label: "Ver solicitações de alteração cadastral",
      component: <UpdateInformation />,
      protected: true,
    },
    {
      id: "requestUpdateInformationRequest",
      path: "/request/updateInformation/request/:id",
      label: "Ver informações de alteração cadastral",
      component: <UpdateNew />,
      protected: true,
    },
    {
      id: "requestUpdateInformationDetails",
      path: "/request/updateInformation/details/:id",
      label: "Ver detalhes da solicitação de alteração cadastral",
      component: <UpdateInformationDetails />,
      protected: true,
    },
    {
      id: "requestPersonal",
      path: "/request/personal",
      label: "Ver solicitações de requisição de pessoal",
      component: <Personal />,
      protected: true,
    },
    {
      id: "requestPersonalNew",
      path: "/request/personal/new",
      label: "Criar nova solicitação de requisição de pessoal",
      component: <PersonalNew />,
      protected: true,
    },
    {
      id: "requestPersonalDetails",
      path: "/request/personal/details/:id",
      label: "Ver detalhes da solicitação de requisição de pessoal",
      component: <PersonalDetails />,
      protected: true,
    },
    {
      id: "requestVacation",
      path: "/request/vacation",
      label: "Ver solicitações de férias",
      component: <Vacation />,
      protected: true,
    },
    {
      id: "requestVacationNew",
      path: "/request/vacation/new/:id",
      label: "Criar nova solicitação de férias",
      component: <VacationNew />,
      protected: true,
    },
    {
      id: "requestVacationDetails",
      path: "/request/vacation/details/:id",
      label: "Ver detalhes da solicitação de férias",
      component: <VacationDetails />,
      protected: true,
    },
    {
      id: "permissionGroup",
      path: "/settings/group",
      label: "Ver Configurações por grupo",
      component: <GroupPermission />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionGroupNew",
      path: "/settings/group/new",
      label: "Criar permissão de grupo",
      component: <PermissionGroupNew />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionGroupDetails",
      path: "/settings/group/details/:id",
      label: "Ver detalhes de permissão de grupo",
      component: <PermissionGroupDetails />,
      protected: true,
      mobile: true,
    },
    {
      id: "usersRole",
      path: "/settings/usersRole",
      label: "Modificar perfis de usuários",
      component: <UsersRole />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionLetter",
      path: "/settings/letter",
      label: "Ver modelos de carta",
      component: <LetterRequest />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionLetterNew",
      path: "/settings/letter/new",
      label: "Criar modelo de carta",
      component: <LetterNew />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionLetterDetails",
      path: "/settings/letter/details/:id",
      label: "Detalhes modelo de carta",
      component: <LetterDetails />,
      protected: true,
      mobile: true,
    },

    {
      id: "permissionsRequiredUpdateInformation",
      path: "/settings/updateInformation",
      label: "Ver usuários com permissão para alteração cadastral",
      component: <PermissionsUpdateInformation />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsRequiredPersonnel",
      path: "/settings/personnel",
      label: "Editar usuários com permissão para requisição de pessoal",
      component: <PermissionsPersonnel />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsRequiredLeave",
      path: "/settings/leave",
      label: "Editar usuários com permissão para afastamento",
      component: <PermissionsLeave />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsRequiredDismissals",
      path: "/settings/dismissals",
      label: "Editar usuários com permissão para desligamento",
      component: <PermissionsDismissals />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsRequiredPromote",
      path: "/settings/promote",
      label: "Editar usuários com permissão para promoção",
      component: <PermissionsPromote />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsRequiredSalaryReview",
      path: "/settings/salaryReview",
      label: "Editar usuários com permissão para revisão salarial",
      component: <PermissionssalaryReview />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsRequiredTransfer",
      path: "/settings/transfer",
      label: "Editar usuários com permissão para transferência",
      component: <PermissionsTransfer />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsRequiredForgetPassword",
      path: "/settings/forgetPassword",
      label: "Alterar como pode ser feito a recuperação de senha",
      component: <PermissionsForgetPassword />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsRequiredPosition",
      path: "/settings/position",
      label: "Editar usuários com permissão para posição",
      component: <PermissionsPosition />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsRequiredVacation",
      path: "/settings/vacation",
      label: "Editar usuários com permissão para férias",
      component: <PermissionsVacation />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsAudit",
      path: "/settings/audit",
      label: "Configurações de auditória",
      component: <PermissionsAudit />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsGrade",
      path: "/settings/tableSalary",
      label: "Configurações de tabela salarial",
      component: <GradePage />,
      protected: true,
      mobile: true,
    },
    {
      id: "permissionsWorkflow",
      path: "/settings/workflow",
      label: "Configurações de workflow",
      component: <WorkflowPage />,
      protected: true,
      mobile: true,
    },
    {
      id: "calendar",
      path: "/settings/calendar",
      label: "Criar e editar o calendario de abertura de solicitações",
      component: <Calendar />,
      protected: true,
    },

    {
      id: "jobRoles",
      path: "/settings/jobRoles",
      label: "Criar e editar cargos",
      component: <JobRoles />,
      protected: true,
    },
    {
      id: "jobRoleNew",
      path: "/settings/jobRoles/new",
      label: "Criar novo cargo",
      component: <JobRoleNew />,
      protected: true,
    },
    {
      id: "jobRoleDetails",
      path: "/settings/jobRoles/details/:id",
      label: "Ver detalhes do cargo",
      component: <JobRoleDetails />,
      protected: true,
    },
    {
      id: "jobRoleGroups",
      path: "/settings/jobRoleGroups",
      label: "Criar e editar grupos de cargos",
      component: <JobRoleGroups />,
      protected: true,
    },
    {
      id: "jobRoleGroupNew",
      path: "/settings/jobRoleGroups/new",
      label: "Criar novo grupo de cargos",
      component: <JobRoleGroupNew />,
      protected: true,
    },
    {
      id: "jobRoleGroupDetails",
      path: "/settings/jobRoleGroups/details/:id",
      label: "Ver detalhes do grupo de cargos",
      component: <JobRoleGroupDetails />,
      protected: true,
    },
    {
      id: "workflow",
      path: "/workflow",
      label: "Ver workflows",
      component: <WorkflowDashboardPage />,
      protected: true,
    },
    {
      id: "workflowNew",
      path: "/workflow/new",
      label: "Novo workflows",
      component: <WorkflowNew />,
      protected: true,
    },
    {
      id: "workflowDetails",
      path: "/workflow/details/:id",
      label: "Detalhes workflows",
      component: <WorkflowDetails />,
      protected: true,
    },
    {
      id: "tableSalaryDashboard",
      path: "/tableSalary/dashboard",
      label: "Ver tabela salarial completa",
      component: <TableSalaryDashboardPage />,
      protected: true,
    },
    {
      id: "reference",
      path: "/tableSalary/reference",
      label: "Criar e editar os nível referências",
      component: <Reference />,
      protected: true,
    },
    {
      id: "grades",
      path: "/tableSalary/grades",
      label: "Criar e editar os nível funcional/Grades",
      component: <Grades />,
      protected: true,
    },
    {
      id: "workSchedule",
      path: "/settings/workSchedule",
      label: "Ver jornadas de trabalho",
      component: <WorkSchedule />,
      protected: true,
    },
    {
      id: "workScheduleNew",
      path: "/settings/workSchedule/new",
      label: "Criar nova jornada de trabalho",
      component: <WorkScheduleNew />,
      protected: true,
    },
    {
      id: "workScheduleDetails",
      path: "/settings/workSchedule/details/:id",
      label: "Ver detalhes da jornada de trabalho",
      component: <WorkScheduleDetails />,
      protected: true,
    },
  ];
