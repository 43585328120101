/** @format */

import { toast } from "react-toastify";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { PutInstante } from "../../../../../services/api";

export function SetActive(dispatch: any, formik: any, data: any, setData: any) {
  formik.validateForm();
  if (Object.keys(formik.errors).length > 0) {
    toast.info("Por favor revise o formulário, existe falha nos dados abaixo:");
    if (Object.keys(formik.errors).indexOf("dependents") !== -1) {
      toast.error("Dependentes com dados inválidos");
    }
    if (Object.keys(formik.errors).indexOf("emergency_contacts") !== -1) {
      toast.error("Contatos de emergência com dados inválidos");
    }
    Object.values(formik?.errors ?? {}).map((item: any) => toast.error(item));
    return;
  }
  dispatch(updateLoading(true));
  PutInstante(formik.values.uuid, { status: "active" }, "/users")
    .then((response: any) => {
      dispatch(updateLoading(false));
      data.user_data.status = "active";
      setData(data);
      toast.success(response?.data?.message);
    })
    .catch((e: any) => {
      GenericError(e, dispatch);
    });
}
