/** @format */

import * as yup from "yup";

export const validationSchema = yup.object({
  solicitation_type: yup
    .string()
    .nullable()
    .required("Campo é obrigatório"),
  workflow_steps: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Campo é obrigatório"),
      step_type: yup.string().required("Campo é obrigatório"),
      position_id: yup.string().when('step_type', {
        is: (val: any) => val && val === 'position',
        then: () => yup.string().required("Campo é obrigatório"),
        otherwise: () => yup.string().notRequired(),
      })
    })
  ),
});

export const columns = [
  { name: "id", label: "Código" },
  { name: "solicitation_type", label: "Tipo de solicitação" },
  { name: "updated_at", label: "Última atualização" },
];

export const workflowOptions: { label: string; value: string }[] = [
  { label: "Hierarquia", value: "hierarchy" },
  { label: "Posição", value: "position" },
];
