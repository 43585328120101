/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { AutoCompleteFieldMultiple } from "../../../../../components/inputs/autoCompleteFieldMultiple";
import { InputCPFField } from "../../../../../components/inputs/inputCPFField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputPhoneField } from "../../../../../components/inputs/inputPhoneField";
import { InputRGField } from "../../../../../components/inputs/inputRGField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import {
  aviso_e_social,
  banks_e_social,
  country_e_social,
  education_level_e_social,
  gender_e_social,
  marital_status_e_social,
  race_e_social,
  type_of_account,
  type_of_disability,
} from "../../../../../config/constants";
import { handleCEPChange } from "../../../../../helpers/handleCEPChange";
import {
  HiddenCheck,
  HiddenCheckChildren,
} from "../../../../../helpers/hiddenCheck";
import useLocation from "../../../../../services/protected/hooks/useLocation";
import { useSelector } from "react-redux";

export function Personal({ formik, data }: any) {
  const { options } = useLocation({ state: formik.values.state_of_birth });
  const userState = useSelector((state: any) => state.user.data);

  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid>
          <Typography fontSize={16} fontWeight={500}>
            Perfil
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 2fr 2fr 2fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              disabled={userState?.role !== "rh"}
              label={"Matrícula"}
              id={"identification"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              disabled={userState?.role !== "rh"}
              label='Matrícula eSocial'
              id={"matricula_esocial"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              disabled
              label='Username'
              id={"username"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='email'
              label='Email corporativo'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputPhoneField
              formik={formik}
              id='corporate_phone'
              label='Telefone corporativo'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Dados pessoais
          </Typography>
        </Grid>
        <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField formik={formik} id='name' label='Nome' />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='social_name'
              label='Nome social'
            />
          </HiddenCheck>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputCPFField formik={formik} id='cpf' label='CPF' />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputRGField formik={formik} id='rg' label='RG' />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='birth_date'
              label='Data de nascimento'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='marital_status'
              label='Estado civil'
              options={marital_status_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='race'
              label='Raça'
              options={race_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='education_level'
              label='Grau de instrução'
              options={education_level_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              id='gender'
              label='Sexo'
              options={gender_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              id='is_retired'
              label='Aposentado'
              options={aviso_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              labelcode={true}
              id='nationality'
              label='Nacionalidade'
              options={country_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputPhoneField formik={formik} id='phone' label='Telefone' />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='personal_email'
              label='Email pessoal'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2} id={"criminal_record"}>
          <Typography fontSize={14} fontWeight={400}>
            Antecedentes criminais
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              id='criminal_record'
              label='Antec. criminais'
              options={aviso_e_social}
              onChange={() => {
                formik.setFieldValue("court_license", "");
                formik.setFieldValue("process", "");
              }}
            />
          </HiddenCheck>
          {formik.values.criminal_record === true && (
            <>
              <HiddenCheck
                user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputTextField
                  formik={formik}
                  id='court_license'
                  label='Alvará judicial'
                />
              </HiddenCheck>
              <HiddenCheck
                user_form_hidden_fields={data.user_form_hidden_fields}>
                <InputTextField
                  formik={formik}
                  id='process'
                  label='Processo'
                  type={"number"}
                />
              </HiddenCheck>
            </>
          )}
        </Grid>
      </HiddenCheck>
      <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2} id={"is_disability"}>
          <Typography fontSize={14} fontWeight={400}>
            Pessoa com deficiência
          </Typography>
        </Grid>
        <Grid gridTemplateColumns={"1fr 2fr"} mt={2} display={"grid"} gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              id='is_disability'
              label='PCD'
              options={aviso_e_social}
              onChange={() => {
                formik.setFieldValue("type_of_disability", []);
              }}
            />
          </HiddenCheck>
          {formik.values.is_disability === true && (
            <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
              <AutoCompleteFieldMultiple
                options={type_of_disability}
                formik={formik}
                id='type_of_disability'
                label='Tipo do PCD'
              />
            </HiddenCheck>
          )}
        </Grid>
      </HiddenCheck>
      <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2} id={"address"}>
          <Typography fontSize={16} fontWeight={500}>
            Endereço
          </Typography>
        </Grid>
        <Grid gridTemplateColumns={"1fr 2fr "} mt={2} display={"grid"} gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <TextField
              fullWidth
              id='postal_code'
              placeholder={"00.000-000"}
              label='CEP'
              value={formik.values.postal_code}
              onChange={(event: any) => {
                formik.setFieldValue("postal_code", handleCEPChange(event));
                if (event.target.value.replace(/\D/g, "").length === 8) {
                  axios
                    .get(
                      `https://viacep.com.br/ws/${event.target.value.replace(
                        /\D/g,
                        ""
                      )}/json/`
                    )
                    .then((response) => {
                      formik.setFieldValue("street", response.data.logradouro);
                      formik.setFieldValue("city", response.data.localidade);
                      formik.setFieldValue(
                        "neighborhood",
                        response.data.bairro
                      );
                      formik.setFieldValue("state", response.data.uf);
                      formik.setFieldTouched("street");
                      formik.setFieldTouched("city");
                      formik.setFieldTouched("neighborhood");
                      formik.setFieldTouched("state");
                    })
                    .catch(() => {
                      toast.error(
                        "CEP não encontrado. Verifique o CEP digitado."
                      );
                    });
                } else {
                  formik.setFieldValue("street", "");
                  formik.setFieldValue("city", "");
                  formik.setFieldValue("neighborhood", "");
                  formik.setFieldValue("state", "");
                  formik.setFieldTouched("street");
                  formik.setFieldTouched("city");
                  formik.setFieldTouched("neighborhood");
                  formik.setFieldTouched("state");
                }
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
              helperText={formik.touched.zip_code && formik.errors.zip_code}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              disabled
              id='street'
              label='Logradouro'
            />
          </HiddenCheck>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 2fr 2fr 2fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='number'
              type={"number"}
              label='Número'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='complement'
              label='Complemento'
            />
          </HiddenCheck>
          <InputTextField
            formik={formik}
            disabled
            id='neighborhood'
            label='Bairro'
          />
          <InputTextField formik={formik} disabled id='city' label='Cidade' />
          <InputTextField formik={formik} disabled id='state' label='UF' />
        </Grid>
      </HiddenCheck>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Dados de nascimento
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              labelcode
              options={country_e_social}
              formik={formik}
              id='country'
              label='País'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='state_of_birth'
              label='Estado'
              labelcode={true}
              options={options.state.map((option: any) => ({
                value: option.sigla,
                label: option.nome,
              }))}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='city_of_birth'
              label='Cidade'
              disabled={
                formik.values.state_of_birth
                  ? formik.values.state_of_birth.length < 1
                  : true
              }
              options={options.city.map((option: any) => ({
                value: option.value,
                label: option.nome,
              }))}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Filiação
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='father_name'
              label='Nome do pai'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputCPFField formik={formik} id='father_cpf' label='CPF do pai' />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='father_country_of_birth'
              label='País de nascimento do pai'
              labelcode={true}
              options={country_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='paternal_grandmother_name'
              label='Avó paterna'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='paternal_grandfather_name'
              label='Avô paterno'
            />
          </HiddenCheck>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='mother_name'
              label='Nome da mãe'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputCPFField formik={formik} id='mother_cpf' label='CPF da mãe' />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='mother_country_of_birth'
              label='País de nascimento da mãe'
              labelcode={true}
              options={country_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='maternal_grandmother_name'
              label='Avó materna'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='maternal_grandfather_name'
              label='Avô materna'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Naturalização
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              options={aviso_e_social}
              formik={formik}
              id='is_naturalized'
              label='Naturalizado'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              label='Data de chegada ao Brasil'
              id='arrival_in_brazil_date'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              label='Data de naturalização'
              id='naturalization_date'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              options={aviso_e_social}
              formik={formik}
              id='married_to_a_brazilian'
              label='Casado com Brasileiro(a)'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              options={aviso_e_social}
              formik={formik}
              id='has_brazilian_children'
              label='Possui filhos no Brasil'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Conta bancária
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='bank_code'
              label='Código do banco'
              labelcode={true}
              options={banks_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='bank_account_type'
              label='Tipo da conta'
              labelcode={true}
              options={type_of_account}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='bank_agency'
              label='Agência com DV'
              type={"number"}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='bank_account'
              label='Conta com DV'
              type={"number"}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
