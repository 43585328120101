/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function TransferNew() {
  const [data, setData]: any = useState({
    options: {
      managers: [],
    },
    user_data: {},
  });
  const { id } = useParams();
  const dispatch = useDispatch();
  const [valide, setValide] = useState(false);
  const formik: any = useFormik({
    initialValues: {
      transfer_date: null,
      observations: "",
      new_manager_id: "",
      with_position: "",
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(
        null,
        { ...values, collaborator_id: data.user_data.id },
        "/transfers"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          window.history.back();
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({ user_uuid: id }, "/transfers/create_form")
      .then((response: any) => {
        dispatch(updateLoading(false));
        const data = response.data.data;
        setData((old: any) => ({ ...old, ...data }));
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/transfer" },
            { label: "Transferências", value: "/request/transfer" },
            { label: "Nova solicitação" },
          ]}
        />
        <InfoComponent
          user={data.user_data}
          instruction='Para transferir o colaborador confira as informações abaixo e
                  digite os detalhes da transferência.'
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar transferência
              </Typography>
            </Grid>
            <Form formik={formik} data={data} />
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <Grid container gap={2} justifyContent={"end"}>
                <ButtonComponent
                  variant={"outlined"}
                  onClick={() => {
                    window.history.back();
                  }}
                  endIcon={<Undo />}>
                  Voltar
                </ButtonComponent>
                <ButtonComponent
                  variant={"contained"}
                  type={"submit"}
                  onClick={() => {
                    setValide(true);
                  }}
                  endIcon={<AddCircle />}>
                  Cadastrar
                </ButtonComponent>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
