/** @format */
import * as yup from "yup";
import { validationCPF } from "../../../../../helpers/validationCPF";

export const columns = [
  { name: "solicitation_id", label: "Código" },
  { name: "target_user_name", label: "Nome" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "solicitation_status", label: "Status" },
];

const contractTypeValidation = (value: any, context: any) => {
  const position_type = context.from[0].value.position_type;

  if (value === null) return true;
  if (
    (value === "999" && position_type !== "pj") ||
    (value !== "999" && position_type === "pj")
  ) {
    return false;
  }
  return true;
};

export const validationSchema = yup.object({
  cpf: yup
    .string()
    .nullable()
    .required("CPF é obrigatório")
    .test("invalid-cpf", "CPF inválido!", (cpf) => validationCPF(cpf)),
  father_cpf: yup
    .string()
    .nullable()
    .test("invalid-cpf", "CPF do pai está inválido inválido!", (cpf) =>
      validationCPF(cpf)
    ),
  mother_cpf: yup
    .string()
    .nullable()
    .test("invalid-cpf", "CPF da mãe está inválido!", (cpf) =>
      validationCPF(cpf)
    ),
  business_unit_contract_type: yup
    .string()
    .nullable()
    .test(
      "invalid-contract_type",
      "Tipo de contrato inválido!",
      (value, context) => contractTypeValidation(value, context)
    ),
  phone: yup.string().nullable().min(15, "Telefone inválido"),
  corporate_phone: yup.string().nullable().min(15, "Telefone inválido"),
  name: yup.string().nullable().required("Nome é obrigatório"),
  birth_date: yup
    .string()
    .nullable()
    .required("Data de nascimento é obrigatório"),
  dependents: yup.array().of(
    yup.object({
      name: yup.string().required("Campo é obrigatório"),
      mother_name: yup.string().required("Campo é obrigatório"),
      birth_date: yup.string().required("Campo é obrigatório"),
      degree_of_kinship: yup.string().required("Campo é obrigatório"),
      cpf: yup.string().required("Campo é obrigatório").min(14, "CPF inválido"),
      gender: yup.string().required("Campo é obrigatório"),
      ir: yup.string().required("Campo é obrigatório"),
      family_salary: yup.string().required("Campo é obrigatório"),
      is_disability: yup.string().required("Campo é obrigatório"),
    })
  ),
  emergency_contacts: yup.array().of(
    yup.object({
      name: yup.string().required("Campo é obrigatório"),
      phone: yup
        .string()
        .required("Campo é obrigatório")
        .min(15, "Telefone inválido"),
      relationship_code: yup.string().required("Campo é obrigatório"),
    })
  ),
});
