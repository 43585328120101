/** @format */

import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function WorkScheduleNew() {
  const router = useNavigate();
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      description: "",
      code: "",
      monthly_hours_amount: false,
      weekly_hours_amount: false,
      vacation_exeption: false,
      night_shift: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PostInstante(null, values, "/work_schedules")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/settings/workSchedule");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  return (
    <Box>
      <Grid
        container
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Configurações" },
            {
              label: "Jornada de trabalho",
              value: "/settings/workSchedule",
            },
            { label: "Nova jornada de trabalho" },
          ]}
        />
      </Grid>
      <Grid width={"inherit"}>
        <Paper sx={{ p: 2 }}>
          <Grid pb={2}>
            <Typography fontSize={18} fontWeight={500}>
              Criar novo grupo
            </Typography>
          </Grid>
          <Form
            formik={formik}
          />
        </Paper>
      </Grid>
    </Box>
  );
}
