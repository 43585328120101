/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { AddCircleOutline, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { AutoCompleteField } from "../../../../components/inputs/autoCompleteField";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../services/api";
import { validationSchema } from "./helpers/constants";
import { Personal } from "./helpers/personal";

export function EmployeeNew() {
  const router = useNavigate();
  const [valide, setValide] = useState(false);
  const [positionId, setPositionId] = useState<any>([]);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { type_of_disability: [], position_id: "" },
    validationSchema: validationSchema,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values: any) => {
      setValide(true);
      dispatch(updateLoading(true));
      if (!values.is_disability) {
        delete values.type_of_disability;
      }
      delete values.personnel_requisition;
      PostInstante(null, values, "/users")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/employee/pending");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/users/positions_by_company")
      .then((res) => {
        dispatch(updateLoading(false));
        setPositionId(res.data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Colaboradores", value: "/employee/active" },
            { label: "Pendentes", value: "employee/pending" },
            { label: "Novo colaborador" },
          ]}
        />
        <InfoComponent instruction="Nesta tela é realizado o cadastro de novos colaboradores. " />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Cadastrar novo colaborador
              </Typography>
            </Grid>
            <Grid>
              <Typography fontSize={16} fontWeight={500}>
                Solicitação
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr "} mt={2} display={"grid"} gap={2}>
              <AutoCompleteField
                options={positionId.map((item: any) => ({
                  value: item.id,
                  label: `${item.code} - ${item.job_role}`,
                }))}
                labelcode={true}
                formik={formik}
                id="position_id"
                label="Posição"
              />
            </Grid>
            <Personal formik={formik} />
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"}>
              <Grid container gap={2} justifyContent={"end"}>
                <ButtonComponent
                  variant={"outlined"}
                  onClick={() => {
                    window.history.back();
                  }}
                  endIcon={<Undo />}
                >
                  Voltar
                </ButtonComponent>
                <ButtonComponent
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<AddCircleOutline />}
                >
                  Criar usuário
                </ButtonComponent>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
