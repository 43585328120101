/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";

import { ButtonComponent } from "../../../../../components/buttonComponent";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";

interface FormProps {
  formik: any;
  cboFamilies: any[];
  cboOcupations: any[];
  setCboFamily: any;
  setCboOcupations: any;
}

export function Form({
  formik,
  cboFamilies,
  cboOcupations,
  setCboFamily,
  setCboOcupations,
}: FormProps) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid gridTemplateColumns={"1fr 1fr 1fr"} mt={2} display={"grid"} gap={2}>
        <TextField
          fullWidth
          id='description'
          label='Descrição'
          placeholder={""}
          variant='outlined'
          value={formik.values.description}
          onChange={(event: any) =>
            formik.setFieldValue("description", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />
        <TextField
          fullWidth
          id='code'
          type='number'
          label='Código'
          variant='outlined'
          value={formik.values.code}
          onChange={(event: any) =>
            formik.setFieldValue("code", event.target.value)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.code && Boolean(formik.errors.code)}
          helperText={formik.touched.code && formik.errors.code}
        />
        <AutoCompleteField
          id='cbo_family'
          formik={formik}
          labelcode
          options={cboFamilies.map((item) => ({
            value: item.id,
            label: item.title,
          }))}
          label='Família CBO'
          onChange={(event, newValue) => {
            setCboFamily(newValue?.value || null);
            if (!newValue) {
              setCboOcupations([]);
            }
          }}
        />
        <AutoCompleteField
          id='cbo_ocupation'
          formik={formik}
          labelcode
          options={cboOcupations.map((item) => ({
            value: item.id,
            label: item.title,
          }))}
          label='Ocupação CBO'
        />
      </Grid>

      <Grid container gap={2} mt={2} justifyContent={"end"}>
        <ButtonComponent
          variant={"outlined"}
          onClick={() => {
            window.history.back();
          }}
          endIcon={<Undo />}>
          Voltar
        </ButtonComponent>
        <ButtonComponent
          variant={"contained"}
          type={"submit"}
          endIcon={<AddCircle />}>
          Salvar
        </ButtonComponent>
      </Grid>
    </form>
  );
}
