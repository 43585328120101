/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import {
  Grid,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { ButtonComponent } from "../../../../../components/buttonComponent";
import { position_type } from "../../../../../config/constants";
import {
  ClearCurrency,
} from "../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";

export function PositionNew() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user.data);
  const [costCenters, setCostCenters] = useState([]);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [reasonSolicitation, setReasonSolicitation] = useState([]);
  const [managers, setManagers] = useState([]);
  const [unitBusiness, setUnitBusiness] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [valide, setValide] = useState(false);
  const formik = useFormik({
    initialValues: {
      function: "",
      salary: "",
      quantity: "",
      description: "",
      business_unit_id: "",
      cost_center_id: "",
      reason_solicitation_id: "",
      manager_id: "",
      department_id: "",
      job_role_id: "",
      position_type: "",
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setValide(false);
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          ...values,
          position_type: Number(values.position_type),
          salary: ClearCurrency(values.salary),
        },
        "/new_positions"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          window.history.back();
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    Promise.all([
      GetInstance(null, "/new_positions/cost_center_by_company"),
      GetInstance(null, "/new_positions/department_by_company"),
      GetInstance(null, "/reason_solicitations"),
      GetInstance(null, "/new_positions/manager_by_company"),
    ])
      .then((res) => {
        setCostCenters(res[0].data);
        setBusinessUnit(res[1].data);
        const reasons = res[2].data.filter((r: any) => r.associated);
        setReasonSolicitation(reasons);
        setManagers(res[3].data);
      })
      .catch((e) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/new_positions/business_unit_by_company")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUnitBusiness(response.data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/personnel_requisitions_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions(response.data);
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.role === "manager") {
      formik.setFieldValue("manager_id", user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Requerimentos", value: "/request/position" },
            { label: "Posições", value: "/request/position" },
            { label: "Nova solicitação" },
          ]}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar nova posição
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <AutoCompleteField
                disabled={user.role === "manager"}
                id="manager_id"
                label="Gestor"
                formik={formik}
                options={managers.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
              <AutoCompleteField
                id="department_id"
                label="Departamento"
                formik={formik}
                options={businessUnit.map((item: any) => ({
                  value: item.id,
                  label: `${item.code} - ${item.name}`,
                }))}
              />
              <AutoCompleteField
                id="cost_center_id"
                label="Centro de custo"
                formik={formik}
                options={costCenters.map((item: any) => ({
                  value: item.id,
                  label: `${item.code} - ${item.description}`,
                }))}
              />
              <InputTextField formik={formik} id="function" label="Função" />
              <InputTextField
                type="number"
                formik={formik}
                id="quantity"
                label="Quantidade"
              />
              <AutoCompleteField
                id="business_unit_id"
                label="Unidade de negócio"
                formik={formik}
                options={unitBusiness?.map((item: any) => ({
                  value: item.id,
                  label: `${item.code} - ${item.name}`,
                }))}
              />
              {user.role === "rh" && (
                <>
                  <AutoCompleteField
                    disabled={user.role === "manager"}
                    id="job_role_id"
                    label="Cargo"
                    formik={formik}
                    options={options?.data?.options.job_role}
                  />
                  <InputCurrencyField
                    formik={formik}
                    id="salary"
                    label="Remuneração"
                  />
                </>
              )}
              <AutoCompleteField
                id="reason_solicitation_id"
                label="Motivo da solicitação"
                formik={formik}
                options={reasonSolicitation?.map((item: any) => ({
                  value: item.id,
                  label: `${item.name}`,
                }))}
              />
              <AutoCompleteField
                id="position_type"
                label="Tipo de posição"
                formik={formik}
                options={position_type}
              />
            </Grid>

            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <InputTextField
                formik={formik}
                multiline={true}
                rows={4}
                id="description"
                label="Responsabilidades"
              />
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}
              >
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                type={"submit"}
                endIcon={<AddCircle />}
              >
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
