/** @format */

import { Grid, Paper, Typography } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "../assets/lotties/empty.json";

export function EmptyData() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Paper style={{ width: "100%" }}>
      <Grid container p={2} mt={2}>
        <Grid
          justifyContent={"center"}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          m={3}>
          <Lottie options={defaultOptions} height={300} width={300} />
          <Typography fontSize={18} fontWeight={800}>
            Não existe dados para serem exibidos
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
