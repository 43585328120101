/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */

import { Add } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { TabsTableComponent } from "../../../../components/tabsTableComponent";
import { Table } from "./helpers/table";

export function UpdateInformation() {
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  const delegation = useSelector((state: any) => state.user.delegation_data);

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio", value: "/" },
          { label: "Solicitações", value: "/request" },
          { label: "Alteração cadastral", value: "/request/updateInformation" },
        ]}
        buttons={
          !delegation?.delegated_session && (
            <ButtonComponent
              variant={"contained"}
              onClick={() => {
                router(`/request/updateInformation/request/${user.uuid}`);
              }}
              endIcon={<Add />}>
              Solicitar alteração cadastral
            </ButtonComponent>
          )
        }
      />
      <TabsTableComponent
        tabs={[
          {
            type: "general_records",
            component: <Table type='general_records' />,
          },
          {
            type: "team_records",
            component: <Table type='team_records' />,
          },
          {
            type: "my_records",
            component: <Table type='my_records' />,
          },
        ]}
      />
    </Grid>
  );
}
