/** @format */

import { exportDataToCSV } from "../../../../../helpers/exportDataToCSV";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";

export const handleDownloadUsersReportButton = async (dispatch: any) => {
  dispatch(updateLoading(true));
  GetInstance({}, "/reports/users/export_csv")
    .then((response: any) => {
      exportDataToCSV(response.data, "Relatório de colaboradores");
      dispatch(updateLoading(false));
    })
    .catch((e: any) => {
      GenericError(e, dispatch);
    });
};

export const handleDownloadDependentsReportButton = async (dispatch: any) => {
  dispatch(updateLoading(true));
  GetInstance({}, "/reports/dependents/export_csv")
    .then((response: any) => {
      exportDataToCSV(response.data, "Relatório de dependentes");
      dispatch(updateLoading(false));
    })
    .catch((e: any) => {
      GenericError(e, dispatch);
    });
};
