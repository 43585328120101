/** @format */

import { Visibility } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { ActionRemove } from "../../../../components/actionRemove";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { optionsTable } from "../../../../config/optionsTable";
import { IsPermissions } from "../../../../helpers/isPermission";
import useVacationTime from "../../../../services/protected/hooks/useVacationTime";
import { columns } from "./helpers/constants";

export function VacationTime() {
  const router = useNavigate();
  const {
    data,
    total,
    page,
    perPage,
    setPerPage,
    setPage,
    setOrder,
    searchCall,
  } = useVacationTime();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Férias", value: "/vacation" },
          { label: "Periodos", value: "/vacation/time" },
        ]}
      />
      <MUIDataTable
        title={""}
        data={data}
        columns={[
          {
            name: "action",
            label: "Ações",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <Grid container>
                    <IsPermissions keyPrivate={"vacationCollaboratorDetails"}>
                      <Tooltip title={"Ver solicitação"}>
                        <IconButton
                          onClick={() =>
                            router(`/vacation/details/${data[dataIndex].uuid}`)
                          }>
                          <Visibility color='primary' />
                        </IconButton>
                      </Tooltip>
                    </IsPermissions>
                    <ActionRemove
                      statusList={data[dataIndex].status}
                      url='/vacations'
                      id={data[dataIndex].uuid}
                    />
                  </Grid>
                );
              },
            },
          },
          { name: "target_user_name", label: "Colaborador" },
          {
            name: "acquisition_period_start",
            label: "Período aquisitivo",
            options: {
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <>
                    {data[dataIndex].acquisition_period_start} -{" "}
                    {data[dataIndex].acquisition_period_end}
                  </>
                );
              },
            },
          },
          {
            name: "concessive_period_start",
            label: "Período concessivo",
            options: {
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <>
                    {data[dataIndex].concessive_period_start} -{" "}
                    {data[dataIndex].concessive_period_end}
                  </>
                );
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por nome",
          download: false,
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPage(0);
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
