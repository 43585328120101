/** @format */

import { createBrowserRouter } from "react-router-dom";
import { CompanyRouter } from "../components/companyRouter";
import { ProtectedRoute } from "../components/protectedRouter";
import { PermissionCheck } from "../helpers/permissionCheck";
import ErrorPage from "../pages/unprotected/error-page";
import { routers } from "./routers";

const routerMap = routers.map((item: any) =>
  item.protected
    ? {
        ...item,
        element: (
          <CompanyRouter>
            <ProtectedRoute>
              <PermissionCheck mobile={item.mobile} keyPrivate={item.id}>
                {item.component}
              </PermissionCheck>
            </ProtectedRoute>
          </CompanyRouter>
        ),
        errorElement: <ErrorPage />,
      }
    : {
        ...item,
        element: <CompanyRouter>{item.component}</CompanyRouter>,
        errorElement: <ErrorPage />,
      }
);

export const router = createBrowserRouter([...routerMap]);
