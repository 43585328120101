/** @format */

import { Box, Grid, Tooltip } from "@mui/material";
import { AddCircleOutlined, Edit } from "@mui/icons-material";
import { IconButton } from "@radix-ui/themes";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { optionsTable } from "../../../../config/optionsTable";
import useJobRoleGroups from "../../../../services/protected/hooks/useJobRoleGroup";
import { columns } from "./helpers/constants";
import { ButtonComponent } from "../../../../components/buttonComponent";

export function JobRoleGroups() {
  const router = useNavigate();

  const {
    data,
    page,
    perPage,
    searchCall,
    setOrder,
    setPage,
    setPerPage,
    total,
  } = useJobRoleGroups();

  return (
    <Box>
      <Grid
        container
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Configurações" },
            { label: "Grupos de Cargos" },
          ]}
          buttons={<ButtonComponent
            variant={"contained"}
            endIcon={<AddCircleOutlined />}
            onClick={() => router("/settings/jobRoleGroups/new")}>
            Novo Grupo
          </ButtonComponent>}
        />
        
        <MUIDataTable
          title={"Cargos"}
          data={data}
          columns={[
            {
              name: "action",
              label: "AÇÕES",
              options: {
                sort: false,
                customBodyRenderLite: (dataIndex: number) => {
                  return (
                    <Grid item gap={2} display={"flex"}>
                      <Tooltip title={"Editar"}>
                        <IconButton
                          variant='ghost'
                          radius='full'
                          onClick={() =>
                            router(
                              `/settings/jobRoleGroups/details/${data[dataIndex].uuid}`
                            )
                          }>
                          <Edit fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  );
                },
              },
            },
            ...columns,
          ]}
          options={{
            ...optionsTable,
            download: false,
            searchPlaceholder: "Pesquise por descrição ou código",
            onSearchChange: (value: string) => {
              searchCall(value);
            },
            onColumnSortChange: (value: string, direction: string) => {
              setOrder(`${value} ${direction}`);
            },
            count: total,
            page,
            onChangePage: (page: number) => {
              setPage(page);
            },
            rowsPerPage: perPage,
            onChangeRowsPerPage: (perPage: number) => {
              setPerPage(perPage);
            },
          }}
        />
      </Grid>
    </Box>
  );
}
