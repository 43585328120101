/** @format */
import * as yup from "yup";
export const columns = [
  { name: "identification", label: "Matrícula" },
  { name: "name", label: "Nome" },
  { name: "cpf", label: "CPF" },
  { name: "job_role_name", label: "Cargo" },
  { name: "manager_name", label: "Gestor" },
  { name: "department_name", label: "Departamento" },
];

export const validationSchema = yup.object({
  job_role_id: yup.string().nullable().required("Campo é obrigatório"),
  manager_id: yup.string().nullable().required("Campo é obrigatório"),
  department_id: yup.string().nullable().required("Campo é obrigatório"),
});
