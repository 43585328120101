/** @format */

import { Grid } from "@mui/material";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { leave_e_social } from "../../../../../config/constants";

export function Form({ formik, edit, showDate }: any) {
  return (
    <>
      <Grid
        gridTemplateColumns={showDate ? "1fr 1fr 1fr " : "1fr 1fr"}
        display={"grid"}
        gap={2}>
        <InputDateField
          formik={formik}
          id={"absence_date"}
          disabled={edit}
          label='Data de saída'
        />
        <InputDateField
          formik={formik}
          id={"return_date"}
          disabled={edit}
          label='Data de retorno'
          onChange={(value: any) => {
            if (value < (formik.values.absence_date as any)) {
              formik.setFieldTouched("return_date");
            }
          }}
        />
        {showDate && (
          <InputDateField
            formik={formik}
            id={"return_date"}
            disabled={edit}
            label='Nova data de retorno'
          />
        )}
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <AutoCompleteField
          options={leave_e_social}
          disabled={edit}
          formik={formik}
          labelcode={true}
          id={"e_social_motive"}
          label='Motivo do afastamento e-Social'
        />
        <InputTextField
          formik={formik}
          id='observations'
          multiline={true}
          disabled={edit}
          rows={4}
          label='Informações sobre o afastamento'
        />
      </Grid>
    </>
  );
}
