/** @format */

import { Autocomplete, Box, TextField } from "@mui/material";
import { getIn } from "formik";
type AutoCompleteFieldProps = {
  options: { value: string | number; label: string }[];
  label: string;
  id: string;
  onChange?: (
    event: any,
    newValue: { value: string | number; label: string } | null
  ) => void;
  labelcode?: boolean;
  formik: any;
  disabled?: boolean;
};

export function AutoCompleteField(props: AutoCompleteFieldProps) {
  const { options, label, labelcode, formik, id, disabled } = props;
  const optionsToUse = options === undefined || options === null ? [] : options;
  const value = getIn(formik.values, id) ?? "";
  const error = getIn(formik.errors, id);
  return (
    <Autocomplete
      {...props}
      options={optionsToUse}
      disabled={disabled}
      value={{ value: value, label: "" }}
      onChange={(event, newValue, reason) => {
        if (reason === "clear") {
          formik.setFieldValue(id, "");
          return;
        }
        formik.setFieldValue(id, newValue?.value || null);
        if (props.onChange) props.onChange(event, newValue);
      }}
      noOptionsText='Nenhum resultado encontrado'
      filterOptions={(optionsToFiltter, params) => {
        const filtered = optionsToFiltter.filter((option: any) => {
          if (!params.inputValue) {
            return true;
          }
          return (
            option?.label
              ?.toLowerCase()
              ?.includes(params?.inputValue?.toLowerCase()) ||
            option?.value
              ?.toString()
              ?.toLowerCase()
              ?.includes(params?.inputValue?.toLowerCase())
          );
        });

        return filtered;
      }}
      getOptionLabel={(option) => {
        if (!option) return "";
        const selected = optionsToUse.find(
          ({ value }) => value === option.value
        );
        return selected
          ? labelcode
            ? `${selected.value} - ${selected.label}`
            : selected?.label ?? ""
          : "";
      }}
      renderOption={(props, option: any) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key + option?.value?.toString()}
            component='li'
            {...optionProps}>
            {labelcode ? `${option.value} - ${option.label}` : option.label}
          </Box>
        );
      }}
      renderInput={(renderInputParams) => (
        <TextField
          {...renderInputParams}
          fullWidth

          onBlur={formik.handleBlur}
          placeholder='Digite aqui para buscar'
          error={Boolean(error)}
          helperText={error}
          label={label}
          variant='outlined'
        />
      )}
    />
  );
}
