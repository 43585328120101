/** @format */

import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
// import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";
import { validationSchema } from "../helpers/constants";

export function WorkScheduleDetails() {
  const { id } = useParams();
  const router = useNavigate();
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      description: "",
      code: "",
      monthly_hours_amount: false,
      weekly_hours_amount: false,
      vacation_exeption: false,
      night_shift: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(null, values, "/work_schedules/" + id)
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/settings/workSchedule");
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/work_schedules/" + id)
      .then((response: any) => {
        dispatch(updateLoading(false));

        formik.setValues({
          description: response.data.data.description,
          code: response.data.data.code,
          monthly_hours_amount: response.data.data.monthly_hours_amount,
          weekly_hours_amount: response.data.data.weekly_hours_amount,
          night_shift: response.data.data.night_shift,
          vacation_exeption: response.data.data.vacation_exeption,
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Box>
      <Grid
        container
        gap={2}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Configurações" },
            {
              label: "Jornada de trabalho",
              value: "/settings/workSchedule",
            },
            { label: "Nova jornada de trabalho" },
          ]}
        />
      </Grid>
      <Grid width={"inherit"}>
        <Paper sx={{ p: 2 }}>
          <Grid pb={2}>
            <Typography fontSize={18} fontWeight={500}>
              Detalhes da Jornada de Trabalho
            </Typography>
          </Grid>
          <Form
            formik={formik}
          />
        </Paper>
      </Grid>
    </Box>
  );
}
