/** @format */

import { Cancel, CheckCircle, Delete } from "@mui/icons-material";
import { AlertDialog, Flex } from "@radix-ui/themes";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { RemoveInstance } from "../../../../services/api";

export const DeleteDialog = (id: any) => {
  const dispatch = useDispatch();
  const router = useNavigate();
  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger>
        <ButtonComponent variant={"contained"} endIcon={<Delete />}>
          Deletar
        </ButtonComponent>
      </AlertDialog.Trigger>
      <AlertDialog.Content maxWidth='450px'>
        <AlertDialog.Title>
          Deseja realmente deletar esta delegação?
        </AlertDialog.Title>
        <AlertDialog.Description size='2'>
          Ao confirmar, a delegação será deletada e não será possível recuperar
          a mesma. Para realizar esse fluxo novamente, será necessário criar uma
          nova delegação.
        </AlertDialog.Description>
        <Flex gap='3' mt='4' justify='end'>
          <AlertDialog.Cancel>
            <ButtonComponent
              color='error'
              variant={"outlined"}
              endIcon={<Cancel />}>
              Cancelar
            </ButtonComponent>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <ButtonComponent
              variant={"contained"}
              color='success'
              endIcon={<CheckCircle />}
              onClick={() => {
                dispatch(updateLoading(true));
                RemoveInstance(id, "/delegations")
                  .then((response: any) => {
                    dispatch(updateLoading(false));
                    router("/delegation");
                    toast.success(response?.data?.message);
                  })
                  .catch((e: any) => {
                    GenericError(e, dispatch);
                  });
              }}>
              Confirmar
            </ButtonComponent>
          </AlertDialog.Action>
        </Flex>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};
