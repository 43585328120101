/** @format */

import { Download } from "@mui/icons-material";
import { Grid, Paper, Typography } from "@mui/material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GenericError } from "../helpers/genericError";
import { updateLoading } from "../redux/reducers/common";
import { GetInstance } from "../services/api";
import { ButtonComponent } from "./buttonComponent";

interface Props {
  solicitation_uuid?: string;
  solicitation_type: string;
  dismissal_reason_id?: string;
  dismissal_type_id?: string;
}

export const DocsView = (props: Props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const loading = useSelector((state: any) => state.common.loading);

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(props, "/letters_content")
      .then(async (response: any) => {
        setData(response.data.data.data);
        dispatch(updateLoading(false));
      })
      .catch((e: any) => {
        GenericError(e, dispatch, false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleDownloadPDF = (item: any, index: number) => {
    dispatch(updateLoading(true));
    const content: any = document.querySelector('.printable-content' + index);
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth, pdfHeight);
      pdf.save(`${item.name.toLowerCase().replaceAll(' ', '_').replaceAll('-', '')}.pdf`);
      dispatch(updateLoading(false));
    });
  };

  return (
    <Grid>
      {data.length === 0 && !loading && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography fontSize={18} fontWeight={500}>
            Documentos
          </Typography>
          <Grid>
            <Typography fontSize={14}>
              Não existem documentos para este colaborador ou não foi encontrado
              modelo de documento para esse tipo de solicitação.
            </Typography>
          </Grid>
        </Paper>
      )}
      {data.length > 0 && !loading && (
        <Grid>
          {data.map((item: any, index: number) => (
            <Paper sx={{ p: 2, mt: 2, gap: 2 }}>
              <Grid container justifyContent={"space-between"}>
                <Typography fontSize={14} fontWeight={500}>
                  {item.name.toUpperCase()}
                </Typography>
                <ButtonComponent
                  variant={"contained"}
                  onClick={() => handleDownloadPDF(item, index)}
                  endIcon={<Download />}>
                  Baixar
                </ButtonComponent>
              </Grid>
              <div
                style={{ maxWidth: "100%" }}
                className={"printable-content" + index}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </Paper>
          ))}
        </Grid>
      )}
    </Grid>
  );
};
