/** @format */

export const handleRGChange = (event: any, formik: any, id: any) => {
  let { value } = event.target;
  value = value.replace(/[^a-zA-Z0-9]/g, '');
  if (value.length <= 10) {
    value = value.replace(/(\w{2})(\w)/, "$1.$2");
    value = value.replace(/(\w{3})(\w)/, "$1.$2");
    value = value.replace(/(\w{3})(\w{1,2})$/, "$1-$2");
  }
  formik.setFieldValue(id, value);
};
