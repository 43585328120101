import * as yup from "yup";

export const validationSchema = yup.object({
  description: yup.string().required("Descrição é obrigatório"),
});

export const columns = [
  { name: "code", label: "Código" },
  { name: "description", label: "Descrição" },
];

