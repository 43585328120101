/** @format */

import { Grid } from "@mui/material";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { TabsTableComponent } from "../../../../components/tabsTableComponent";
import { Table } from "./helpers/table";

export function DismissalRequests() {
  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Início" },
          { label: "Solicitações", value: "/request/dismissalRequests" },
          {
            label: "Autorizações de desligamento",
          },
        ]}
      />
      <TabsTableComponent
        tabs={[
          {
            type: "general_records",
            component: <Table type='general_records' />,
          },
          {
            type: "team_records",
            component: <Table type='team_records' />,
          },
        ]}
      />
    </Grid>
  );
}
