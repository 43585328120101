/** @format */

import { AddCircle, Cancel } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";
import { onSubmit } from "../helpers/onSubmit";

export function DismissalRequestsNew() {
  const router = useNavigate();
  const { id } = useParams();
  const [valide, setValide] = useState(false);
  const [user, setUser]: any = useState({});
  const [options, setOptions] = useState({
    dismissal_reasons: [],
    dismissal_types: [],
  });
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      dismissal_date: null,
      early_warning: "",
      comment: "",
      dismissal_reason_id: "",
      dismissal_type_id: "",
    },
    validationSchema: validationSchema,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values) => {
      onSubmit({ values, dispatch, router, id });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/dismissal_requests_form?user_uuid=" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setUser(response.data.data.user_data);
        setOptions({
          dismissal_reasons:
            response.data.data.dismissal_reasons.group_0.filter(
              (d: any) => d.associated
            ),
          dismissal_types: response.data.data.dismissal_types.group_0.filter(
            (d: any) => d.associated
          ),
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Solicitações", value: "/request/dismissalRequests" },
            {
              label: "Autorização de desligamento",
              value: "/request/dismissalRequests",
            },
            { label: "Nova solicitação" },
          ]}
        />
        <InfoComponent
          user={user}
          instruction={
            "Nessa solicitação você poderá criar uma autorização de desligamento para o colaborador."
          }
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Criar autorização de desligamento
              </Typography>
            </Grid>
            <Form formik={formik} options={options} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                color={"error"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Cancel />}>
                Cancelar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                type='submit'
                onClick={() => {
                  setValide(true);
                }}
                endIcon={<AddCircle />}>
                Cadastrar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
