/** @format */
export const columns = [
  { name: "identification", label: "Matrícula" },
  { name: "name_formatted", label: "Nome" },
  { name: "cpf", label: "CPF" },
  { name: "job_role_name", label: "Cargo" },
  { name: "manager_name", label: "Gestor" },
  { name: "department_name", label: "Departamento" },
];

