import { Checkbox, FormControlLabel } from "@mui/material";

interface FieldProps {
  formik: any;
  id: string;
  label: string;
  disabled?: boolean;
  onBlur?: (event: any) => void;
}
export const InputCheckBox = (props: FieldProps) => {
  const {
    formik,
    id,
    label,
    disabled,
  } = props;

  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      slotProps={{
        typography: {
          color: formik.errors.name ? "error" : "inherit",
        },
      }}
      onBlur={formik.handleBlur}
      control={
        <Checkbox
          id={id}
          disabled={disabled}
          checked={formik.values[id]}
          onChange={() => {
            formik.setFieldValue(id, !formik.values[id])
          }
          }
        />
      }
    />
  );
}