/** @format */

import { Edit, Undo, Visibility } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionAccountingDisapproved } from "../../../../../components/actionAccountingDisapproved";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionCompleted } from "../../../../../components/actionCompleted";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionResend } from "../../../../../components/actionResend";
import { ActionReturned } from "../../../../../components/actionReturned";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { DocsView } from "../../../../../components/docsView";
import { InfoComponent } from "../../../../../components/infoComponent";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import { ClearCurrency } from "../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";
import { HistoryComponent } from "../helpers/historyComponent";

export function PromoteDetails() {
  const [docs, setDocs] = useState(false);
  const [data, setData]: any = useState({
    options: {
      business_unit: [],
      sector: [],
      cost_center: [],
      job_role: [],
      job_role_group: [],
      positions: [],
    },
    user_data: {},
    collaborator_data: {},
    solicitation_data: {},
    rules: {},
  });
  const { id } = useParams();
  const permissions = useSelector((state: any) => state.user.permissions);
  const user = useSelector((state: any) => state?.user?.data);
  const [valide, setValide] = useState(false);

  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.record.created_by_id;
  const edit =
    (permissions !== null &&
      permissions.indexOf("requestPromoteDetailsEdit") !== -1 &&
      (data?.solicitation_data.status === "waiting_rh_user" ||
        data?.solicitation_data.status === "accounting_return")) ||
    isResendable;
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      target_business_unit_id: "",
      movement_date: null,
      percent: "",
      target_salary: "",
      observations: "",
      promote_position: false,
      target_sector_id: "",
      target_cost_center_id: "",
      target_position_id: "",
      target_job_role_id: "",
      actual_salary: "",
    },
    validationSchema: validationSchema(
      data.rules.promotions_min_percent,
      data.rules.promotions_max_percent
    ),
    validateOnChange: valide,
    validateOnBlur: valide,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(id, values, "/promotions")
        .then((response: any) => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          window.history.back();
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/promotions/" + id)
      .then((response: any) => {
        const data = response.data.data;
        setData((old: any) => ({ ...old, ...data }));
        const movement_date = dayjs(data.record.movement_date);
        GetInstance(
          { user_uuid: data.collaborator_data.uuid },
          "/promotions_form"
        )
          .then(async (response: any) => {
            formik.setFieldValue(
              "actual_salary",
              ClearCurrency(response.data.data.user_data.remuneration_value) ??
              "0"
            );
            setData((old: any) => ({
              ...old,
              collaborator_data: {
                ...old.collaborator_data,
                ...response.data.data.user_data,
              },
              options: response.data.data.options,
            }));
            formik.setValues({
              target_business_unit_id: data.record.target_business_unit_id,
              movement_date,
              percent: data.record.percent,
              target_salary: data.record.target_salary,
              observations: data.record.observations,
              target_sector_id: data.record.target_sector_id,
              target_cost_center_id: data.record.target_cost_center_id,
              target_job_role_id: data.record.target_job_role_id,
              target_position_id: data.record.target_position_id,
              target_position:
                response.data.data.user_data.position_id +
                " - " +
                response.data.data.user_data.position_name,
              actual_salary: ClearCurrency(
                data.collaborator_data.remuneration_value
              ),
              promote_position:
                data.record.target_position_id ===
                response.data.data.user_data.position_id,
            });
            dispatch(updateLoading(false));
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Solicitações", value: "/request/promote" },
            { label: "Promoções", value: "/request/promote" },
            { label: "Ver solicitação" },
          ]}
        />
        <InfoComponent
          user={data.collaborator_data}
          solicitation={data.solicitation_data}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da promoção
              </Typography>
            </Grid>
            <Form formik={formik} data={data} edit={!edit} />
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <Grid container gap={2} justifyContent={"end"}>
                {isResendable && (
                  <ActionResend
                    id={id}
                    url={"promotions"}
                    disabled={!formik.isValid}
                    onSubmit={(resendActionCallback: any) => {
                      dispatch(updateLoading(true));
                      PutInstante(id, formik.values, "/promotions")
                        .then((response: any) => {
                          resendActionCallback();
                          dispatch(updateLoading(false));
                          window.history.back();
                        })
                        .catch((e: any) => {
                          GenericError(e, dispatch);
                        });
                    }}
                  />
                )}
                <ButtonComponent
                  variant={"outlined"}
                  onClick={() => {
                    window.history.back();
                  }}
                  endIcon={<Undo />}>
                  Voltar
                </ButtonComponent>
                {edit && (
                  <ButtonComponent
                    variant={"contained"}
                    type={"submit"}
                    onClick={() => {
                      setValide(true);
                    }}
                    endIcon={<Edit />}>
                    Editar
                  </ButtonComponent>
                )}

                <StatusPermittedByRoleContainer
                  rhAllowedStatuses={[
                    "pending",
                    "waiting_manager_user",
                    "waiting_rh_user",
                    "accounting_return",
                  ]}
                  managerAllowedStatuses={["pending", "waiting_manager_user"]}
                  status={data?.solicitation_data.status}>
                  <ActionReturned id={id} url={"promotions"} />
                  <ActionDisapproved id={id} url={"promotions"} />
                </StatusPermittedByRoleContainer>
                <StatusPermittedByRoleContainer
                  rhAllowedStatuses={[
                    "pending",
                    "waiting_manager_user",
                    "waiting_rh_user",
                  ]}
                  managerAllowedStatuses={["pending", "waiting_manager_user"]}
                  status={data?.solicitation_data.status}>
                  <ActionAproved id={id} url={"promotions"} />
                </StatusPermittedByRoleContainer>
                <StatusPermittedByRoleContainer
                  rhAllowedStatuses={["accounting_return"]}
                  status={data?.solicitation_data.status}>
                  <ActionResend
                    id={id}
                    url={"promotions"}
                    hasObservation
                    disabled={!formik.isValid}
                    onSubmit={(resendActionCallback: any) => {
                      dispatch(updateLoading(true));
                      PutInstante(id, formik.values, "/promotions")
                        .then((response: any) => {
                          resendActionCallback();
                          dispatch(updateLoading(false));
                          window.history.back();
                        })
                        .catch((e: any) => {
                          GenericError(e, dispatch);
                        });
                    }}
                  />
                </StatusPermittedByRoleContainer>
                <StatusPermittedByRoleContainer
                  accountantAllowedStatuses={["approved"]}
                  status={data?.solicitation_data.status}>
                  <ActionCompleted id={id} url={"promotions"} />
                  <ActionAccountingDisapproved id={id} url={"promotions"} />
                </StatusPermittedByRoleContainer>
                <StatusPermittedByRoleContainer
                  rhAllowedStatuses={["completed"]}
                  managerAllowedStatuses={["completed"]}
                  status={data?.solicitation_data.status}>
                  <ButtonComponent
                    onClick={() =>
                      setDocs(true)
                    }
                    variant={"contained"}
                    endIcon={<Visibility />}>
                    Ver documentos de comunicação
                  </ButtonComponent>
                </StatusPermittedByRoleContainer>
              </Grid>
            </Grid>
            <HistoryComponent
              data={data?.solicitation_data?.solicitation_histories}
              options={data.options}
            />
          </Paper>
          {docs && (
            <DocsView solicitation_uuid={data?.solicitation_data?.uuid} solicitation_type={"promotion"} />
          )}
        </Grid>
      </Grid>
    </form>
  );
}
