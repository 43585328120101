/** @format */

import { toast } from "react-toastify";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import {
  PostInstante,
  PostInstanteFormData,
  RemoveInstance,
} from "../../../../../services/api";

export const onSubmit = (
  values: any,
  dispatch: any,
  id: any,
  formik: any,
  router: any
) => {
  dispatch(updateLoading(true));
  PostInstante(null, { ...values, collaborator_id: id }, "/dismissals")
    .then((response: any) => {
      const formData = new FormData();
      formik.values.files.map((file: any) => formData.append("files[]", file));
      PostInstanteFormData(
        null,
        formData,
        "/solicitations/" +
          response.data.data.solicitation_uuid +
          "/upload_files"
      )
        .then(() => {
          dispatch(updateLoading(false));
          toast.success(response?.data?.message);
          router("/request/dismissals");
        })
        .catch((e: any) => {
          if (e.code !== "ERR_CANCELED") {
            RemoveInstance(id, "/dismissals")
              .then(() => {
                dispatch(updateLoading(false));
                toast.error(
                  "Sua solicitação não pode ser criada, pois houve um erro ao anexar os arquivos."
                );
              })
              .catch((e: any) => {
                if (e.code !== "ERR_CANCELED") {
                  dispatch(updateLoading(false));
                  toast.error(
                    "Sua solicitação não pode ser criada, pois houve um erro ao anexar os arquivos."
                  );
                }
              });
          }
        });
    })
    .catch((e: any) => {
      GenericError(e, dispatch);
    });
};
