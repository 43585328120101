/** @format */
import { Groups, Info, Person } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
// import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { optionsTable } from "../../../../config/optionsTable";
import useReportUsers from "../../../../services/protected/hooks/useReportUsers";
import { columns } from "./helpers/constants";
import {
  handleDownloadDependentsReportButton,
  handleDownloadUsersReportButton,
} from "./helpers/handleDownloadButton";

export function ReportEmployee() {
  const dispatch = useDispatch();
  const router = useNavigate();

  // const formik: any = useFormik({
  //   initialValues: {},
  //   onSubmit: () => {
  //     setReset(true);
  //   },
  // });

  const {
    data,
    total,
    setOrder,
    setPage,
    setPerPage,
    searchCall,
    page,
    perPage,
  } = useReportUsers();

  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[{ label: "Inicio" }, { label: "Relatórios", value: '/report/employee' }, { label: "Colaboradores" }]}
      />
      <MUIDataTable
        title={""}
        data={data}
        columns={[
          {
            name: "action",
            label: "Ações",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <Tooltip title={"Ver informações"}>
                    <IconButton
                      onClick={() =>
                        router(
                          `/request/updateInformation/request/${data[dataIndex].uuid}`
                        )
                      }>
                      <Info />
                    </IconButton>
                  </Tooltip>
                );
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por nome ou CPF",
          download: false,
          customToolbar: () => (
            <>
              <Tooltip title='Baixar relatório de colaboradores'>
                <IconButton
                  onClick={() => handleDownloadUsersReportButton(dispatch)}>
                  <Person />
                </IconButton>
              </Tooltip>
              <Tooltip title='Baixar relatório de dependentes'>
                <IconButton
                  onClick={() =>
                    handleDownloadDependentsReportButton(dispatch)
                  }>
                  <Groups />
                </IconButton>
              </Tooltip>
            </>
          ),
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPage(0);
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
