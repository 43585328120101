/** @format */
import * as yup from "yup";
export const columns = [
  { name: "solicitation_code", label: "Código" },
  { name: "name", label: "Função" },
  { name: "solicitation_date", label: "Data da solicitação" },
  { name: "job_role_name", label: "Cargo" },
  { name: "salary", label: "Remuneração" },
  { name: "created_by_name", label: "Solicitante" },
  { name: "department_name", label: "Departamento" },
  { name: "solicitation_status", label: "Status" },
];

export const validationSchema = yup.object({
  function: yup.string().nullable().required("Nome da função é obrigatório"),
  quantity: yup
    .number()
    .nullable()
    .min(1, "Quantidate mínima: 1")
    .required("Quantidade é obrigatório"),
  description: yup
    .string()
    .nullable()
    .required("Responsabilidades é obrigatório"),
  reason_solicitation_id: yup
    .string()
    .nullable()
    .required("Esse campo é obrigatório"),
  manager_id: yup.string().nullable().required("Esse campo é obrigatório"),
  business_unit_id: yup
    .string()
    .nullable()
    .required("Esse campo é obrigatório"),
  cost_center_id: yup.string().nullable().required("Esse campo é obrigatório"),
});
