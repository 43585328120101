/** @format */

import { Edit, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function WorkflowDetails() {
  const [valide, setValide] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [options, setOptions] = useState({
    positions: [],
  });

  const formik: any = useFormik({
    initialValues: {
      solicitation_type: "",
      workflow_steps: [{ position_id: '', name: '', step_type: null, order: 1 }]
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      PutInstante(null, { ...values }, `/workflows/${id}`)
        .then((response: any) => {
          dispatch(updateLoading(false));
          window.history.back();
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/workflow_steps_form")
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        setOptions(response.data.data.options)
        GetInstance(null, "/workflows/" + id)
          .then(async (response: any) => {
            dispatch(updateLoading(false));
            const formData = response.data.data;
            formik.setValues({ ...formData.record });
          })
          .catch((e: any) => {
            GenericError(e, dispatch)
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch)
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Workflow", value: "/workflow" },
            { label: "Detalhes workflow" },
          ]}
        />
        <InfoComponent
          instruction={
            "Nessa tela você pode editar um workflow da sua empresa."
          }
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes do workflow
              </Typography>
            </Grid>
            <Form
              formik={formik}
              options={options}
            />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                type={"submit"}
                onClick={() => {
                  setValide(true);
                }}
                endIcon={<Edit />}>
                Editar
              </ButtonComponent>


            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
