/** @format */

import { Clear, Edit, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";

import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { config, validationSchema, variables } from "../helpers/constants";
import { Form } from "../helpers/form";
import { motive_e_social } from "../../../../../config/constants";

export function LetterDetails() {
  const { id } = useParams();
  const router = useNavigate();
  const dispatch = useDispatch();
  const editor = useRef(null);
  const [valide, setValide] = useState(false);
  const [options, setOptions] = useState({
    dismissal_types: [],
    dismissal_reasons: [],
  });

  const formik = useFormik({
    initialValues: {
      solicitation_type: "",
      dismissal_types: [],
      salary_review_types: [],
      name: "",
      content: "",
      active: false,
    },
    validationSchema: validationSchema,
    validateOnBlur: valide,
    validateOnChange: valide,
    onSubmit: (values) => {
      dispatch(updateLoading(true));
      const sendValues: any = {
        ...values,
        dismissal_types: values.dismissal_types.map((item: any) => item.value),
        salary_review_types: values.salary_review_types.map(
          (item: any) => item.value
        ),
      };
      if (sendValues.solicitation_type !== "dismissal") {
        delete sendValues.dismissal_types;
      }
      if (sendValues.solicitation_type !== "salary_review") {
        delete sendValues.salary_review_types;
      }
      PutInstante(id, sendValues, "/letters")
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/settings/letter");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/letters_form")
      .then((response: any) => {
        setOptions({
          dismissal_reasons:
            response.data.data.options.dismissal_reasons.group_0.filter(
              (d: any) => d.associated
            ),
          dismissal_types:
            response.data.data.options.dismissal_types.group_0.filter(
              (d: any) => d.associated
            ),
        });
        GetInstance(null, "/letters/" + id)
          .then(async (response2: any) => {
            formik.setFieldValue(
              "solicitation_type",
              response2?.data?.data?.solicitation_type
            );
            formik.setFieldValue("name", response2?.data?.data?.name);
            formik.setFieldValue("content", response2?.data?.data?.content);
            formik.setFieldValue("active", response2?.data?.data?.active);
            if (response2?.data?.data?.solicitation_type === "dismissal") {
              const valuesFind =
                await response2?.data?.data?.dismissal_types?.map((item: any) =>
                  response.data.data.options.dismissal_types.group_0.find(
                    (item2: any) => Number(item) === item2.id
                  )
                );
              formik.setFieldValue(
                "dismissal_types",
                valuesFind.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                })) ?? []
              );
            }
            if (response2?.data?.data?.solicitation_type === "salary_review") {
              const valuesFind2 =
                await response2?.data?.data?.salary_review_types?.map(
                  (item: any) =>
                    motive_e_social.find((item2: any) => item === item2)
                );
              formik.setFieldValue(
                "salary_review_types",
                valuesFind2.map((item: any) => ({
                  value: item,
                  label: item,
                }))
              );
            }
            dispatch(updateLoading(false));
          })
          .catch((e: any) => {
            GenericError(e, dispatch);
          });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Configurações", value: "/settings/letter" },
            { label: "Carta", value: "/settings/letter" },
            { label: "Ver carta" },
          ]}
        />
        <InfoComponent
          instructionMap={[
            "Selecione o tipo de carta, adicione um nome e os outros dados necessário, Selecione 'Ativar exibição da carta' caso deseje ativar e crie o documento personalizado que deseja acrescentar no sistema. Depois é só clicar em salvar edição.",
            "Para adicionar uma variavel de ambiente, por exemplo que seja colocado o nome do colaborador personalizado, adicione o texto como no exemplo do arquivo.",
            "Variáveis disponíveis:",
          ]}
          instructionChildren={variables.map((item: any) => (
            <Typography fontSize={12} ml={2} fontWeight={400}>
              - {item.label} <strong>{item.value}</strong>
            </Typography>
          ))}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes do modelo de carta
              </Typography>
            </Grid>
            <Form formik={formik} options={options} />
            <Grid pt={2}>
              <JoditEditor
                ref={editor}
                value={formik.values.content}
                config={config(editor)}
                onBlur={(newContent) =>
                  formik.setFieldValue("content", newContent)
                }
              />
              {formik.errors?.content && (
                <Typography
                  fontSize={"0.75rem"}
                  style={{ marginTop: 4, marginLeft: 14 }}
                  color={"error"}
                >
                  {formik.errors?.content}
                </Typography>
              )}
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}
              >
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  formik.setFieldValue("content", "");
                }}
                endIcon={<Clear />}
              >
                Limpar tudo
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                onClick={() => {
                  setValide(true);
                  formik.handleSubmit();
                }}
                endIcon={<Edit />}
              >
                Salvar edição
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
