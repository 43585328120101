/** @format */

import { Edit, Undo } from "@mui/icons-material";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { ActionAproved } from "../../../../../components/actionApproved";
import { ActionDisapproved } from "../../../../../components/actionDisapproved";
import { ActionResend } from "../../../../../components/actionResend";
import { ActionReturned } from "../../../../../components/actionReturned";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { StatusPermittedByRoleContainer } from "../../../../../components/statusPermittedByRoleContainer";
import {
  ClearCurrency,
  FormatCurrency,
} from "../../../../../helpers/formatCurrency";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PutInstante } from "../../../../../services/api";
import { HistoryComponent } from "../helpers/historyComponent";
import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";

const validationSchema = yup.object({
  initial_date: yup
    .date()
    .min(new Date(), "Data é igual ou anterior a data de hoje")
    .nullable()
    .required("Data é obrigatório"),
  job_role_id: yup.string().nullable().required("Posição é obrigatório"),
  job_role_group_id: yup.string().nullable().required("Cargo é obrigatório"),
  salary: yup.string().nullable().required("Remuneração é obrigatório"),
  observations: yup.string().nullable().required("Informação é obrigatório"),
});

export function PersonalDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [valide, setValide] = useState(false);
  const [data, setData]: any = useState({
    options: { job_role: [], job_role_group: [] },
    collaborator_data: {},
    solicitation_data: {},
  });

  const user = useSelector((state: any) => state.user.data);
  const permissions = useSelector((state: any) => state.user.permissions);

  const isResendable =
    data?.solicitation_data.status === "returned" &&
    user.id === data.record.created_by_id;

  const edit =
    (permissions !== null &&
      permissions.indexOf("requestPersonalDetailsEdit") !== -1 &&
      data?.solicitation_data.status === "pending") ||
    isResendable;

  const formik: any = useFormik({
    initialValues: {
      job_role_id: "",
      job_role_group_id: "",
      salary: "",
      initial_date: null,
      position_id: "",
      observations: "",
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setValide(false)
      dispatch(updateLoading(true));
      PutInstante(
        id,
        {
          ...values,
          salary: ClearCurrency(values.salary),
        },
        "/personnel_requisitions"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          window.history.back();
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance({}, "/personnel_requisitions/" + id)
      .then(async (response: any) => {
        dispatch(updateLoading(false));
        const data = response.data.data;
        setData(data);
        const initial_date = dayjs(data.record.initial_date);
        formik.setValues({
          job_role_id: data.record.job_role_id,
          job_role_group_id: data.record.job_role_group_id,
          salary: FormatCurrency({
            target: {
              value: `${data.record.salary}` || "0",
            },
          }),
          initial_date,
          position_id: data.position.id,
          observations: data.record.observations,
        });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const resend = (resendActionCallback: any) => {
    dispatch(updateLoading(true));
    PutInstante(
      id,
      {
        ...formik.values,
        salary: ClearCurrency(formik.values.salary),
      },
      "/personnel_requisitions"
    )
      .then(() => {
        resendActionCallback();
        dispatch(updateLoading(false));
        window.history.back();
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Início" },
            { label: "Requerimentos", value: "/request/personal" },
            { label: "Pessoal", value: "/request/personal" },
            { label: "Ver solicitação" },
          ]}
        />
        <InfoComponent solicitation={data.solicitation_data} />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Detalhes da requisição de pessoal
              </Typography>
            </Grid>
            <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
              <TextField
                fullWidth
                disabled
                label="Posição"
                value={`${data.position?.code} ${data.position?.job_role_name}`}
              />
              <AutoCompleteField
                formik={formik}
                disabled
                id="job_role_id"
                label="Cargo"
                options={data?.options?.job_role}
              />
              <InputDateField
                disabled
                formik={formik}
                id="initial_date"
                label="Data prevista de início"
              />
              <InputCurrencyField
                disabled={!edit}
                formik={formik}
                id="salary"
                label="Remuneração"
              />
            </Grid>
            <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
              <InputTextField
                formik={formik}
                id="observations"
                label="Responsabilidades"
                multiline={true}
                rows={4}
              />
            </Grid>
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}
              >
                Voltar
              </ButtonComponent>

              {isResendable && (
                <ActionResend
                  id={id}
                  url={"personnel_requisitions"}
                  disabled={!formik.isValid}
                  onSubmit={resend}
                />
              )}

              {edit && (
                <ButtonComponent
                  variant={"contained"}
                  type={"submit"}
                  onClick={() => setValide(true)}
                  endIcon={<Edit />}
                >
                  Editar
                </ButtonComponent>
              )}

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                  "accounting_return",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}
              >
                <ActionReturned id={id} url={"personnel_requisitions"} />
                <ActionDisapproved id={id} url={"personnel_requisitions"} />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={[
                  "pending",
                  "waiting_manager_user",
                  "waiting_rh_user",
                ]}
                managerAllowedStatuses={["pending", "waiting_manager_user"]}
                status={data?.solicitation_data.status}
              >
                <ActionAproved id={id} url={"personnel_requisitions"} />
              </StatusPermittedByRoleContainer>

              <StatusPermittedByRoleContainer
                rhAllowedStatuses={["accounting_return"]}
                status={data?.solicitation_data.status}
              >
                <ActionResend
                  id={id}
                  url={"personnel_requisitions"}
                  hasObservation
                  disabled={!formik.isValid}
                  onSubmit={resend}
                />
              </StatusPermittedByRoleContainer>
            </Grid>

            <HistoryComponent
              data={data?.solicitation_data?.solicitation_histories}
              options={data?.options}
            />
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
