/** @format */

import { Grid } from "@mui/material";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { aviso_e_social } from "../../../../../config/constants";
import { PostInstante } from "../../../../../services/api";

export const Form = ({ formik, options, edit }: any) => {
  return (
    <>
      <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
        <InputDateField
          formik={formik}
          id='dismissal_date'
          label='Data de saída'
          onBlur={async (event) => {
            if (event.target.value) {
              const { data } = await PostInstante(
                null,
                { dismissal_date: event.target.value },
                "/dismissals/validate_date"
              );
              if (!data.data.success) {
                formik.setErrors({
                  dismissal_date:
                    "A data de desligamento excede o limite permitido de D+1. Por favor, contate o departamento de RH para proceder com o desligamento retroativo.",
                });
              }
            }
          }}
          disabled={edit}
        />
        <SelectField
          formik={formik}
          id='early_warning'
          label='Vai cumprir aviso prévio'
          options={aviso_e_social}
          disabled={edit}
        />
      </Grid>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <AutoCompleteField
          formik={formik}
          id='dismissal_type_id'
          disabled={edit}
          labelcode={true}
          label='Tipo de desligamento'
          options={options.dismissal_types.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))}
        />
        <AutoCompleteField
          formik={formik}
          id='dismissal_reason_id'
          disabled={edit}
          labelcode={true}
          label='Motivo do desligamento'
          options={options.dismissal_reasons.map((option: any) => ({
            value: option.id,
            label: option.name,
          }))}
        />
        <InputTextField
          formik={formik}
          multiline={true}
          disabled={edit}
          rows={4}
          id='comment'
          label='Comentários'
        />
      </Grid>
    </>
  );
};
