/** @format */
import { SentimentVerySatisfied } from "@mui/icons-material";
import { Chip, Grid, Typography, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BreadcrumbsComponent } from "../../../components/breadcrumbsComponent";
import { actionsFast } from "../../../config/actionsFast";
import { actionsFastTeam } from "../../../config/actionsFastTeam";
import { EmptyData } from "../../../helpers/emptyData";
import { FormatDate } from "../../../helpers/formatDate";
import { FormatStatus } from "../../../helpers/formatStatus";
import { PostInstante } from "../../../services/api";
import { DialogDismissalNew } from "../request/dismissals/helpers/dialogDismissalNew";

export function Home() {
  const theme = useTheme();
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  const photo = useSelector((state: any) => state.user.photo);
  const permissions = useSelector((state: any) => state.user.permissions);
  const delegation = useSelector((state: any) => state.user.delegation_data);
  const [openModalDismissal, setOpenModalDismissal] = useState({
    open: false,
    type: "",
    date: "",
  });

  return (
    <Grid container>
      <Grid container>
        {openModalDismissal.open && user.role !== "rh" && (
          <DialogDismissalNew
            {...openModalDismissal}
            close={() =>
              setOpenModalDismissal({ open: false, type: "", date: "" })
            }
          />
        )}

        <BreadcrumbsComponent links={[{ label: "Inicio", value: "/home" }]} />
      </Grid>
      <Grid container flexDirection={"row"} alignItems={"center"} gap={1}>
        <Typography component={"span"} fontSize={24} fontWeight={800}>
          {!delegation?.delegated_session
            ? "Bem vindo de volta!"
            : "Você está como delegado do perfil!"}
        </Typography>
        <SentimentVerySatisfied color={"primary"} />
      </Grid>
      <Grid container mt={6} justifyContent={"space-between"}>
        <Grid item>
          <Grid container alignItems='center' gap={4}>
            <Grid
              item
              style={{
                width: "140px",
                height: "140px",
                background: `linear-gradient(to bottom left, transparent 0%, ${theme.palette.primary.main} 150%)`,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                borderRadius: "50%",
              }}>
              <Grid
                item
                style={{
                  width: "100px",
                  height: "100px",
                  background: `linear-gradient(to top right, transparent 0%, ${theme.palette.primary.main} 200%)`,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "50%",
                }}>
                <Avatar
                  alt={user?.social_name ?? user?.name}
                  src={photo ?? "/image.png"}
                  sx={{
                    width: 128,
                    height: 128,
                    bgcolor: theme.palette.primary.main,
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container flexDirection={"column"}>
                <Typography component={"span"} fontSize={22}>
                  Olá,
                  <strong> {user?.social_name ?? user?.name}</strong>
                </Typography>
                <Grid item>
                  <Chip
                    size={"small"}
                    color={user?.status === "active" ? "success" : "error"}
                    label={FormatStatus(user?.status)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          sx={{
            [theme.breakpoints.down("xl")]: {
              paddingTop: "48px",
              width: "inherit",
            },
          }}>
          {!delegation?.delegated_session && (
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <Grid
                container
                p={4}
                gap={8}
                borderRadius={1}
                style={{ border: `1px solid ${theme.palette.primary.main}` }}>
                <Grid item>
                  <Typography fontSize={16} fontWeight={800}>
                    Matrícula
                  </Typography>
                  <Typography fontSize={12}>
                    {EmptyData(user?.identification)}
                  </Typography>
                </Grid>
                {user?.manager_id && (
                  <Grid item>
                    <Typography fontSize={16} fontWeight={800}>
                      Superior matrícula
                    </Typography>
                    <Typography fontSize={12}>
                      {EmptyData(user?.manager_id)}
                    </Typography>
                  </Grid>
                )}
                {user?.business_unit_hire_date && (
                  <Grid item>
                    <Typography fontSize={16} fontWeight={800}>
                      Data de admissão
                    </Typography>
                    <Typography fontSize={12}>
                      {EmptyData(user?.business_unit_hire_date)}
                    </Typography>
                  </Grid>
                )}
                {user?.contract_status && (
                  <Grid item>
                    <Typography fontSize={16} fontWeight={800}>
                      Contrato
                    </Typography>
                    <Typography fontSize={12}>
                      {EmptyData(user?.contract_status)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!delegation?.delegated_session && (
        <Grid container mt={6} flexDirection={"column"}>
          <Typography fontSize={24} fontWeight={800}>
            Suas ações
          </Typography>
          <Grid container gap={2} mt={4}>
            {actionsFast.map(
              (item, index) =>
                permissions !== null &&
                permissions.indexOf(item.id) !== -1 && (
                  <Grid
                    item
                    sx={{
                      [theme.breakpoints.down("xl")]: {
                        width: "inherit",
                      },
                    }}>
                    <Card
                      variant='outlined'
                      sx={{
                        width: 200,
                        transition: "transform 0.3s ease",
                        "&:hover": {
                          transform: "translateY(-10px)",
                          borderColor: theme.palette.primary.main,
                        },
                        [theme.breakpoints.down("xl")]: {
                          width: "inherit",
                        },
                      }}
                      onClick={() => {
                        const id = item.user ? user.uuid : "";
                        if (
                          item.id === "requestLeave" &&
                          user.status === "on_leave"
                        ) {
                          PostInstante(
                            null,
                            null,
                            `/users/${user.uuid}/user_absence_id`
                          ).then((res) => {
                            router(`/request/leave/details/${res.data.uuid}`);
                          });
                          return;
                        }
                        if (item.id === "requestDismissals") {
                          if (
                            new Date(user.business_unit_job_security_date) >=
                            new Date()
                          ) {
                            setOpenModalDismissal({
                              open: true,
                              type: user.business_unit_job_security_type,
                              date: FormatDate(
                                user.business_unit_job_security_date
                              ),
                            });
                          } else {
                            router(item.url + id);
                          }
                          return;
                        }
                        router(item.url + id);
                      }}>
                      <CardContent>
                        {item.icon}
                        <Typography fontSize={16} fontWeight={800}>
                          {item.title}
                        </Typography>
                        <Typography fontSize={12} color='text.secondary'>
                          {item.description}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                )
            )}
          </Grid>
        </Grid>
      )}
      {user.role !== "collaborator" &&
        Array.isArray(permissions) &&
        actionsFastTeam.filter((item: any) => permissions.includes(item.id))
          .length > 0 && (
          <Grid container mt={4} flexDirection={"column"}>
            <Typography fontSize={24} fontWeight={800}>
              Ações rápidas para sua equipe
            </Typography>
            <Grid container gap={2} mt={4}>
              {actionsFastTeam.map(
                (item, index): any =>
                  permissions !== null &&
                  ((item.delegation === false &&
                    delegation.delegated_session === true) ||
                    delegation.delegated_session === false) &&
                  permissions.indexOf(item.id) !== -1 && (
                    <Grid
                      item
                      sx={{
                        [theme.breakpoints.down("xl")]: {
                          width: "inherit",
                        },
                      }}>
                      <Card
                        variant='outlined'
                        sx={{
                          width: 200,
                          transition: "transform 0.3s ease",
                          "&:hover": {
                            transform: "translateY(-10px)",
                            borderColor: theme.palette.primary.main,
                          },
                          [theme.breakpoints.down("xl")]: {
                            width: "inherit",
                          },
                        }}
                        onClick={() => {
                          const id = item.user ? user.uuid : "";
                          router(item.url + id);
                        }}>
                        <CardContent>
                          {item.icon}
                          <Typography fontSize={16} fontWeight={800}>
                            {item.title}
                          </Typography>
                          <Typography fontSize={12} color='text.secondary'>
                            {item.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  )
              )}
            </Grid>
          </Grid>
        )}
    </Grid>
  );
}
