/**
 * eslint-disable array-callback-return
 *
 * @format
 */

/** @format */

import { AddCircle, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { BreadcrumbsComponent } from "../../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../../components/buttonComponent";
import { InfoComponent } from "../../../../../components/infoComponent";
import { FormatDate } from "../../../../../helpers/formatDate";
import { GenericError } from "../../../../../helpers/genericError";
import { updateLoading } from "../../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { Form } from "../helpers/form";

export function VacationNew() {
  const { id } = useParams();
  const [user, setUser]: any = useState({ vacations: [{}], vacationsOpen: [] });
  const [valide, setValide] = useState(false);
  const userData: any = useSelector((state: any) => state?.user?.data);
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      vacation_items: [
        {
          start_date: null,
          end_date: null,
          days_quantity: "",
          thirteen_anticipation: false,
          abono_pecuniario: false,
          payment_date: null,
          description: "",
        },
      ],
    },
    validateOnBlur: valide,
    validateOnChange: valide,
    validationSchema: validationSchema(user, userData),
    onSubmit: async (values: any) => {
      const sendValues = values;
      // eslint-disable-next-line array-callback-return
      await sendValues.vacation_items.map((item: any) => {
        item.days_quantity = Number(item.days_quantity);
        item.abono_quantity = item.abono_pecuniario
          ? sendValues.abono_quantity
          : 0;
      });
      dispatch(updateLoading(true));
      PostInstante(
        null,
        {
          ...sendValues.vacation_items[0],
          vacation_uuid: sendValues.uuid,
        },
        "/vacation_items"
      )
        .then((response: any) => {
          dispatch(updateLoading(false));
          window.history.back();
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    if (id !== undefined) {
      dispatch(updateLoading(true));
      GetInstance({}, "/vacation_items_form?user_uuid=" + id)
        .then(async (response: any) => {
          dispatch(updateLoading(false));
          if (response.data.data.available_vacation === null) {
            window.history.back();
            toast.error("Não existem periodo de férias.");
            return;
          }
          setUser({
            vacations_custom_days_abono:
              response.data.data.company_rules.vacations_rules
                ?.vacations_custom_days_abono ?? null,
            ...response.data.data.user_data,
            ...response.data.data.available_vacation,
            vacations: [response.data.data.available_vacation],
            vacationsOpen: response.data.data.other_open_vacations ?? [],
          });
          response.data.data.available_vacation.concessive_period_start =
            new Date(
              response.data.data.available_vacation.concessive_period_start
            );
          response.data.data.available_vacation.concessive_period_end =
            new Date(
              response.data.data.available_vacation.concessive_period_end
            );
          formik.setValues({
            ...formik.values,
            days: response.data.data.available_vacation.days_available,
            ...response.data.data.available_vacation,
            vacations_request_minimum_days:
              userData?.role === "rh" && userData?.id !== user?.target_user_id
                ? 0
                : response.data.data.company_rules
                    .vacations_request_minimum_days,
          });
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Solicitações", value: "/request/vacation" },
            { label: "Férias", value: "/request/vacation" },
            { label: "Nova solicitação" },
          ]}
        />
        <InfoComponent
          user={user}
          vacation={{ opt: user.vacations, formik }}
          otherVacations={user.vacationsOpen}
          instructionMap={[
            "Sempre que houver mais de 1 periodo, o mais antigo sempre é o disponivel para programação.",
            "Mínimo de 5 dias para cada período.",
            "Se dividir o saldo em 3 períodos, pelo menos um deve ter 14 dias ou mais.",
            "Ao selecionar 'Sim' para abono pecuniário, 1/3 dos dias disponíveis será convertido em remuneração, e o saldo será reduzido proporcionalmente.",
            "As férias serão definidas pela disponibilidade de dias, sendo seu início dois dias antes de um feriado ou do Descanso Semanal Remunerado (DSR).",
            userData?.role !== "rh" || userData?.id === user?.target_user_id
              ? `As férias devem ser solicitadas com pelo menos ${formik.values?.vacations_request_minimum_days} dias de antecedência.`
              : "As férias podem ser criadas a qualquer momento pelo seu perfil.",
          ]}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid>
              <Typography fontSize={18} fontWeight={500}>
                Criar programação para o período aquisitivo (
                {FormatDate(user.vacations[0]?.acquisition_period_start) +
                  " - " +
                  FormatDate(user.vacations[0]?.acquisition_period_end)}
                )
              </Typography>
            </Grid>
            <Form formik={formik} user={user} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  window.history.back();
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              <ButtonComponent
                variant={"contained"}
                onClick={() => {
                  setValide(true);
                  formik.handleSubmit();
                }}
                endIcon={<AddCircle />}>
                Solicitar
              </ButtonComponent>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
