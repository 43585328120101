/** @format */

import { TextField, TextFieldProps } from "@mui/material";
import { FormatCurrency } from "../../helpers/formatCurrency";

interface FieldProps extends Omit<TextFieldProps, "ref"> {
  formik: any;
  id: string;
  label: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

export function InputCurrencyField(props: FieldProps) {
  const { formik, id, label, disabled, onChange } = props;
  return (
    <TextField
      {...props}
      fullWidth
      id={id}
      disabled={disabled}
      name={id}
      placeholder='R$ 0,00'
      label={label}
      value={formik.values[id]}
      onChange={(event: any) => {
        formik.setFieldValue(id, FormatCurrency(event));
        onChange && onChange(event);
      }}
      onBlur={formik.handleBlur}
      error={Boolean(formik.errors[id])}
      helperText={formik?.errors[id]}
    />
  );
}
