/** @format */

import { Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { ButtonComponent } from "../../../../../components/buttonComponent";

export function EmptyForm() {
  return (
    <Grid pt={5}>
      <Typography align='center' fontSize={18} fontWeight={500}>
        Não existe campos para serem alterados.
      </Typography>
      <Grid gridTemplateColumns={"1fr"} mt={2} display={"grid"} gap={2}>
        <Grid container gap={2} justifyContent={"end"}>
          <ButtonComponent
            variant={"outlined"}
            onClick={() => {
              window.history.back();
            }}
            endIcon={<Undo />}>
            Voltar
          </ButtonComponent>
        </Grid>
      </Grid>
    </Grid>
  );
}
