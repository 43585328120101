/** @format */

import { toast } from "react-toastify";
import { updateLoading, updateLoadingSessao } from "../redux/reducers/common";

export const GenericError = (
  e: any,
  dispatch?: any,
  messageToast: boolean = true
) => {
  if (e.code !== "ERR_CANCELED") {
    if (dispatch) {
      dispatch(updateLoading(false));
      dispatch(updateLoadingSessao(false));
    }
    if (messageToast) {
      try {
        toast.error(
          e?.response?.data?.message?.error || e?.response?.data?.message
        );
      } catch {
        toast.error("Erro inesperado, tente novamente.");
      }
    }
  }
};
