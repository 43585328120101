/** @format */

import { Drafts, Visibility } from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { ActionRemove } from "../../../../components/actionRemove";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { optionsTable } from "../../../../config/optionsTable";
import { IsPermissions } from "../../../../helpers/isPermission";
import useSettingsLetter from "../../../../services/protected/hooks/useSettingsLetter";
import { columns } from "./helpers/constants";
import { Filters } from "./helpers/filters";

export function LetterRequest() {
  const router = useNavigate();
  const formik: any = useFormik({
    initialValues: {
      solicitation_type: "all",
      active: "all",
    },
    onSubmit: () => {
      setReset(true);
    },
  });
  const {
    data,
    total,
    page,
    perPage,
    setReset,
    searchCall,
    setPage,
    setPerPage,
    setOrder,
  } = useSettingsLetter(formik.values);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <Grid container gap={2}>
      <BreadcrumbsComponent
        links={[
          { label: "Inicio" },
          { label: "Configurações", value: "/settings/letter" },
          { label: "Cartas" },
        ]}
        buttons={
          <ButtonComponent
            variant={"contained"}
            onClick={() => {
              router("/settings/letter/new");
            }}
            endIcon={<Drafts />}>
            Novo modelo de carta
          </ButtonComponent>
        }
      />
      <Filters formik={formik} />
      <MUIDataTable
        title={""}
        data={data}
        columns={[
          {
            name: "action",
            label: "Ações",
            options: {
              sort: false,
              customBodyRenderLite: (dataIndex: number) => {
                return (
                  <Grid container>
                    <IsPermissions keyPrivate={"permissionLetterDetails"}>
                      <Tooltip title={"Ver carta"}>
                        <IconButton
                          onClick={() =>
                            router(
                              `/settings/letter/details/${data[dataIndex].uuid}`
                            )
                          }>
                          <Visibility color='primary' />
                        </IconButton>
                      </Tooltip>
                    </IsPermissions>
                    <ActionRemove
                      statusList={data[dataIndex].solicitation_status}
                      url='/letter'
                      id={data[dataIndex].uuid}
                    />
                  </Grid>
                );
              },
            },
          },
          ...columns,
        ]}
        options={{
          ...optionsTable,
          searchPlaceholder: "Pesquise por nome",
          download: false,
          onSearchChange: (value: string) => {
            searchCall(value);
          },
          onColumnSortChange: (value: string, direction: string) => {
            setOrder(`${value} ${direction}`);
          },
          count: total,
          page,
          onChangePage: (page: number) => {
            setPage(page);
          },
          rowsPerPage: perPage,
          onChangeRowsPerPage: (perPage: number) => {
            setPage(0);
            setPerPage(perPage);
          },
        }}
      />
    </Grid>
  );
}
