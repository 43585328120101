/** @format */

import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { ButtonComponent } from "./buttonComponent";
import { toast } from "react-toastify";

function FormatValue(values: any, keys: any) {
  let returnString = "";
  // eslint-disable-next-line array-callback-return
  Object.entries(values).map((item: any) => {
    const keyObj: any = Object.entries(keys).filter(
      (obj: any) => obj[0] === item[0]
    )[0][1];
    const key = keyObj?.label ?? "";
    let value = item[1].toString();
    if (dayjs(value).isValid()) {
      value = dayjs(value).format("DD/MM/YYYY") ?? "-";
    }
    if (keyObj.options) {
      value =
        keyObj.options.filter((item: any) => item.value.toString() === value)[0]
          ?.label ?? "";
    }
    if (value.length > 0)
      returnString += ` ${key}: ${value};`;
  });
  return returnString;
}

interface FilterProps {
  formik: any;
  children: any;
  keys: any;
  id: string;
  buttonLabel?: string;
}

export function Filter({ formik, children, keys, id, buttonLabel }: FilterProps) {
  const [active, setActive] = useState(formik.values);

  function onSubmit() {
    if (
      (formik.values?.from && new Date(formik.values?.from).toDateString() === "Invalid Date") ||
      (formik.values?.to && new Date(formik.values?.to).toDateString() === "Invalid Date")
    ) {
      toast.info("Os campos precisam ser preenchidos corretamente.");
    } else {
      formik.handleSubmit();
    }
  }

  function onReset() {
    formik.handleReset();
    formik.handleSubmit();
    Cookies.set("filters_", JSON.stringify(formik.initialValues));
    setActive(formik.initialValues);
  }

  useEffect(() => {
    if (Cookies.get("filters_" + id)) {
      const filters = JSON.parse(Cookies.get("filters_" + id) || "{}");
      formik.setValues(filters);
      setActive(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formik?.isValid && formik?.isSubmitting) {
      Cookies.set("filters_" + id, JSON.stringify(formik.values));
      setActive(formik.values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.isSubmitting]);

  return formik.values !== undefined ? (
    <Grid container gap={2}>
      <Grid
        gap={2}
        width={"100%"}
        flexDirection={isMobile ? "column" : "row"}
        display={"flex"}>
        <Grid
          width={"100%"}
          display={"grid"}
          gap={2}
          gridTemplateColumns={
            isMobile
              ? "1fr"
              : children.length === 1
                ? "1fr"
                : children.length === 2
                  ? "1fr 1fr"
                  : children.length === 3
                    ? "1fr 1fr 1fr"
                    : "1fr 1fr 1fr 1fr"
          }>
          {children}
        </Grid>
        <Grid display={"flex"}>
          <ButtonComponent variant='contained' onClick={onSubmit}>
            {buttonLabel ?? 'Filtrar'}
          </ButtonComponent>
        </Grid>
      </Grid>
      {FormatValue(active, keys).length > 0 &&
        <Grid container alignItems={"center"}>
          <Typography fontSize={12} fontWeight={400}>
            Filtros ativos: <strong>{FormatValue(active, keys)}</strong>
          </Typography>
          <ButtonComponent
            style={{ marginLeft: 8 }}
            size='small'
            variant='text'
            onClick={onReset}>
            Limpar filtros
          </ButtonComponent>
        </Grid>}
    </Grid>
  ) : (
    <></>
  );
}
