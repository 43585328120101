export type Solicitation = {
    id: string;
    label: string;
};

export const SOLICITATIONS = [
    { id: "salary_review", label: "Revisão Salarial" },
    { id: "promote_user", label: "Promoção de Colaborador" },
    { id: "transfer", label: "Transferência" },
    { id: "absence", label: "Ausência" },
    { id: "promotion", label: "Promoção" },
    { id: "dismissal", label: "Desligamento" },
    { id: "personnel_requisition", label: "Requisição de Pessoal" },
    { id: "user_update_request", label: "Atualização de Usuário" },
    { id: "vacation_item", label: "Férias" },
    { id: "delegation", label: "Delegação" },
    { id: "dismissal_request", label: "Solicitação de Desligamento" },
];