/** @format */

export const fields: {
  label: string;
  value: string;
  notRequiredCheck?: boolean;
  hidden?: boolean;
}[] = [
    { value: "dependents", label: "Dependentes" },
  { value: "phone", label: "Telefone" },
  { value: "description", label: "Descrição do grau de parentesco" },
  { value: "relationship_code", label: "Código do grau de parentesco" },
  { value: "address", label: "Endereço" },
  { value: "bank", label: "Dados bancários" },
  { value: "health_insurance_id", label: "Plano de saúde" },
  { value: "health_insurance_price", label: "Valor do plano de saúde" },
  { value: "food_voucher_id", label: "Vale alimentação" },
  { value: "food_voucher_price", label: "Valor do vale alimentação" },
  { value: "is_basic_basket_option", label: "Cesta básica" },
  { value: "job_role_group_id", label: "Departamento" },
  { value: "job_role_id", label: "Cargo" },
  { value: "remuneration_value", label: "Salário" },
  { value: "manager_id", label: "Gestor" },
  { value: "remuneration_trust_position_amount", label: "Cargo confiança(R$)" },
  { value: "department_id", label: "Departamento" },
  {
    value: "remuneration_trust_position_percentage",
    label: "Cargo confiança(%)",
  },
  { value: "salary_deduction", label: "Pgto desc. salário" },
  { value: "is_commissioned", label: "Comissionado" },
  { value: "union_id", label: "Sindicato" },
  { value: "join_to_union", label: "Filiado sindicato" },
  { value: "deduct_union_dues", label: "Descontar contribuição sindical?" },
  {
    value: "deduct_federation_dues",
    label: "Descontar contribuição federativa?",
  },
  { value: "deduct_salary_reversion", label: "Descontar reversão salárial?" },
  {
    value: "contribution_starting_from",
    label: "Data inicio contribuição sindical",
  },
  { value: "identification", label: "Matrícula" },
  { value: "username", label: "Username" },
  {
    value: "matricula_esocial",
    label: "Matrícula eSocial",
    notRequiredCheck: true,
  },
  { value: "email", label: "Email corporativo" },
  { value: "cpf", label: "CPF" },
  { value: "corporate_phone", label: "Telefone corporativo" },
  { value: "name", label: "Nome" },
  { value: "rg", label: "RG" },
  { value: "birth_date", label: "Data de nascimento" },
  { value: "social_name", label: "Nome social" },
  { value: "marital_status", label: "Estado civil" },
  { value: "race", label: "Raça" },
  { value: "education_level", label: "Grau de instrução" },
  { value: "gender", label: "Sexo" },
  { value: "phone", label: "Telefone" },
  { value: "nationality", label: "Nacionalidade" },
  { value: "is_retired", label: "Aposentado" },
  { value: "personal_email", label: "Email pessoal" },
  { value: "criminal_record", label: "Antec. criminais" },
  { value: "court_license", label: "Alvará judicial" },
  { value: "process", label: "Processo judicial" },
  { value: "is_disability", label: "PCD" },
  { value: "type_of_disability", label: "Tipos do PCD" },
  { value: "postal_code", label: "CEP", hidden: true },
  { value: "street", label: "Logradouro", hidden: true },
  { value: "number", label: "Número residencial", hidden: true },
  { value: "complement", label: "Complemento residencial", hidden: true },
  { value: "neighborhood", label: "Bairro", hidden: true },
  { value: "city", label: "Cidade", hidden: true },
  { value: "state", label: "UF", hidden: true },
  { value: "country", label: "País de nascimento" },
  { value: "state_of_birth", label: "Estado de nascimento" },
  { value: "city_of_birth", label: "Cidade de nascimento" },
  { value: "father_name", label: "Nome do pai" },
  { value: "father_cpf", label: "CPF do pai" },
  { value: "father_country_of_birth", label: "País de nascimento do pai" },
  { value: "paternal_grandmother_name", label: "Avó paterna" },
  { value: "paternal_grandfather_name", label: "Avô paterno" },
  { value: "mother_name", label: "Nome da mãe" },
  { value: "mother_cpf", label: "CPF da mãe" },
  { value: "mother_country_of_birth", label: "País de nascimento da mãe" },
  { value: "maternal_grandmother_name", label: "Avó materna" },
  { value: "maternal_grandfather_name", label: "Avô materno" },
  { value: "is_naturalized", label: "Naturalizado" },
  { value: "arrival_in_brazil_date", label: "Data de chegada ao Brasil" },
  { value: "naturalization_date", label: "Data de naturalização" },
  { value: "married_to_a_brazilian", label: "Casado com Brasileiro(a)" },
  { value: "has_brazilian_children", label: "Possui filhos no Brasil" },
  { value: "bank_code", label: "Código do banco", hidden: true },
  { value: "bank_account_type", label: "Tipo da conta", hidden: true },
  { value: "bank_agency", label: "Agência com DV", hidden: true },
  { value: "bank_account", label: "Conta com DV", hidden: true },
  { value: "business_unit_id", label: "Unidade de negócio" },
  { value: "sector_id", label: "Setor" },
  { value: "work_section_id", label: "Seção" },
  { value: "cost_center_id", label: "Centro de custo" },
  { value: "managerial_cost_center_id", label: "Centro de custo gerencial" },
  { value: "category_id", label: "Categoria do colaborador" },
  { value: "business_unit_contract_type", label: "Tipo de contrato" },
  { value: "work_schedule_id", label: "Horário de trabalho" },
  { value: "business_unit_job_security_type", label: "Tipo de estabilidade" },
  {
    value: "business_unit_job_security_date",
    label: "Data garantia estabilidade",
  },
  { value: "business_unit_employment_bond", label: "Contrato determ. térm." },
  { value: "staffing_number_date", label: "Lotação organ. período" },
  { value: "business_unit_hire_date", label: "Data de admissão" },
  { value: "business_unit_exp_extension", label: "Data exp. prorrogação" },
  { value: "business_unit_exp_finish_date", label: "Data exp. término" },
  { value: "business_unit_leave_date", label: "Data de afastamento" },
  { value: "business_unit_contract_finish", label: "Data de desligamento" },
  { value: "contract_status", label: "Status do contrato" },
  { value: "contract_cbo", label: "CBO número" },
  { value: "contract_nature", label: "Natureza do contrato" },
  { value: "contract_salary", label: "Tipo de salário" },
  {
    value: "salary_payment_method_id",
    label: "Método de Pagamento do Salário",
  },
  { value: "contract_base_salary", label: "Salário base" },
  { value: "is_pregnant_apprentice", label: "Aprendiz grávida" },
  { value: "work_from_home", label: "Teletrabalho" },
  { value: "admission_type", label: "Tipo de admissão" },
  { value: "social_security_regime", label: "Regime Previdenciário" },
  { value: "overtime_type", label: "Tipo de Horas Extras" },

  { value: "general_registry_number", label: "RG" },
  {
    value: "general_registry_issuing_authority",
    label: "RG - Orgão de expedição",
  },
  { value: "general_registry_federal_unit", label: "RG - Estado de expedição" },
  { value: "general_registry_date", label: "RG - Data de expedição" },
  { value: "civil_id_register_number", label: "RIC" },
  {
    value: "civil_register_issuing_authority",
    label: "RIC - Orgão de expedição",
  },
  { value: "civil_id_register_date", label: "RIC - Data de expedição" },
  { value: "national_foreigner_registry_number", label: "RNE" },
  {
    value: "national_foreigner_registry_issuing_authority",
    label: "RNE - Orgão de expedição",
  },
  {
    value: "national_foreigner_registry_date",
    label: "RNE - Data de expedição",
  },
  { value: "reservist_number", label: "Certificado de reservista" },
  {
    value: "reservist_date_of_issuance",
    label: "Certificado de reservista - Data de emissão",
  },
  { value: "voter_card_number", label: "Título de eleitor" },
  { value: "voter_card_zone", label: "Título de eleitor - Zona" },
  { value: "voter_card_section", label: "Título de eleitor - Seção" },
  { value: "work_permit_number", label: "Carteira de trabalho" },
  { value: "work_permit_series", label: "Carteira de trabalho - Série" },
  {
    value: "work_permit_federal_unit",
    label: "Carteira de trabalho - Estado de expedição",
  },
  { value: "social_integration_program_number", label: "PIS/PSET/NIT" },
  { value: "social_integration_program_date", label: "PIS/PSET/NIT - Data" },
  { value: "driver_license_registration_number", label: "CNH" },
  {
    value: "driver_license_issuing_authority",
    label: "CNH - Orgão de expedição",
  },
  { value: "driver_license_date_of_issuance", label: "CNH - Data de emissão" },
  { value: "driver_license_due_date", label: "CNH - Data de validade" },
  { value: "driver_license_category", label: "CNH - Categoria" },
  { value: "driver_license_quantity", label: "CNH - Nível" },
  { value: "driver_license_federal_unit", label: "CNH - Estado de expedição" },
  { value: "oc_number", label: "CNH - Orgão de classe" },
  {
    value: "oc_issuing_authority",
    label: "CNH - Orgão de classe - Orgão de expedição",
  },
  {
    value: "oc_federal_unit",
    label: "CNH - Orgão de classe - Estado de expedição",
  },
  {
    value: "oc_date_of_issuance",
    label: "CNH - Orgão de classe - Data de expedição",
  },
  {
    value: "mopp_number",
    label: "CNH - Movimentação Operacional de Produtos Perigosos",
  },
  {
    value: "mopp_due_date",
    label:
      "CNH - Movimentação Operacional de Produtos Perigosos - Data de validade",
  },
  { value: "degree_of_kinship", label: "Parentesco" },
  { value: "family_salary", label: "Renda familiar" },
  { value: "ir", label: "Imposto de renda" },

  { value: "emergency_contacts", label: "Contatos de emergência" },
];
