/** @format */

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { TabsComponent } from "./tabsComponent";

interface Tab {
  component: React.ReactNode;
  type: "general_records" | "team_records" | "my_records";
  label?: string;
}

interface Tabs {
  tabs: Tab[];
}

function FormatLabel(label: string): string {
  switch (label) {
    case "general_records":
      return "Solicitações recebidas";
    case "team_records":
      return "Solicitações do meu time";
    case "my_records":
      return "Minhas solicitações";
    default:
      return "";
  }
}

export function TabsTableComponent({ tabs }: Tabs): any {
  const [tabsRole, setTabsRole] = React.useState<any>([]);
  const user = useSelector((state: any) => state.user.data);

  useEffect(() => {
    const tabsNew: Tab[] = [];
    tabs.forEach((tab) => {
      if (user.role === "manager") {
        switch (tab.type) {
          case "team_records":
          case "my_records":
            tabsNew.push({ ...tab, label: FormatLabel(tab.type) });
            break;
        }
      } else if (user.role === "accountant") {
        switch (tab.type) {
          case "general_records":
          case "my_records":
            tabsNew.push({ ...tab, label: FormatLabel(tab.type) });
            break;
        }
      } else {
        tabsNew.push({ ...tab, label: FormatLabel(tab.type) });
      }
    });
    setTabsRole(tabsNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  if (user.role === "collaborator") {
    return tabs.find((tab) => tab.type === "my_records")?.component ?? null;
  }

  return <TabsComponent tabs={tabsRole} />;
}
