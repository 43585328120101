/** @format */

import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import { aviso_e_social } from "../../../../../config/constants";
import {
  HiddenCheck,
  HiddenCheckChildren,
} from "../../../../../helpers/hiddenCheck";

export function Remuneration({ formik, data }: any) {
  useEffect(() => {
    if (formik.values.union_id) {
      const unionData = data.options.union.filter(
        (item: any) => item.value === formik.values.union_id
      )[0];
      formik.setValues({
        ...formik.values,
        union_id_name: unionData.name,
        union_id_cnpj: unionData.cnpj,
        union_id_email: unionData.email,
        union_id_phone: unionData.phone,
        union_id_representative_name: unionData.representative_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.union_id]);

  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid>
          <Typography fontSize={16} fontWeight={500}>
            Estrutura
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='position_code'
              label='Código da posição'
              disabled
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='manager_id'
              label='Gestor'
              options={[{ label: "", value: null }, ...data.options.managers]}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='department_id'
              labelcode
              label='Departamento'
              options={data.options.departments}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='job_role_id'
              labelcode
              label='Cargo'
              options={data.options.job_role}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputCurrencyField
              formik={formik}
              id='remuneration_value'
              label='Salário base'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputCurrencyField
              formik={formik}
              id='remuneration_trust_position_amount'
              label='Cargo confiança(R$)'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='remuneration_trust_position'
              type='number'
              label='Cargo confiança(%)'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputCurrencyField
              formik={formik}
              id='salary_deduction'
              label='Pgto desc. salário'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              id='is_commissioned'
              label='Comissionado'
              options={aviso_e_social}
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>

      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Filiação sindicato
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              id='join_to_union'
              label='Deseja filiar-se'
              options={aviso_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              id='deduct_union_dues'
              label='Descontar contribuição sindical?'
              options={aviso_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              id='deduct_federation_dues'
              label='Descontar contribuição federativa?'
              options={aviso_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              id='deduct_salary_reversion'
              label='Descontar reversão salárial?'
              options={aviso_e_social}
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='contribution_starting_from'
              label='Data inicio contribuição'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Sindicato
          </Typography>
        </Grid>
        <Grid gridTemplateColumns={"1fr 1fr"} mt={2} display={"grid"} gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              formik={formik}
              id='union_id'
              label='Código'
              options={data.options.union}
            />
            <InputTextField
              formik={formik}
              label='Nome'
              disabled
              id='union_id_name'
            />
            <InputTextField
              formik={formik}
              label='Nome do representante'
              disabled
              id='union_id_representative_name'
            />
            <InputTextField
              formik={formik}
              label='Telefone'
              disabled
              id='union_id_phone'
            />
            <InputTextField
              formik={formik}
              label='CNPJ'
              disabled
              id='union_id_cnpj'
            />
            <InputTextField
              formik={formik}
              label='Email'
              disabled
              id='union_id_email'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
