/** @format */

import { Grid, Typography } from "@mui/material";
import { AutoCompleteField } from "../../../../../components/inputs/autoCompleteField";
import { InputCurrencyField } from "../../../../../components/inputs/inputCurrencyField";
import { InputDateField } from "../../../../../components/inputs/inputDateField";
import { InputTextField } from "../../../../../components/inputs/inputTextField";
import { SelectField } from "../../../../../components/inputs/selectField";
import {
  admission_type_e_social,
  aviso_e_social,
  contract_type_e_social,
  nature_e_social,
  overtime_type_e_social,
  salary_e_social,
  security_type_e_social,
  social_security_regime_e_social,
  status_e_social,
} from "../../../../../config/constants";
import {
  HiddenCheck,
  HiddenCheckChildren,
} from "../../../../../helpers/hiddenCheck";

export function Contract({ formik, data }: any) {
  return (
    <Grid container flexDirection={"column"}>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid>
          <Typography fontSize={16} fontWeight={500}>
            Contrato
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={status_e_social.map((item: any) => ({
                label: item,
                value: item,
              }))}
              label='Status'
              id='contract_status'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputTextField
              formik={formik}
              id='contract_cbo'
              label='CBO número'
              type='number'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={nature_e_social}
              label='Natureza'
              id='contract_nature'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={salary_e_social}
              label='Tipo de salário'
              id='contract_salary'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={data.options.salary_payment_method}
              label='Método de Pagamento do Salário'
              id='salary_payment_method_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputCurrencyField
              formik={formik}
              id='contract_base_salary'
              label='Salário base'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Unidade de negócio
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.business_unit}
              formik={formik}
              label='Código'
              labelcode
              id='business_unit_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.sector}
              formik={formik}
              label='Setor'
              labelcode
              id='sector_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.work_section}
              formik={formik}
              label='Seção'
              id='work_section_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.cost_center}
              formik={formik}
              label='Centro de custo'
              labelcode
              id='cost_center_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.managerial_cost_center}
              formik={formik}
              label='Centro de custo gerencial'
              labelcode
              id='managerial_cost_center_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.work_category}
              formik={formik}
              label='Categoria do colaborador'
              id='work_category_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={contract_type_e_social}
              formik={formik}
              label='Tipo de contrato'
              id='business_unit_contract_type'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={data.options.work_schedule}
              formik={formik}
              label='Horário de trabalho'
              id='work_schedule_id'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={security_type_e_social.map((item: any) => ({
                label: item,
                value: item,
              }))}
              formik={formik}
              label='Tipo de estabilidade'
              id='business_unit_job_security_type'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_job_security_date'
              label='Data garantia estabilidade'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_employment_bond'
              label='Contrato determ. térm.'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='staffing_number_date'
              label='Lotação organ. período'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_hire_date'
              label='Data de admissão'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_exp_extension'
              label='Data exp. prorrogação'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_exp_finish_date'
              label='Data exp. término'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_leave_date'
              label='Data de afastamento'
              disabled
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <InputDateField
              formik={formik}
              id='business_unit_contract_finish'
              label='Data de desligamento'
              disabled
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>

      <HiddenCheckChildren
        user_form_hidden_fields={data.user_form_hidden_fields}>
        <Grid mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Dados de trabalho
          </Typography>
        </Grid>
        <Grid
          gridTemplateColumns={"1fr 1fr 1fr 1fr"}
          mt={2}
          display={"grid"}
          gap={2}>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={aviso_e_social}
              label='Aprendiz grávida'
              id='is_pregnant_apprentice'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <SelectField
              formik={formik}
              options={aviso_e_social}
              label='Teletrabalho'
              id='work_from_home'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={admission_type_e_social}
              formik={formik}
              disabled={data.user_form_disabled_fields.includes(
                "admission_type"
              )}
              id='admission_type'
              label='Tipo de admissão'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={social_security_regime_e_social}
              formik={formik}
              disabled={data.user_form_disabled_fields.includes(
                "social_security_regime"
              )}
              id='social_security_regime'
              label='Regime Previdenciário'
            />
          </HiddenCheck>
          <HiddenCheck user_form_hidden_fields={data.user_form_hidden_fields}>
            <AutoCompleteField
              options={overtime_type_e_social}
              formik={formik}
              disabled={data.user_form_disabled_fields.includes(
                "overtime_type"
              )}
              id='overtime_type'
              label='Tipo de Horas Extras'
            />
          </HiddenCheck>
        </Grid>
      </HiddenCheckChildren>
    </Grid>
  );
}
