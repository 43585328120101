/** @format */

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { FormatDate } from "../../../../../helpers/formatDate";
import { FormatStatusToHistoric } from "../../../../../helpers/formatStatusToHistoric";

export const HistoryComponent = ({ data, options }: any) => {
  const theme = useTheme();
  const [expandedHistoricId, setExpandedHistoricId] = useState<any>(null);
  const solicitationHistories =
    data?.length &&
    data?.sort((a: any, b: any) => {
      const dateA: any = new Date(a.created_at);
      const dateB: any = new Date(b.created_at);

      return dateB - dateA;
    });
  return data?.solicitation_data?.solicitation_histories?.length > 0 ? (
    <>
      <Typography fontSize={18} fontWeight={500}>
        Histórico do Desligamento
      </Typography>
      {solicitationHistories.map((history: any, index: number) => {
        const dismissalType: any = options.dismissal_types?.find(
          (item: any) => item?.id === history.new_data.dismissal_type_id
        );
        const dismissalReason: any = options.dismissal_reasons?.find(
          (item: any) => item?.id === history.new_data.dismissal_reason_id
        );
        return (
          <Accordion
            sx={{
              marginTop: 3.5,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: 1,
              "&:before": {
                display: "none",
              },
            }}
            expanded={expandedHistoricId === history?.id}
            onChange={() => setExpandedHistoricId(history?.id)}>
            <AccordionSummary
              aria-controls='panel1d-content'
              id='panel1d-header'
              expandIcon={
                <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />
              }
              sx={{
                margin: 0,
                flexDirection: "row-reverse",
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(90deg)",
                },
              }}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                width='100%'>
                <Typography marginLeft={2} fontSize={18} fontWeight={500}>
                  {`${history.user_name} - ${FormatStatusToHistoric(
                    history.status
                  )}`}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Grid display='flex' flexDirection='column' gap={2}>
                <Grid gridTemplateColumns={"1fr 1fr"} display={"grid"} gap={2}>
                  <TextField
                    fullWidth
                    label='Data de saída'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={FormatDate(history.new_data.dismissal_date)}
                  />
                  <TextField
                    fullWidth
                    label='Vai cumprir aviso prévio'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={history.new_data.early_warning ? "Sim" : "Não"}
                  />
                </Grid>
                <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2}>
                  <TextField
                    fullWidth
                    label='Tipo de desligamento'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={`${dismissalType?.id} - ${dismissalType?.name}`}
                  />
                </Grid>
                <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2}>
                  <TextField
                    fullWidth
                    label='Motivo do desligamento'
                    variant='outlined'
                    rows={4}
                    disabled
                    value={`${dismissalReason?.id} - ${dismissalReason?.name}`}
                  />
                </Grid>
                <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2}>
                  <TextField
                    fullWidth
                    label='Comentários'
                    variant='outlined'
                    rows={4}
                    multiline={true}
                    disabled
                    value={history.new_data.comment}
                  />
                </Grid>
                {history.observations && (
                  <Grid gridTemplateColumns={"1fr"} display={"grid"} gap={2}>
                    <TextField
                      fullWidth
                      label='Justificativa'
                      variant='outlined'
                      rows={4}
                      multiline={true}
                      disabled
                      value={history.observations}
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  ) : (
    <></>
  );
};
