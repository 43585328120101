/** @format */

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Save } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { InfoComponent } from "../../../../components/infoComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { GetInstance, PostInstante } from "../../../../services/api";
import { Solicitation, SOLICITATIONS } from "./helpers/constants";

export function EmailSettings() {
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      permissions: {
        salary_review: true,
        promote_user: true,
        transfer: true,
        absence: true,
        promotion: true,
        dismissal: true,
        personnel_requisition: true,
        user_update_request: true,
        vacation_item: true,
        delegation: true,
        dismissal_request: true,
      },
    },
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      PostInstante(null, values, "/users/emails_notification_settings")
        .then((response: any) => {
          toast.success(response?.data?.message);
          dispatch(updateLoading(false));
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  useEffect(() => {
    dispatch(updateLoading(true));
    GetInstance(null, "/users/emails_notification_settings")
      .then((response: any) => {
        dispatch(updateLoading(false));
        const updatedPermissions = formik.values.permissions;
        response.data.data.forEach((permission: any) => {
          updatedPermissions[permission] = false;
        });
        formik.setValues({ permissions: updatedPermissions });
      })
      .catch((e: any) => {
        GenericError(e, dispatch);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (id: string) => {
    formik.setFieldValue(`permissions[${id}]`, !formik.values.permissions[id]);
  };

  return (
    <Grid>
      <InfoComponent instruction='Selecione as notificações que deseja receber por e-mail.' />
      <Paper sx={{ p: 3, mt: 2 }}>
        <Typography fontSize={18} fontWeight={600} mb={2}>
          Notificações por e-mail
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            gridTemplateColumns={isMobile ? "1fr 1fr" : "1fr 1fr 1fr"}
            mt={2}
            display={"grid"}
            gap={1}>
            {SOLICITATIONS.map(({ id, label }: Solicitation) => (
              <FormControlLabel
                key={id}
                label={label}
                onBlur={formik.handleBlur}
                control={
                  <Checkbox
                    checked={formik.values.permissions[id]}
                    onChange={() => handleChange(id)}
                  />
                }
              />
            ))}
          </Grid>
          <Grid container gap={2} mt={2} justifyContent={"end"}>
            <ButtonComponent
              endIcon={<Save />}
              variant={"contained"}
              type={"submit"}>
              salvar preferências
            </ButtonComponent>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
}
