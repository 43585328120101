/** @format */

import { Edit, Undo } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BreadcrumbsComponent } from "../../../../components/breadcrumbsComponent";
import { ButtonComponent } from "../../../../components/buttonComponent";
import { GenericError } from "../../../../helpers/genericError";
import { updateLoading } from "../../../../redux/reducers/common";
import { PutInstante } from "../../../../services/api";
import { validationSchema } from "../helpers/constants";
import { DeleteDialog } from "../helpers/deleteDialog";
import { Form } from "../helpers/form";

export function DelegationDetails() {
  const router = useNavigate();
  const user = useSelector((state: any) => state.user.data);
  let { id } = useParams();
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      initial_date: null,
      final_date: null,
      target_user_id: "",
      delegated_user_id: user.id,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      dispatch(updateLoading(true));
      PutInstante(null, values, "/delegations/" + id)
        .then((response: any) => {
          dispatch(updateLoading(false));
          router("/delegation");
          toast.success(response?.data?.message);
        })
        .catch((e: any) => {
          GenericError(e, dispatch);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container gap={2}>
        <BreadcrumbsComponent
          links={[
            { label: "Inicio" },
            { label: "Delegações", value: "/delegation" },
            { label: "Ver delegação" },
          ]}
        />
        <Grid width={"inherit"}>
          <Paper sx={{ p: 2 }}>
            <Grid pb={2}>
              <Typography fontSize={18} fontWeight={500}>
                Ver detalhes da delegação de fluxo de trabalho
              </Typography>
            </Grid>
            <Form formik={formik} id={id} />
            <Grid container gap={2} mt={2} justifyContent={"end"}>
              <ButtonComponent
                variant={"outlined"}
                onClick={() => {
                  router("/delegation");
                }}
                endIcon={<Undo />}>
                Voltar
              </ButtonComponent>
              {user.id === formik.values.assigned_to_id && (
                <ButtonComponent
                  variant={"contained"}
                  type={"submit"}
                  endIcon={<Edit />}>
                  Salvar
                </ButtonComponent>
              )}
              <DeleteDialog id={id} />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
}
